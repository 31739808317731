import { Layout, LayoutContent, LayoutHeader } from '@ui';
import { Loader } from '@components';
import { RootState } from '@store';
import { appActions } from '@app/duck/appSlice';
import { useAuthSessionListener } from '@modules/auth/duck/authHooks';
import { JobModalsControllerApp } from '@modules/job/modals';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSObject, Theme } from '@emotion/react';
import { createSelector } from '@reduxjs/toolkit';
import { ErrorBoundary } from 'react-error-boundary';
import { AppHeader, AppSider, FallbackRender } from './components';

const appReadySelector = (state: RootState) => state.app.ready;
const authReadySelector = (state: RootState) => state.auth.ready;
const readySelector = createSelector([appReadySelector, authReadySelector], (appReady, authReady) => ({
  appReady,
  authReady,
}));

export const App = () => {
  const dispatch = useDispatch();
  const { appReady, authReady } = useSelector(readySelector);

  useAuthSessionListener();
  useEffect(() => {
    if (authReady && !appReady) {
      dispatch(appActions.setReady(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, authReady]);

  if (!appReady) {
    return <Loader mode="fixed" size="large" />;
  }

  return (
    <Layout css={cssLayout}>
      <AppSider />
      <Layout css={cssLayoutInner}>
        <LayoutHeader css={cssLayoutHeader}>
          <AppHeader />
        </LayoutHeader>
        <LayoutContent css={cssContent}>
          <ErrorBoundary FallbackComponent={FallbackRender}>
            <Outlet />
          </ErrorBoundary>
        </LayoutContent>
      </Layout>

      <JobModalsControllerApp />
    </Layout>
  );
};

const cssLayout = (): CSSObject => ({
  height: '100vh',
});

const cssContent = (): CSSObject => ({
  '&&': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
});

const cssLayoutInner = (theme: Theme): CSSObject => ({
  minHeight: '100vh',
  overflowX: 'hidden' as 'hidden',
  overflowY: 'auto' as 'auto',
  background: theme.colorBgBase,
});

const cssLayoutHeader = (theme: Theme): CSSObject => ({
  padding: 0,
  lineHeight: '1rem',
  background: theme.colorBgBase,
  height: 56,
});
