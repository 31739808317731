import { PropertiesView } from '@components';
import { Button, Space, Typography } from '@ui';
import { StudyDetails as StudyDetailsType } from '@modules/study/StudyTypes';
import { StudyModalsController, StudyModalsType } from '@modules/study/modals';
import { studyActions } from '@modules/study/duck/studySlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSObject } from '@emotion/react';

export const StudyDetailsTemplate = ({ study, detailsMap, conditionsAndEligibilityMap }: StudyDetailsTemplateProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['study']);

  const openDbSchemaModal = () => dispatch(studyActions.pushModal({ type: StudyModalsType.dbschema }));

  return (
    <>
      <Space css={cssContainer} direction="vertical" size="large">
        <PropertiesView data={detailsMap} renderHeader={<Typography.Title level={4} children={study.name} />} />
        {conditionsAndEligibilityMap && (
          <PropertiesView
            data={conditionsAndEligibilityMap}
            renderHeader={<Typography.Title level={5} children={t('detailsPage.conditionsEligibilityTitle')} />}
          />
        )}
      </Space>
      <Button css={cssDetailsButton} type="default" size="large" onClick={openDbSchemaModal}>
        {t('detailsPage.showStructure')}
      </Button>
      <StudyModalsController />
    </>
  );
};

const cssContainer = (): CSSObject => ({
  gap: 40,
});

const cssDetailsButton = (): CSSObject => ({
  marginTop: 24,
  width: 'fit-content',
});

interface StudyDetailsTemplateProps {
  detailsMap: Array<{
    key: string;
    value: any;
  }>;
  conditionsAndEligibilityMap?: Array<{
    key: string;
    value: any;
  }>;
  study: Partial<StudyDetailsType>;
}
