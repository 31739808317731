import { IDatasetListResponse, EDatasetType, IDataset, Scope, EDatasetStatus } from '@modules/dataset/DatasetTypes';
import { Space, Table, Tooltip } from '@ui';
import { ActorAndInfo, InformationModal, StatusTag } from '@components';
import { dateToString } from '@shared/utils/Date';
import { getCreatedByInfo, isCrossStudy } from '@shared/utils/common';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getTableListOption } from '@shared/utils/Table';
import { useFeatures } from '@modules/user/duck/userHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { DatasetActionButtons } from '@modules/dataset/components/DatasetActionButtons';
import { useMemo, useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { ColumnType } from 'antd/lib/table';
import { ExportOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { DatasetScopeList, DatasetScopeListScope, renderInfoModalContent } from './DatasetScopeList';

export const DatasetList = ({ data, studyId, loading, pagination, isStackType, onChange, t }: DatasetListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IDatasetListResponse['items'][0]>();
  const crossStudy = isCrossStudy(studyId!);
  const globalStudy = useSelector(selectGlobalStudy);
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();

  const { hasGPDIP } = useFeatures();

  const datasetTypes = getTableListOption(EDatasetType, true);
  const datasetStatuses = getTableListOption(EDatasetStatus, true);

  const columns: TableColumnsType<IDatasetListResponse['items'][0]> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        !isStackType && {
          width: 100,
          title: t('rootTable.type'),
          dataIndex: 'type',
          filters: datasetTypes,
          onFilter: undefined,
          render: (type: EDatasetType) => t(`rootTable.typeNames.${type}`),
        },
        {
          title: t('rootTable.created'),
          key: 'narrow',
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (createdAt: number, record: IDataset) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={getCreatedByInfo(record)} />
          ),
        },
        {
          title: t('rootTable.updated'),
          key: 'narrow',
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (updatedAt: number, record: IDataset) => (
            <ActorAndInfo info={dateToString(updatedAt)} actor={getCreatedByInfo(record)} />
          ),
        },
        !crossStudy && {
          title: t('rootTable.scope'),
          width: 100,
          dataIndex: 'scope',
          render: (scope: Scope) => (
            <DatasetScopeList
              currentStudyName={globalStudy?.name}
              onMoreBtnClick={setStudiesDetailsModal}
              scope={scope}
            />
          ),
        },
        hasGPDIP &&
          !crossStudy && {
            width: 90,
            title: t('rootTable.sync'),
            dataIndex: 'syncStates',
            render: (_: any, record: IDataset) => {
              if (record.export.automatic) {
                const getExportInfo = record.export ? (
                  <Tooltip title={t('rootTable.syncExport')}>
                    <ExportOutlined />
                  </Tooltip>
                ) : null;

                return (
                  <Space>
                    {t('rootTable.syncStates.auto')}
                    {getExportInfo}
                  </Space>
                );
              }
              if (!record.export.automatic && !record.export.crontab) {
                return t('rootTable.syncStates.manual');
              }

              return t('rootTable.syncStates.period');
            },
          },
        {
          title: t('rootTable.status'),
          dataIndex: 'status',
          width: 150,
          filters: datasetStatuses,
          onFilter: undefined,
          render: (status: EDatasetStatus, record: IDataset) => (
            <StatusTag
              showSuccessTag={!!status}
              showErrorTag={status === EDatasetStatus.Error}
              errorMessage={record?.error}
            />
          ),
        },
        {
          width: 200,
          title: t('rootTable.actions.title'),
          dataIndex: '',
          key: 'actions',
          render: (_: any, record: IDataset) => (
            <DatasetActionButtons isStackType={isStackType} record={record} t={t} studyId={studyId} />
          ),
        },
      ].filter((item) => typeof item !== 'boolean') as ColumnType<IDataset>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasetTypes, studyId],
  );

  return (
    <>
      <Table
        locale={locale}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          title={t('studiesDetails')}
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
        />
      )}
    </>
  );
};

interface DatasetListProps {
  data?: IDatasetListResponse['items'];
  pagination?: TableProps<IDatasetListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IDatasetListResponse['items'][0]>['onChange'];
  loading?: boolean;
  t: TFunction;
  studyId: number;
  isStackType?: boolean;
}
