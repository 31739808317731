import { LibraryAuditLogList } from '@modules/library/audit/components/LibraryAuditLogList';
import {
  useLibraryAuditLogListQuery,
  useLibraryExportToExcelMutation,
} from '@modules/library/audit/duck/libraryAuditApi';
import { Loader, PageTemplateSimple } from '@components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { LibraryAuditModalsController } from '@modules/library/audit/modals/LibraryAuditModalsController';
import { ExportButton } from '@components/buttons/ExportButton';
import { TableExtraConfig, TableFiltersConfig, TableSorterConfig } from '@ui';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { TablePaginationConfig } from 'antd';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LibraryAuditLogItem } from '../LibraryAuditTypes';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

const sortOrder: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
};

const prepareSorter = (sorter: TableSorterConfig<LibraryAuditLogItem>) => {
  const config = Array.isArray(sorter) ? sorter.at(0) : sorter;
  return {
    sort_by: config?.order ? (config?.field as string) : undefined,
    order: sortOrder[config?.order ?? ''],
  };
};

const prepareFilters = (filters: TableFiltersConfig) =>
  Object.fromEntries(
    Object.keys(filters)
      .filter((key) => !!filters[key])
      .map((key) => [key, filters[key]?.join(',')]),
  );

export const LibraryAuditLogListPage = () => {
  const { t } = useTranslation(['audit']);
  const {
    appPermissions: { canGlAuditLogsExport },
  } = useAppPermissions();
  const { paginationState, setPagination, getPagination } = useTablePaginationState(initialPage);
  const [filters, setFilters] = useState<TableFiltersConfig>({});
  const [sorter, setSorter] = useState<TableSorterConfig<LibraryAuditLogItem>>({});
  const libraryAuditLogsQuery = useLibraryAuditLogListQuery({
    page: paginationState.current - 1,
    ...prepareSorter(sorter),
    ...prepareFilters(filters),
  });
  const [libraryExportToExcel] = useLibraryExportToExcelMutation();

  const globalLibrary = useSelector(selectGlobalLibrary);
  // const { navigation } = useRccNavigation({ pageType: 'studies' });

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<LibraryAuditLogItem>,
    extra: TableExtraConfig<LibraryAuditLogItem>,
  ) => {
    switch (extra.action) {
      case 'paginate':
        setPagination(tablePagination.current!);
        break;
      case 'sort':
        setSorter({ ...sorter });
        break;
      case 'filter':
        setPagination(1);
        setFilters({ ...filters });
        break;
    }
  };

  const pagination = getPagination(libraryAuditLogsQuery.data?.totalItems);

  const onLibraryExportHandler = async () => {
    const result = await libraryExportToExcel(globalLibrary?.id!).unwrap();
    return result.url;
  };

  return (
    <PageTemplateSimple
      // navigation={navigation}
      hideTitleSkeleton
      title={{
        level: 2,
        children: t('list.title'),
        extra: canGlAuditLogsExport && <ExportButton name={t('list.title')} onExport={onLibraryExportHandler} />,
      }}
      content={{
        isLoading: libraryAuditLogsQuery.isLoading && !libraryAuditLogsQuery.data,
        errorText: t('errors.pageLoadingError'),
        error: libraryAuditLogsQuery.error,
      }}
    >
      {libraryAuditLogsQuery.isFetching && !libraryAuditLogsQuery.isLoading && <Loader mode="absolute" />}
      {libraryAuditLogsQuery.data && (
        <LibraryAuditLogList
          data={libraryAuditLogsQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          pagination={pagination}
        />
      )}
      <LibraryAuditModalsController />
    </PageTemplateSimple>
  );
};
