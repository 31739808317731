import { Popconfirm, Radio, Space, Tooltip, Typography } from '@ui';
import { selectConfirmationOpened, selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { DEFAULT_STAGE_HEIGHT, DEFAULT_STAGE_WIDTH } from '@modules/modelEditor/components/builder/constants';
import { CustomHandle } from '@modules/modelEditor/components/builder/components/CustomHandle';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { Trash } from '@components/icons';
import { ButtonWithConfirmation } from '@components';
import { CSSObject, Theme } from '@emotion/react';
import { ReactNode, FC, useEffect } from 'react';
import { NodeToolbar, Position, NodeProps, useKeyPress } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

export const ContainerStage: FC<ContainerStageProps> = ({
  customStyle,
  children,
  id,
  data,
  isConnectable,
  selected,
  actionButtons,
  isDeletable,
  background,
  targetHandle,
  sourceHandle,
  onDeleteStage,
  onDoubleClick,
}) => {
  const { t } = useTranslation(['model']);
  const dispatch = useDispatch();
  const { validation, toolbarPosition } = data;
  const backspacePressed = useKeyPress('Backspace');
  const isConfirmationOpened = useSelector(selectConfirmationOpened);
  const readOnly = useSelector(selectModelEditorReadOnly);

  const setConfirmationOpened = (flag: boolean) => dispatch(modelEditorActions.confirmationOpened(flag));

  useEffect(() => {
    if (backspacePressed && !readOnly) {
      setConfirmationOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backspacePressed]);

  const onKeyClickHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setConfirmationOpened(false);
    }
  };

  const onMouseClickHandler = () => setConfirmationOpened(false);

  useEffect(() => {
    if (isConfirmationOpened) {
      document.addEventListener('keydown', onKeyClickHandler);
      document.addEventListener('mouseup', onMouseClickHandler);
    }

    return () => {
      document.removeEventListener('keydown', onKeyClickHandler);
      document.removeEventListener('mouseup', onMouseClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationOpened]);

  return (
    <div css={[cssLayout({ selected, background }), customStyle]} onDoubleClick={onDoubleClick}>
      <NodeToolbar position={toolbarPosition} css={cssNodeToolbar}>
        <Radio.Group size="large">
          {actionButtons &&
            actionButtons.map((btn) => (
              <Tooltip key={`tooltip_${id}_${btn.value}`} placement="bottom" title={btn.title}>
                <Radio.Button
                  key={`btn_${id}_${btn.value}`}
                  value={btn.value}
                  onClick={() => btn.onClick()}
                  type={'primary'}
                >
                  {btn.icon}
                </Radio.Button>
              </Tooltip>
            ))}

          {isDeletable && (
            <ButtonWithConfirmation
              key={`delete_btn_${id}`}
              size="large"
              submitFunc={() => {
                onDeleteStage(id);
                setConfirmationOpened(false);
              }}
              css={cssTrash}
              confirmTitle={t('builder.confirmation.delete.title')}
              disabled={readOnly}
              tooltipTitle={t('builder.confirmation.delete.title')}
              confirmContent={
                <Space direction="vertical">
                  <Typography.Text type="danger">{t('builder.confirmation.warn')}</Typography.Text>
                  <Typography.Text>{t('builder.confirmation.delete.description')}</Typography.Text>
                </Space>
              }
              icon={<Trash />}
            />
          )}
        </Radio.Group>
      </NodeToolbar>

      {children || t('builder.unknownName')}

      {targetHandle && (
        <CustomHandle type="target" position={Position.Left} isConnectable={isConnectable} validation={validation} />
      )}
      {sourceHandle && (
        <CustomHandle type="source" position={Position.Right} isConnectable={isConnectable} validation={validation} />
      )}
    </div>
  );
};

const cssLayout = ({ selected, background }: ContainerStageStyleProps): CSSObject => ({
  // TODO: width should not be fixed. This is hot fix. Need some investigation regarding this parameter across all places where it used
  width: DEFAULT_STAGE_WIDTH,
  height: DEFAULT_STAGE_HEIGHT,
  minWidth: 80,
  background,
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'grab',
  position: 'relative',
  border: selected ? '0.5px solid #1a192b' : '',
  textAlign: 'center',
  userSelect: 'none',
  padding: '24px 32px',
});

const cssNodeToolbar = (theme: Theme): CSSObject => ({
  borderStartEndRadius: 4,
  borderEndEndRadius: 4,
  borderEndStartRadius: 4,
  borderStartStartRadius: 4,
  boxShadow: '0px 0px 4px 0px #00000040',
  '& .ant-radio-group': {
    display: 'flex',
  },
  '& .ant-radio-button-wrapper': {
    fontSize: 24,
    lineHeight: 1,
    padding: 8,
    width: 40,
  },
  '&& .ant-radio-button-wrapper-checked': {
    color: 'red',
    borderColor: theme['color-grey-300'],

    '&:before': {
      backgroundColor: theme['color-grey-300'],
    },
    '& > span > svg': {
      color: theme['color-brand-blue-500'],
    },
  },
});

const cssTrash = (theme: Theme): CSSObject => ({
  '&&': {
    borderStartStartRadius: 0,
    borderStartEndRadius: 4,
    borderEndStartRadius: 0,
    borderEndEndRadius: 4,
    border: `1px solid ${theme['color-grey-300']}`,
    boxShadow: 'none',
  },
});

export interface ActionButton {
  value: string;
  onClick: () => void;
  icon: ReactNode;
  title: string;
}

interface ContainerStageProps extends Pick<NodeProps, 'id' | 'data' | 'isConnectable' | 'selected'> {
  children?: ReactNode;
  actionButtons?: ActionButton[];
  background?: string;
  targetHandle?: boolean;
  sourceHandle?: boolean;
  customStyle?: any;
  isDeletable?: boolean;
  onDeleteStage: (nodeId: string) => void;
  onDoubleClick: () => void;
}

interface ContainerStageStyleProps {
  background?: string;
  selected: boolean;
}
