import { default as AntTabs } from 'antd/es/tabs';
import { CSSObject, Theme } from '@emotion/react';
import { TabsProps } from 'antd';

const Tabs: React.FunctionComponent<TabsProps> = ({ children, ...props }) => {
  return <AntTabs {...props} css={cssTabs} children={children} />;
};

export { Tabs };

const cssTabs = (theme: Theme): CSSObject => ({
  '&& .ant-tabs-tab': {
    border: 'none',
    marginBottom: '4px',
  },
  '&& .ant-tabs-tab-active': {
    backgroundColor: theme['color-grey-300'],
    borderRadius: 4,
    fontWeight: 500,
  },
});
