import {
  selectConfirmationOpened,
  selectModelEditorEdges,
  selectModelEditorHoveredEdge,
  selectModelEditorNodes,
  selectModelEditorReadOnly,
} from '@modules/modelEditor/duck/modelEditorSelectors';
import { findSourceNodesByNodeId } from '@modules/modelEditor/components/builder/Utils';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { Popconfirm } from '@ui';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { useEffect } from 'react';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useKeyPress } from 'reactflow';
import { CSSObject, Theme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EdgeLabelType = ({ sourceX, sourceY, label }: { sourceX: number; sourceY: number; label: string }) => {
  return (
    <div css={cssLabelType({ sourceX, sourceY })} className="nodrag nopan">
      {label.toUpperCase()}
    </div>
  );
};

export const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { t } = useTranslation(['model']);
  const dispatch = useDispatch();
  const backspacePressed = useKeyPress('Backspace');
  const edges = useSelector(selectModelEditorEdges);
  const nodes = useSelector(selectModelEditorNodes);
  const isConfirmationOpened = useSelector(selectConfirmationOpened);
  const hoveredEdge = useSelector(selectModelEditorHoveredEdge);
  const readOnly = useSelector(selectModelEditorReadOnly);
  const isCurrentEdgeHovered = !!(hoveredEdge && id === hoveredEdge.id);
  const selectedEdges = edges.filter((el) => el.selected);

  // to prevent multiple edge deletion (user could be overwhelmed with confirmation popups)
  const isCurrentEdgeSelected = selectedEdges.length === 1 && selectedEdges[0].id === id;

  let joinTypeLabel = '';
  const currentEdge = edges.find((edge) => edge.id === id);
  const nodeDetails = nodes.find((node) => node.id === currentEdge?.target);
  const isConnectedToJoin = nodeDetails?.type === ModelEditorNodeType.join;
  if (isConnectedToJoin) {
    const sourceNodes = findSourceNodesByNodeId(nodeDetails.id, edges);
    joinTypeLabel = sourceNodes[0] === currentEdge?.source ? t('join.leftSourceShort') : t('join.rightSourceShort');
  }

  useEffect(() => {
    if (backspacePressed && isCurrentEdgeSelected) {
      setConfirmationOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backspacePressed, isCurrentEdgeSelected]);

  const setConfirmationOpened = (flag: boolean) => dispatch(modelEditorActions.confirmationOpened(flag));

  const onDeleteEdge = (id: string) => dispatch(modelEditorActions.onDeleteEdge(id));

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />;
      <EdgeLabelRenderer>
        {isConnectedToJoin && <EdgeLabelType label={joinTypeLabel} sourceX={sourceX} sourceY={sourceY} />}
        {!readOnly && (
          <Popconfirm
            css={cssPopconfirmDelete({ labelX, labelY })}
            id={`popconfirmDeleteEdge_${id}`}
            title={t('builder.confirmation.deleteEdge.title')}
            description={
              <>
                <div css={cssWarning}>{t('builder.confirmation.warn')}</div>
                <div> {t('builder.confirmation.deleteEdge.description')}</div>
              </>
            }
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => {
              onDeleteEdge(id);
              setConfirmationOpened(false);
              dispatch(modelEditorActions.onHoverEdge(null));
            }}
            onCancel={() => {
              setConfirmationOpened(false);
              dispatch(modelEditorActions.selectEdge({ id, selected: false }));
            }}
            open={isCurrentEdgeSelected && isConfirmationOpened}
          >
            <div css={cssLabel({ labelX, labelY, isShow: isCurrentEdgeHovered || isCurrentEdgeSelected })}>
              <CloseCircleTwoTone
                twoToneColor={['#a9a9a9', '#eee']}
                onClick={() => {
                  setConfirmationOpened(true);
                  dispatch(modelEditorActions.selectEdge({ id }));
                }}
              />
            </div>
          </Popconfirm>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

const cssPopconfirmDelete = ({ labelX, labelY }: Pick<CssLabelProps, 'labelX' | 'labelY'>): CSSObject => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
});

const cssLabel = ({ labelX, labelY, isShow }: CssLabelProps): CSSObject => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
  fontSize: 16,
  pointerEvents: 'all',
  display: isShow ? 'block' : 'none',
  borderRadius: '50%',
  ':hover': {
    boxShadow: `0px 0px 4px 4px rgba(0,0,0,0.1)`,
  },
});

const cssLabelType = ({ sourceX, sourceY }: CssLabelTypeProps): CSSObject => ({
  position: 'absolute',
  transform: `translate(20%, -50%) translate(${sourceX}px,${sourceY}px)`,
  background: '#fff',
  padding: 2,
  borderRadius: 4,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.3,
});

const cssWarning = (theme: Theme): CSSObject => ({
  color: theme.colorError,
  fontWeight: 600,
});

interface CssLabelProps {
  labelX: number;
  labelY: number;
  isShow: boolean;
}

interface CssLabelTypeProps {
  sourceX: number;
  sourceY: number;
}
