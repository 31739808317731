import {
  TAnalysisObjectPscModalsPushModalPayload,
  TAnalysisObjectPscModalsState,
} from '@modules/library/analysisObjects/psc/modals/AnalysisObjectPscModalsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AnalysisObjectPscStore = {
  modals: { selectedModal: null },
};

export const analysisObjectPscSlice = createSlice({
  name: 'analysisObjectPsc',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TAnalysisObjectPscModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const analysisObjectPscActions = analysisObjectPscSlice.actions;

export const analysisObjectPscReducer = analysisObjectPscSlice.reducer;

interface AnalysisObjectPscStore {
  modals: TAnalysisObjectPscModalsState;
}
