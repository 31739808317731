import { Table, Tag } from '@ui';
import { tableNamesToOptions } from '@shared/utils/Table';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { JobListResponse } from '@modules/job/duck/jobApi';
import { dateToString } from '@shared/utils/Date';
import { Status } from '@modules/job/JobTypes';
import routes from '@routes';
import { getStudyPathName } from '@routes/utils';
import { ActorAndInfo } from '@components';
import { StoreListResponse } from '@modules/stores/duck/storeApi';
import { ConnectionListResponse, SourceListResponse } from '@modules/source/duck/sourceApi';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

export const JobList = ({
  data,
  storeList,
  sourceList,
  connectionList,
  studyId,
  onChange,
  pagination,
  loading,
  t,
}: JobListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<JobListResponse['items'][0]>();
  const currentData = data?.items.filter((item) => !item.deleted_at);

  const columns: TableColumnsType<JobListResponse['items'][0]> = useMemo(
    () => {
      const sourceListOption = tableNamesToOptions(sourceList);
      const storeListOption = tableNamesToOptions(storeList);

      return [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
          render: (_, record) => (
            <Link
              to={routes[getStudyPathName(studyId)].jobs.view.resolver({
                jobId: record.id,
                studyId,
              })}
            >
              {record.name}
            </Link>
          ),
        },
        {
          title: t('rootTable.dataSource'),
          dataIndex: 'data_source_name',
          filters: sourceListOption,
          onFilter: undefined,
          render: (_, record) => record.data_source,
        },
        {
          title: t('rootTable.connection'),
          dataIndex: 'connection_name',
          ...getColumnSearchProps('connection'),
          onFilter: undefined,
          render: (_, record) => record.connection || connectionList?.find((c) => c.id === record.connection_id)?.name,
        },
        {
          title: t('rootTable.dataStore'),
          dataIndex: 'data_store_name',
          filters: storeListOption,
          onFilter: undefined,
          render: (_, record) => record.data_store,
        },
        {
          title: t('rootTable.lastRunAt'),
          key: 'narrow',
          dataIndex: 'last_run',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (lastRunAt: number, record) => (
            <ActorAndInfo info={dateToString(lastRunAt)} actor={record.created_by} />
          ),
        },
        {
          width: 150,
          title: t('rootTable.status'),
          dataIndex: 'status',
          render: (status: Status) => <Tag color={status}>{status}</Tag>,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceList, storeList, connectionList],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={currentData}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface JobListProps {
  studyId: number;
  data?: JobListResponse;
  storeList?: StoreListResponse['items'];
  sourceList?: SourceListResponse['items'];
  connectionList?: ConnectionListResponse;
  pagination?: TableProps<JobListResponse['items'][0]>['pagination'];
  onChange?: TableProps<JobListResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  t: TFunction;
}
