import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const FolderFilled: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M2.39844 7.20078C2.39844 6.56426 2.65129 5.95381 3.10138 5.50372C3.55147 5.05364 4.16192 4.80078 4.79844 4.80078H10.7984L13.1984 7.20078H19.1984C19.835 7.20078 20.4454 7.45364 20.8955 7.90372C21.3456 8.35381 21.5984 8.96426 21.5984 9.60078V16.8008C21.5984 17.4373 21.3456 18.0478 20.8955 18.4978C20.4454 18.9479 19.835 19.2008 19.1984 19.2008H4.79844C4.16192 19.2008 3.55147 18.9479 3.10138 18.4978C2.65129 18.0478 2.39844 17.4373 2.39844 16.8008V7.20078Z"
      fill="currentColor"
    />
  </BaseIcon>
));
