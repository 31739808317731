import { Divider } from '@ui';
import { CSSObject } from '@emotion/react';

export const ModalDivider = ({ top = 24, bottom = 12 }: ModalDividerProps) => (
  <div css={cssDividerContainer({ top, bottom })}>
    <Divider css={cssDivider} />
  </div>
);

const cssDividerContainer = ({ top, bottom }: ModalDividerProps): CSSObject => ({
  margin: `${top}px -24px ${bottom}px`,
});

const cssDivider = (): CSSObject => ({
  margin: 0,
});

interface ModalDividerProps {
  top?: number;
  bottom?: number;
}
