import { IGpdip, IGpdipTree, RunStatus } from '@modules/gpdip/GpdipTypes';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getRunStatusById } from '@modules/gpdip/duck/gpdipUtils';
import { dateToString } from '@shared/utils/Date';
import { ActionButtons } from '@modules/gpdip/components/ActionButtons';
import { gpdipActions } from '@modules/gpdip/duck/gpdipSlice';
import { GpdipModalsType } from '@modules/gpdip/modals/GpdipModalsConstants';
import { Badge, Space, Table, Typography } from '@ui';
import { StatusTag } from '@components';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

const dashSymbol = <>&mdash;</>;

export const GpdipList = ({
  data,
  loading,
  t,
  onChange,
  expandedKeys,
  addExpandedKey,
  removeExpandedKey,
  statuses,
  statusesLoading,
}: GpdipListProps) => {
  const dispatch = useDispatch();
  const { getColumnSearchProps, locale } = useColumnSearch<IGpdipTree>();

  const openSynchronizationModal = (data: IGpdip) => {
    dispatch(gpdipActions.pushModal({ type: GpdipModalsType.syncSettings, data }));
  };

  const columns: TableColumnsType<IGpdipTree> = useMemo(
    () => [
      {
        width: 350,
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
      },
      {
        width: 250,
        title: t('rootTable.automatic'),
        dataIndex: 'automatic',
        key: 'automatic',
        render: (automatic, record) => {
          if (!record.children) {
            const synchronizationType = record.export.automatic
              ? t('datastore.scheduler.automatic')
              : record.export.crontab
              ? t('datastore.scheduler.periodically')
              : t('datastore.scheduler.manual');

            return <Typography.Link children={synchronizationType} onClick={() => openSynchronizationModal(record)} />;
          }

          return `${t('rootTable.sync.auto')} (${record.automaticCount}) / ${t('rootTable.sync.periodically')} (${
            record.periodicallyCount
          }) / ${t('rootTable.sync.manual')} (${record.manualCount})`;
        },
      },
      {
        width: 250,
        title: t('rootTable.finishedAt'),
        dataIndex: 'finished_at',
        key: 'finished_at',
        render: (finished_at, record) => {
          if (!record.children) {
            return finished_at ? dateToString(finished_at) : dashSymbol;
          }
          return record.finished_at ? dayjs(record.finished_at).fromNow() : dashSymbol;
        },
      },
      {
        width: 250,
        title: t('rootTable.error'),
        dataIndex: 'error',
        key: 'error',
        render: (error, record) => {
          if (!record.children) {
            return <StatusTag showSuccessTag={!!record.finished_at} showErrorTag={error} errorMessage={error} />;
          }
          return (
            <Space>
              <Badge status="success" text={`Success (${record.successCount})`} />
              <div>/</div>
              <Badge status="error" text={`Error (${record.errorCount})`} />
            </Space>
          );
        },
      },
      {
        width: 100,
        align: 'center',
        title: t('rootTable.runAction'),
        dataIndex: '',
        key: 'actions',
        render: (_, record) => {
          if (!record.children) {
            const status = getRunStatusById(statuses, record.id);
            return <ActionButtons record={record as IGpdip} status={status} loading={statusesLoading} t={t} />;
          }
        },
      },
    ],
    [statuses, statusesLoading],
  );

  return (
    <>
      <Table
        locale={locale}
        columns={columns}
        expandable={{
          childrenColumnName: 'children',
          expandedRowKeys: expandedKeys ?? [],
          onExpand: (expanded, record) => {
            if (expanded) {
              addExpandedKey(record.id);
            } else {
              removeExpandedKey(record.id);
            }
          },
        }}
        dataSource={data}
        loading={loading}
        rowKey={(item) => item.id}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={false}
        onChange={onChange}
      />
    </>
  );
};

interface GpdipListProps {
  data?: IGpdipTree[];
  loading: boolean;
  t: TFunction;
  onChange?: TableProps<IGpdipTree>['onChange'];
  expandedKeys?: IGpdipTree['id'][];
  setShouldExpandList?: any;
  addExpandedKey: (id: IGpdipTree['id']) => void;
  removeExpandedKey: (id: IGpdipTree['id']) => void;
  statuses: RunStatus[];
  statusesLoading: boolean;
}
