import { useColumnSearch } from '@components/ui/table/tableHooks';
import { useMemo } from 'react';
import { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { ILibraryImportModel } from '../components/LibraryImportModel';

export const useModelColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportModel>();
  const { t } = useTranslation(['libraryModel']);

  const modelColumns: TableColumnsType<ILibraryImportModel> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: t('rootTable.dataStore'),
        dataIndex: 'data_store',
      },
      {
        title: t('rootTable.libraryVersion'),
        key: 'version',
        dataIndex: 'version',
        render: (_, record) => record.version || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { modelColumns, locale };
};
