import { Badge, Popconfirm, Space, Tooltip, Typography } from '@ui';
import { selectModelEditorErrors } from '@modules/modelEditor/duck/modelEditorSelectors';
import { ArrowsExpand, LockClosed, LockOpen, Minus, Network, Plus, Trash } from '@components/icons';
import { ButtonWithConfirmation } from '@components';
import {
  Connection,
  Controls,
  EdgeChange,
  Node,
  NodeChange,
  useReactFlow,
  ControlButton,
  useStore,
  useStoreApi,
  ReactFlowState,
  Edge,
} from 'reactflow';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

const TOOLTIP_PLACEMENT = 'right';

export const ControlsDataStage = ({
  setReadOnly,
  onLayout,
  actions,
  loading,
  readOnly,
  canBeUnlocked,
}: ControlsDataStageProps) => {
  const { t } = useTranslation('model');
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const store = useStoreApi();

  const currentZoom = useStore((s: ReactFlowState) => s.transform[2]);
  const { minZoom, maxZoom } = store.getState();
  const errors = useSelector(selectModelEditorErrors)
    .filter((error) => !error.node?.id)
    .map((error) => t(error.message, error.params ?? {}));
  const uniqueErrors = Array.from(new Set(errors));

  return (
    <Controls position="top-right" showZoom={false} showFitView={false} showInteractive={false}>
      <Tooltip placement={TOOLTIP_PLACEMENT} title={t('builder.controls.zoomIn')}>
        <ControlButton css={cssControlBtn} onClick={() => zoomIn()} disabled={maxZoom === currentZoom}>
          <Plus />
        </ControlButton>
      </Tooltip>

      <Tooltip placement={TOOLTIP_PLACEMENT} title={t('builder.controls.zoomOut')}>
        <ControlButton css={cssControlBtn} onClick={() => zoomOut()} disabled={minZoom === currentZoom}>
          <Minus />
        </ControlButton>
      </Tooltip>

      <Tooltip placement={TOOLTIP_PLACEMENT} title={t('builder.controls.fitView')}>
        <ControlButton css={cssControlBtn} onClick={() => fitView()}>
          <ArrowsExpand />
        </ControlButton>
      </Tooltip>

      <Tooltip placement={TOOLTIP_PLACEMENT} title={t('builder.controls.interactivity')}>
        <ControlButton css={cssControlBtn} onClick={() => setReadOnly(!readOnly)} disabled={!canBeUnlocked}>
          {readOnly ? <LockClosed /> : <LockOpen />}
        </ControlButton>
      </Tooltip>

      {!readOnly && (
        <>
          <Tooltip placement={TOOLTIP_PLACEMENT} title={t('builder.controls.autolayout')}>
            <ControlButton css={cssControlBtn} onClick={() => onLayout()}>
              <Network />
            </ControlButton>
          </Tooltip>

          <ButtonWithConfirmation
            submitFunc={() => actions.onDeleteNodes()}
            confirmTitle={t('builder.confirmation.deleteAll.title')}
            disabled={readOnly}
            tooltipTitle={t('builder.controls.deleteAll')}
            confirmContent={t('builder.confirmation.deleteAll.description')}
            icon={<Trash />}
            size="large"
            tooltipPlacement="right"
            css={cssTrash}
          />
        </>
      )}
      {errors.length > 0 && (
        <Tooltip
          color="red"
          placement="left"
          title={
            <ul>
              {uniqueErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          }
        >
          <ControlButton css={cssControlBtn}>
            <Badge count={uniqueErrors.length} title="" style={{ fontSize: 16 }} />
          </ControlButton>
        </Tooltip>
      )}
    </Controls>
  );
};

interface Actions {
  onNodesChange: (nodes: NodeChange[]) => void;
  onEdgesChange: (edges: EdgeChange[]) => void;
  addNodes: (node: Node | Node[]) => void;
  setNodes: (nodes: Node[]) => void;
  onConnect: (connection: Connection) => void;
  onDeleteNode: (nodeId: string) => void;
  onDeleteNodes: () => void;
  onHoverEdge: (edge: Edge | null) => void;
}

interface ControlsDataStageProps {
  setReadOnly: (value: boolean) => void;
  onLayout: () => void;
  actions: Actions;
  loading: boolean;
  readOnly?: boolean;
  canBeUnlocked?: boolean;
}

const cssControlBtn = (theme: Theme): CSSObject => ({
  width: 24,
  height: 24,
  padding: '8px 4px',
  '& > svg': {
    height: 24,
    maxWidth: 24,
    maxHeight: 24,
  },
  borderBottom: `1px solid ${theme['color-grey-300']}`,
});

const cssTrash = (theme: Theme): CSSObject => ({
  '&&': {
    width: 32,
    borderRadius: 0,
    borderBottom: `1px solid ${theme['color-grey-300']}`,
    borderTop: `1px solid ${theme['color-grey-300']}`,
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: 'none',
  },
});
