import { useColumnSearch } from '@components/ui/table/tableHooks';
import { DatasetScopeList, DatasetScopeListScope } from '@modules/dataset/components';
import { Scope } from '@modules/dataset/DatasetTypes';
import { TableColumnsType } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISQLLabObject } from '../SQLLabObjectTypes';

export const useSqlLabObjectColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ISQLLabObject>();
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();
  const { t } = useTranslation(['dnaObject']);

  const sqlLabColumns: TableColumnsType<ISQLLabObject> = useMemo(
    () => [
      {
        title: t('sqlLab.rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        ...getColumnSearchProps('name'),
      },
      {
        title: t('sqlLab.rootTable.scope'),
        dataIndex: 'object_scope',
        key: 'object_scope',
        render: (object_scope: Scope) => (
          <DatasetScopeList currentStudyName={'-'} onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
        ),
      },
      {
        width: 120,
        key: 'libraryVersion',
        title: t('sqlLab.rootTable.libraryVersion'),
        dataIndex: 'version',
        render: (_, record) => (Array.isArray(record.library_id) && record.library_id.length ? record.version : '-'),
      },
      {
        width: 100,
        title: t('sqlLab.rootTable.source'),
        key: 'source',
        dataIndex: 'source',
        render: (_, record) => record?.source || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { sqlLabColumns, studiesDetailsModal, setStudiesDetailsModal, locale };
};
