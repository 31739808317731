import { appApi } from '@config/appApi';
import { ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscConstants';
import {
  IAnalysisObjectPscListQueryParams,
  IAnalysisObjectPscListResponse,
  IAnalysisObjectPscQuery,
  TAnalysisObjectPscSourcesResponse,
} from '@modules/library/analysisObjects/psc/AnalysisObjectPscTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const AnalysisObjectPscApiRoutes = {
  list: '/api/analysis/psc',
  psc: (pscId: number) => `/api/analysis/psc/${pscId}`,
  filters: '/api/analysis/psc/filters',
};

const AnalysisObjectPscInvalidations: {
  LIST: TagDescription<ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.ID>;
  FILTERS: TagDescription<ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.FILTERS>;
} = {
  LIST: { type: ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (modelId: number) => ({ type: ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.ID, modelId }),
  FILTERS: { type: ANALYSIS_OBJECT_PSC_TAG_DESCRIPTION.FILTERS, id: 'FILTERS' },
};

export const AnalysisObjectPscApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectPscList: builder.query<IAnalysisObjectPscListResponse, IAnalysisObjectPscListQueryParams | void>({
      providesTags: [AnalysisObjectPscInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectPscApiRoutes.list,
      }),
    }),
    analysisObjectPscFilters: builder.query<TAnalysisObjectPscSourcesResponse, void>({
      providesTags: [AnalysisObjectPscInvalidations.FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectPscApiRoutes.filters,
      }),
    }),
    deleteAnalysisObjectPsc: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        AnalysisObjectPscInvalidations.LIST,
        AnalysisObjectPscInvalidations.ID(id),
      ],
      query: (id) => ({
        method: 'DELETE',
        url: AnalysisObjectPscApiRoutes.psc(id),
      }),
    }),
    copyAnalysisObjectPsc: builder.mutation<void, IAnalysisObjectPscQuery>({
      invalidatesTags: [AnalysisObjectPscInvalidations.LIST],
      query: ({ data, source }) => ({
        params: { source },
        data,
        method: 'POST',
        url: AnalysisObjectPscApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectPscListQuery,
  useAnalysisObjectPscFiltersQuery,
  useDeleteAnalysisObjectPscMutation,
  useCopyAnalysisObjectPscMutation,
} = AnalysisObjectPscApi;
