import { useDispatch, useSelector } from 'react-redux';
import { DatasetModalsEditStackDataset, IDatasetModalsEditStackDatasetProps } from './components';
import { EDatasetModalsType } from './DatasetModalsConstants';
import { selectDatasetModals } from '../duck/datasetSelectors';
import { datasetActions } from '../duck/datasetSlice';

export const DatasetModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectDatasetModals);

  const closeModal = () => {
    dispatch(datasetActions.dropModal());
  };

  return (
    <DatasetModalsEditStackDataset
      open={selectedModal === EDatasetModalsType.editStackDataset}
      data={data as IDatasetModalsEditStackDatasetProps['data']}
      onClose={closeModal}
    />
  );
};
