import { Alert, Button, Form, FormLayout, notification, Tabs, Tooltip } from '@components/ui';
import { DiscoverTab } from '@modules/job/modals/components/upload/steps/components/DiscoverTab';
import { TabProps } from '@modules/job/JobTypes';
import { SKIP_COLUMN } from '@modules/job/duck/constants';
import { PreviewTabData } from '@modules/job/modals/components/upload/steps/components/PreviewTabData';
import { DiscoverTabManual } from '@modules/job/modals/components/upload/steps/components/DiscoverTabManual';
import { ProcessJobParams, ProcessParams } from '@modules/job/duck/jobApi';
import { emptyError } from '@modules/job/modals/components/upload/duck/uploadConstants';
import {
  findTabObject,
  initValues,
  isAllTabConfirmed,
  isEmptyPK,
  isNoMapping,
  isNoRT,
  isNoSourceColumn,
  missingColumns,
  removeSkipColumns,
  truncateString,
} from '@modules/job/modals/components/upload/duck/uploadUtils';
import { Loader, QueryError } from '@components';
import { TFunction } from 'i18next';
import { CheckCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { isEmpty, isObject } from 'lodash';
import { CSSObject } from '@emotion/react';
import { Trans } from 'react-i18next';

export const ConfigureStep = ({
  onCancel,
  t,
  setCurrent,
  tabs,
  updateTabPartially,
  updateAllTabs,
  processParams,
  referenceTables,
  refTableInfo,
  referenceTablesLoading,
  referenceTablesInfoLoading,
  increaseProgress,
  isManualUpload,
  canImportJobEditStructure,
  setConfiguredProcessParams,
  runProcessJob,
  studyId,
}: ConfigureStepProps) => {
  const [form] = Form.useForm();

  const getTabErrorMessage = (tabName: string, checkConfirm: boolean = true) => {
    const validatedObject = findTabObject(tabName, tabs);

    if (validatedObject) {
      if (!isManualUpload && isNoRT(validatedObject)) {
        return t('uploadRT.errors.emptyRT');
      }

      if (isNoMapping(validatedObject)) {
        return t('uploadRT.errors.emptyMapping');
      }

      const errorColumns = isNoSourceColumn(validatedObject);
      if (isManualUpload && errorColumns) {
        return (
          <Trans
            i18nKey="job.uploadRT.errors.noColumnInFile"
            components={{
              icon: <QuestionCircleOutlined />,
              tooltip: <Tooltip title={truncateString(errorColumns, 50)} />,
            }}
          />
        );
      }

      const missingMandatoryColumns = missingColumns(validatedObject);
      if (isManualUpload && missingMandatoryColumns) {
        return t('uploadRT.errors.missingRequiredColumns', { list: missingMandatoryColumns });
      }

      if (isEmptyPK(validatedObject)) {
        return t('uploadRT.errors.emptyPK');
      }

      if (checkConfirm && !isAllTabConfirmed(validatedObject)) {
        return t('uploadRT.errors.confirmMapping');
      }
    }

    return '';
  };

  const validateTabs = () =>
    tabs.map((tab) => {
      const msg = getTabErrorMessage(tab.tableName, true);
      if (msg) {
        return { ...tab, isError: true, errorMessage: msg };
      }
      return { ...tab, ...emptyError };
    });

  const handleTabConfirm = (tableName: string) => {
    const tabErrorMsg = getTabErrorMessage(tableName, false);
    if (tabErrorMsg) {
      updateTabPartially(tableName, { confirmed: false, isError: true, errorMessage: tabErrorMsg });
    } else {
      updateTabPartially(tableName, { confirmed: true, ...emptyError });
    }
  };

  const onSubmitProcess = async () => {
    const validatedTabs = validateTabs() || [];
    const isContainErrors = validatedTabs.some((el) => el.errorMessage);

    if (isContainErrors) {
      updateAllTabs(validatedTabs);
      notification.error({ message: t('uploadRT.errors.submitError') });
      return;
    }

    setCurrent(3);
    try {
      const updMappingData = tabs.map((el) => ({
        tableName: el.tableName,
        referenceTable: el?.referenceTable,
        total_columns: el.total_columns,
        total_rows: el.total_rows,
        mapping: removeSkipColumns(el.mapping).map(({ sourceMappingColumn, skip, ...rest }) =>
          rest.sourceColumn === SKIP_COLUMN ? { ...rest, sourceColumn: undefined } : rest,
        ),
      }));

      const data = {
        ...processParams,
        data: updMappingData,
        callback: increaseProgress,
      };

      setConfiguredProcessParams(data);
      await runProcessJob(data);
    } catch (e: any) {
      if (e?.name === 'AbortError') {
        console.warn('Request aborted');
      } else {
        console.error(e);
      }
    }
  };

  const renderStatusIcon = (tabName: string) => {
    const tab = findTabObject(tabName, tabs);

    if (tab?.isError) {
      return <ExclamationCircleOutlined style={{ color: 'red', marginRight: 0 }} />;
    }

    if (tab?.confirmed) {
      return <CheckCircleFilled style={{ color: 'green', marginRight: 0 }} />;
    }

    return '';
  };

  if (!tabs) {
    return <Loader mode="absolute" size="large" />;
  }

  if (tabs && !tabs.length) {
    return (
      <div css={cssNoDiscover}>
        <QueryError
          error={{ status: 'info', data: { userMsg: t('uploadModal.noDiscoverDataSubtitle') } }}
          title={t('uploadModal.noDiscoverDataTitle')}
          extra={
            <Button type="primary" key="closeModal" onClick={onCancel}>
              {t('close')}
            </Button>
          }
        />
      </div>
    );
  }

  const initValuesData = isManualUpload ? initValues(tabs) : undefined;

  return (
    <FormLayout
      form={form}
      initialValues={initValuesData}
      onCancel={onCancel}
      onSubmit={onSubmitProcess}
      okText={t('Submit')}
      onFinishFailed={({ values }) => {
        if (!isEmpty(values)) {
          const tabNamesWithUndefinedValue = Object.keys(values).filter((sheet) => {
            const tables: { structure: Record<string, any> } = values[sheet];
            if (isObject(tables)) {
              return Object.values(tables).some((table) => Object.values(table.structure).includes(undefined));
            }
            return !tables;
          });

          const preparedTabNames = tabNamesWithUndefinedValue.map((el) => el.split('.')[0]);
          if (preparedTabNames) {
            const updMapping = tabs.map((el) =>
              preparedTabNames.includes(el.tableName)
                ? { ...el, isError: true, errorMessage: t('uploadRT.errors.emptyFormFields') }
                : { ...el, ...emptyError },
            );
            updateAllTabs(updMapping);
          }
        }
      }}
    >
      <Row css={cssFormContainer}>
        <Col span={24}>
          <Tabs
            css={cssTabs}
            defaultActiveKey={tabs[0]?.tableName}
            size="small"
            items={tabs.map((tab) => ({
              key: tab.tableName,
              label: (
                <div>
                  {tab.tableName} {renderStatusIcon(tab.tableName)}
                </div>
              ),
              children: (
                <>
                  {isManualUpload ? (
                    <DiscoverTabManual
                      key={`Manual_${tab.tableName}`}
                      t={t}
                      tab={tab}
                      canImportJobEditStructure={canImportJobEditStructure}
                      updateTabPartially={updateTabPartially}
                      handleTabConfirm={handleTabConfirm}
                    />
                  ) : (
                    <DiscoverTab
                      key={`RT_${tab.tableName}`}
                      form={form}
                      t={t}
                      tab={tab}
                      updateTabPartially={updateTabPartially}
                      handleTabConfirm={handleTabConfirm}
                      referenceTables={referenceTables}
                      refTableInfo={refTableInfo}
                      referenceTablesLoading={referenceTablesLoading}
                      referenceTablesInfoLoading={referenceTablesInfoLoading}
                      studyId={studyId}
                    />
                  )}
                  <PreviewTabData data={tab?.sample || []} t={t} />
                  {tab.errorMessage && <Alert css={cssTabError} message={tab.errorMessage} type="error" showIcon />}
                </>
              ),
            }))}
          />
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssFormContainer = (): CSSObject => ({
  flex: 1,
});

const cssTabs = (): CSSObject => ({
  '& .ant-tabs-nav': {
    marginBottom: 12,
  },
});

const cssTabError = (): CSSObject => ({
  minWidth: 560,
  width: 'calc(100% - 300px)',
  position: 'fixed',
  bottom: 20,
  height: 40,
});

const cssNoDiscover = (): CSSObject => ({
  alignContent: 'center',
  height: '100%',
});

interface ConfigureStepProps {
  t: TFunction;
  onCancel: () => void;
  tabs: TabProps[];
  updateAllTabs: (val: TabProps[]) => void;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  setCurrent: (val: number) => void;
  processParams: ProcessParams;
  referenceTables: string[];
  refTableInfo: any;
  referenceTablesLoading: boolean;
  referenceTablesInfoLoading: boolean;
  increaseProgress: (val?: number) => void;
  isManualUpload?: boolean;
  canImportJobEditStructure?: boolean;
  setConfiguredProcessParams: (params: ProcessJobParams) => void;
  runProcessJob: (params: ProcessJobParams) => void;
  studyId: number;
}
