import { StudyDetails as StudyDetailsType } from '@modules/study/StudyTypes';
import { useTranslation } from 'react-i18next';
import { StudyDetailsTemplate } from './StudyDetailsTemplate';

export const StudyDetails = ({ study }: StudyDetailsProps) => {
  const { t } = useTranslation(['study']);

  const detailsMap = [
    {
      key: t('detailsPage.studyName'),
      value: study.uniqueIdentifier,
    },
    {
      key: t('detailsPage.briefTitle'),
      value: study.name,
    },
    {
      key: t('detailsPage.officialTitle'),
      value: study.officialTitle,
    },
    {
      key: t('detailsPage.briefSummary'),
      value: study.summary,
    },
    {
      key: t('detailsPage.sponsor'),
      value: study.sponsor,
    },
    {
      key: t('detailsPage.screeningStartDate'),
      value: study.screeningStartDateValue,
    },
    {
      key: t('detailsPage.secondaryIDs'),
      value: study.secondaryIdentifier,
    },
    {
      key: t('detailsPage.collaborators'),
      value: study.collaborators,
    },
    {
      key: t('detailsPage.principalInvestigator'),
      value: study.principalInvestigator,
    },
    {
      key: t('detailsPage.protocolVerification'),
      value: study.protocolDescription,
    },
    {
      key: t('detailsPage.protocolID'),
      value: study.protocolId,
    },
  ];

  const conditionsAndEligibilityMap = [
    {
      key: t('detailsPage.maximumAge'),
      value: study.ageMax,
    },
    {
      key: t('detailsPage.minimumAge'),
      value: study.ageMin,
    },
  ];

  return (
    <StudyDetailsTemplate
      study={study}
      detailsMap={detailsMap}
      conditionsAndEligibilityMap={conditionsAndEligibilityMap}
    />
  );
};

interface StudyDetailsProps {
  study: Partial<StudyDetailsType>;
}
