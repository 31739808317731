import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ECdrType } from '@modules/library/cdr/LibraryCdrTypes';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILibraryImportCDR } from '../components/LibraryImportCDR';

export const useCdrColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportCDR>();
  const { t } = useTranslation(['libraryCdr']);

  const cdrColumns: TableColumnsType<ILibraryImportCDR> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        ...getColumnSearchProps('name'),
      },
      {
        width: 100,
        title: t('rootTable.cdrType'),
        key: 'type',
        dataIndex: 'type',
        render: (value: ECdrType) => ECdrType[value],
      },
      {
        width: 120,
        key: 'sourceVersion',
        title: t('rootTable.sourceVersion'),
        dataIndex: 'sourceVersion',
      },
      {
        width: 120,
        key: 'libraryVersion',
        title: t('rootTable.libraryVersion'),
        dataIndex: 'libraryVersion',
        render: (_, record) => record.libraryVersion || '-',
      },
      {
        title: t('rootTable.source'),
        key: 'source',
        dataIndex: 'source',
        render: (_, record) => record?.source || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { cdrColumns, locale };
};
