import { IAnalysisObjectSQLLabModalsImportProps } from './modals/AnalysisObjectSQLLabModalsImport';
import { IAnalysisObjectCodeLabModalsImportProps } from './modals/AnalysisObjectCodeLabModalsImport';

export enum EAnalysisObjectModalsType {
  'importSQLDataset' = 'importSQLDataset',
  'importCodeLab' = 'importCodeLab',
}

export type TAnalysisObjectModalsPushModalPayload =
  | {
      type: EAnalysisObjectModalsType.importSQLDataset;
      data?: IAnalysisObjectSQLLabModalsImportProps['data'];
    }
  | {
      type: EAnalysisObjectModalsType.importCodeLab;
      data?: IAnalysisObjectCodeLabModalsImportProps['data'];
    };

export type TAnalysisObjectModalsState = {
  selectedModal: EAnalysisObjectModalsType | null;
  data?: IAnalysisObjectSQLLabModalsImportProps['data'] | IAnalysisObjectCodeLabModalsImportProps['data'];
};
