import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { ILibrarySqlQuery, ILibrarySqlQueryQuery } from '@modules/library/sqlQuery/LibrarySqlQueryTypes';
import { ILibraryImportSqlQuery } from '../components/LibraryImportSqlQuery';

export const prepareLibrarySqlQuery = (
  data: ILibraryImportSqlQuery[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibrarySqlQueryQuery['data'] => {
  if (!Array.isArray(data) || kind !== ELibrarySourceType.Library) return [];

  return data.map((item) => {
    const rawData = item.rawData as ILibrarySqlQuery;
    return {
      id: rawData.id,
      name: rawData.name,
      description: rawData.description,
      configuration: rawData.configuration,
      source: rawData.source || '',
      source_id: rawData.source_id || null,
      env: rawData.env || '',
      tenant_info: rawData.tenant_info || '',
    };
  });
};
