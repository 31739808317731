import {
  ECdrType,
  ILibraryCDR,
  ILibraryCDRResponse,
  TLibraryCdrDeleteProps,
} from '@modules/library/cdr/LibraryCdrTypes';
import { Table } from '@ui';
import { Trash } from '@components/icons';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getReportVersionLabel } from '@shared/utils/common';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';

export const LibraryCdrList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDelete,
  t,
}: CdrReportListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const columns: TableColumnsType<ILibraryCDR> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          width: 150,
          title: t('rootTable.cdrType'),
          dataIndex: 'object_type',
          render: (_: any, record: ILibraryCDR) => ECdrType[record.object_type],
          // TODO: there is only one type for now. Filters should be added when DNA change it in the api
          // filters: getTableListOption(CDR_TYPES),
          // onFilter: undefined,
        },
        {
          width: 150,
          title: t('rootTable.sourceVersion'),
          render: (_: any, record: ILibraryCDR) => getReportVersionLabel(record?.configuration?.version_label),
        },
        {
          width: 150,
          title: t('rootTable.libraryVersion'),
          dataIndex: 'version',
        },
        {
          title: t('rootTable.source'),
          key: 'narrow',
          dataIndex: 'source',
          render: (_: any, record: ILibraryCDR) => (
            <ActorAndInfo
              info={supportedEnvs[record.env]?.label || t('na')}
              actor={record.source || t('na')}
              actorSize={'sm'}
            />
          ),
        },
        {
          title: t('rootTable.created'),
          key: 'narrow',
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (createdAt: number, record: ILibraryCDR) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
          ),
        },
        onDelete && {
          width: 150,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_: any, { id, name }: ILibraryCDR) => (
            <ButtonWithConfirmation
              icon={<Trash color="darkGrey" />}
              submitFunc={() => onDelete({ id, name })}
              confirmContent={t('confirmation.description', { name })}
              confirmTitle={t('confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ].filter(Boolean) as TableColumnsType<ILibraryCDR>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, onDelete],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface CdrReportListProps {
  libraryId: number;
  data?: ILibraryCDRResponse;
  pagination?: TableProps<ILibraryCDRResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibraryCDRResponse['items'][0]>['onChange'];
  onDelete?: (val: TLibraryCdrDeleteProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
