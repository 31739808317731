import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const ChevronRightIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M9 5L16 12L9 19"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
