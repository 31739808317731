import { Dropdown, Skeleton } from '@ui';
import { ConfirmModal, useConfirmModal } from '@components';
import { DownOutlined } from '@ant-design/icons';
import { DropdownProps } from 'antd';
import { CSSObject, Theme } from '@emotion/react';
import { ReactNode } from 'react';

export const MoreButton = ({ label, menu, loading, deleteConfig }: MoreButtonProps) => {
  const confirmModal = useConfirmModal();

  const deleteMenuItem = {
    key: 'delete',
    label: deleteConfig?.itemLabel ?? 'Delete',
    disabled: deleteConfig?.disabled ?? false,
    danger: deleteConfig?.danger ?? true,
  };
  const menuItems = menu?.items ?? [];

  const onClickHandle = async (info: any) => {
    const { key } = info;
    if (menu?.onClick) {
      await menu.onClick({ ...info, key });
    }

    if (key === 'delete') {
      confirmModal.openConfirmation({
        content: deleteConfig?.confirmContent,
      });
    }
  };

  const preparedMenu = {
    ...menu,
    items: deleteConfig ? [...menuItems, deleteMenuItem] : menuItems,
    onClick: onClickHandle,
    style: menu?.style ?? { width: 180 },
  };

  return loading ? (
    <Skeleton.Button active />
  ) : (
    <>
      <Dropdown menu={preparedMenu} trigger={['click']} placement="bottomRight" autoAdjustOverflow={false}>
        <button onClick={(e) => e.preventDefault()} css={cssActionBtn}>
          {label ?? 'More'} <DownOutlined />
        </button>
      </Dropdown>

      {deleteConfig && (
        <ConfirmModal
          title={deleteConfig.confirmTitle}
          submitFunc={deleteConfig.submitFunc}
          okText={deleteConfig?.confirmOkText ?? 'Delete'}
          okButtonProps={{ danger: true }}
          {...confirmModal}
        />
      )}
    </>
  );
};

interface MoreButtonProps {
  menu: DropdownProps['menu'];
  loading?: boolean;
  label?: string;
  deleteConfig?: {
    itemLabel?: string;
    disabled?: boolean;
    danger?: boolean;
    submitFunc: () => void;
    confirmTitle: string;
    confirmContent: ReactNode;
    confirmOkText?: string;
  };
}

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme['color-brand-blue-500'],
  cursor: 'pointer',
  padding: 0,
  marginLeft: 8,
});
