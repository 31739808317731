import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TDatasetModalsPushModalPayload, TDatasetModalsState } from '../modals/DatasetModalsTypes';

const initialState: DatasetStore = {
  modals: { selectedModal: null },
};

export const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TDatasetModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const datasetActions = datasetSlice.actions;

export const datasetReducer = datasetSlice.reducer;

interface DatasetStore {
  modals: TDatasetModalsState;
}
