import { Modal, Typography } from '@ui';
import { ICascadeObject } from '@shared/components/CascadeObjects';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd';
import { CSSObject, Theme } from '@emotion/react';

const initStage: IConfirmModalStage = {
  isOpen: false,
};

export const useConfirmModal = () => {
  const [confirmState, setConfirmState] = useState<IConfirmModalStage>(initStage);

  const openConfirmation = (data: Omit<IConfirmModalStage, 'isOpen'>) => {
    setConfirmState((prevState) => ({ ...prevState, ...data, isOpen: true }));
  };

  const closeConfirmation = () => {
    setConfirmState(initStage);
  };

  return {
    confirmState,
    openConfirmation,
    closeConfirmation,
  };
};

export const ConfirmModal = ({
  title,
  confirmState,
  okText,
  cancelText,
  children,
  submitFunc,
  closeConfirmation,
  okButtonProps,
}: IConfirmModalProps) => {
  const { t } = useTranslation(['shared']);
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    setLoading(true);
    await submitFunc(confirmState.data, confirmState?.options);
    setLoading(false);
    closeConfirmation();
  };

  return (
    <Modal
      width={526}
      title={
        <Typography.Title level={4} style={{ padding: '4px 0' }}>
          {title}
        </Typography.Title>
      }
      open={confirmState.isOpen}
      confirmLoading={loading}
      onOk={onOk}
      okButtonProps={okButtonProps}
      onCancel={closeConfirmation}
      okText={okText || t('confirm')}
      cancelText={cancelText || t('cancel')}
      children={
        <>
          {confirmState.content}
          {children}
        </>
      }
      css={cssModal}
    />
  );
};

const cssModal = (theme: Theme): CSSObject => ({
  top: 'calc(50% - 100px)',

  '& .ant-modal-content': {
    padding: 0,
  },
  '& .ant-modal-close': {
    top: 12,
    width: 40,
    height: 40,
  },
  '& .ant-modal-header': {
    padding: '12px 24px',
    borderBottom: `1px solid ${theme['color-grey-300']}`,
    marginBottom: 0,
  },
  '& .ant-modal-body': {
    padding: '16px 24px',
  },
  '& .ant-modal-footer': {
    marginTop: 40,
    padding: '12px 24px',
    borderTop: `1px solid ${theme['color-grey-300']}`,
    '> button': {
      height: 40,
    },
  },
});

interface IConfirmModalProps extends ReturnType<typeof useConfirmModal> {
  title: string;
  okText?: string;
  cancelText?: string;
  children?: ReactNode;
  submitFunc: (data?: any, options?: Record<string, any>) => void;
  okButtonProps?: ButtonProps;
}

interface IConfirmModalStage {
  isOpen: boolean;
  data?: any;
  content?: ReactNode;
  cascadeObjects?: ICascadeObject;
  options?: { libraryId?: number };
}
