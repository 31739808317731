import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const FolderOpenFilled: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.39844 7.1998C2.39844 6.56329 2.65129 5.95284 3.10138 5.50275C3.55147 5.05266 4.16192 4.7998 4.79844 4.7998H9.59844L11.9984 7.1998H16.7984C17.435 7.1998 18.0454 7.45266 18.4955 7.90275C18.9456 8.35284 19.1984 8.96329 19.1984 9.5998V10.7998H9.59844C8.64366 10.7998 7.72798 11.1791 7.05285 11.8542C6.37772 12.5294 5.99844 13.445 5.99844 14.3998V16.1998C5.99844 16.6772 5.8088 17.135 5.47123 17.4726C5.13366 17.8102 4.67583 17.9998 4.19844 17.9998C3.72105 17.9998 3.26321 17.8102 2.92565 17.4726C2.58808 17.135 2.39844 16.6772 2.39844 16.1998V7.1998Z"
      fill="currentColor"
    />
    <path
      d="M7.19844 14.4C7.19844 13.7635 7.45129 13.153 7.90138 12.7029C8.35147 12.2529 8.96192 12 9.59844 12H19.1984C19.835 12 20.4454 12.2529 20.8955 12.7029C21.3456 13.153 21.5984 13.7635 21.5984 14.4V16.8C21.5984 17.4365 21.3456 18.047 20.8955 18.4971C20.4454 18.9471 19.835 19.2 19.1984 19.2H2.39844H4.79844C5.43496 19.2 6.04541 18.9471 6.49549 18.4971C6.94558 18.047 7.19844 17.4365 7.19844 16.8V14.4Z"
      fill="currentColor"
    />
  </BaseIcon>
));
