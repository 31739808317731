import { Tabs } from '@ui';
import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { NotificationsArchivedPane } from './NotificationsArchivedPane';
import { NotificationsActivePane } from './NotificationsActivePane';
import { TNotification } from '../duck/notificationsApi';

const tabs = {
  notifications: {
    id: 'notifications',
  },
  archive: {
    id: 'archive',
  },
};

export const NotificationsPanel = ({ notifications, archived }: NotificationsPanelProps) => {
  const { t } = useTranslation(['shared']);

  return (
    <div css={cssPanel} onClick={(e) => e.stopPropagation()}>
      <Tabs
        defaultActiveKey={tabs.notifications.id}
        size="small"
        items={[
          {
            key: tabs.notifications.id,
            label: t(`notification.tabs.${tabs.notifications.id}`) + ` (${notifications.length})`,
            children: <NotificationsActivePane notifications={notifications} />,
          },
          {
            key: tabs.archive.id,
            label: t(`notification.tabs.${tabs.archive.id}`) + ` (${archived.length})`,
            children: <NotificationsArchivedPane archived={archived} />,
          },
        ]}
      />
    </div>
  );
};

const cssPanel = (): CSSObject => ({
  width: '450px',
  height: '560px',
});

interface NotificationsPanelProps {
  notifications: TNotification[];
  archived: TNotification[];
}
