import {
  TLibrarySqlLabModalsPushModalPayload,
  TLibrarySqlLabModalsState,
} from '@modules/library/libraryObject/libraryObjectTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibraryObjectStore = {
  modals: { selectedModal: null },
};

export const libraryObjectSlice = createSlice({
  name: 'libraryObject',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibrarySqlLabModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const libraryObjectActions = libraryObjectSlice.actions;

export const libraryObjectReducer = libraryObjectSlice.reducer;

interface LibraryObjectStore {
  modals: TLibrarySqlLabModalsState;
}
