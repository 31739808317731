import { StudyDetails as StudyDetailsType } from '@modules/study/StudyTypes';
import { useTranslation } from 'react-i18next';
import { StudyDetailsTemplate } from './StudyDetailsTemplate';

export const CrossStudyDetails = ({ study }: CrossStudyDetailsProps) => {
  const { t } = useTranslation(['study']);

  const detailsMap = [
    {
      key: t('detailsPage.studyName'),
      value: study.name,
    },
    {
      key: t('detailsPage.briefSummary'),
      value: study.summary,
    },
  ];

  return <StudyDetailsTemplate study={study} detailsMap={detailsMap} />;
};

interface CrossStudyDetailsProps {
  study: Partial<StudyDetailsType>;
}
