import { Alert, DraggableModal, Form, FormItem, FormLayout, notification, Typography } from '@ui';
import { Domain } from '@modules/job/JobTypes';
import { useDeleteJobMutation } from '@modules/job/duck/jobApi';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

const JobModalsDeleteConfirmContent = ({ data, onClose, t }: JobModalsDeleteConfirmContentProps) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [deleteJob] = useDeleteJobMutation();

  const handleDeleteDomain = async () => {
    try {
      await deleteJob({
        tableName: data.table_name,
        storeId: data.data_store_id!,
        jobId: data.id,
        database: data.database,
      }).unwrap();
      onClose();
      notification.success({ message: t('notification.form.successMessageDelete', { name: data.table_name }) });
    } catch (e) {
      api.error({ message: (e as QueryErrorType)?.data?.userMsg });
      notification.error({
        message: t('notification.form.errorMessageDelete', { name: data.table_name }),
      });
    }
  };

  return (
    <FormLayout
      wrapperCol={{ span: 24 }}
      form={form}
      onCancel={onClose}
      onSubmit={handleDeleteDomain}
      okText={t('delete')}
      isDangerous
    >
      <FormItem>
        <Typography.Text>{t('deleteJobModal.confirmation', { name: data?.table_name })}</Typography.Text>
        <Alert
          css={cssAlert}
          type="warning"
          message={<span dangerouslySetInnerHTML={{ __html: t('deleteJobModal.confirmationNote') }} />}
        />
      </FormItem>
      {contextHolder}
    </FormLayout>
  );
};
export const JobModalsDeleteConfirm = ({ open, data, onClose }: JobModalsDeleteConfirmProps) => {
  const { t } = useTranslation(['job']);
  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('deleteJobModal.title')}
      width={540}
      footer={null}
      destroyOnClose
    >
      {open && <JobModalsDeleteConfirmContent data={data} t={t} onClose={onClose} />}
    </DraggableModal>
  );
};

const cssAlert = (): CSSObject => ({
  marginTop: 24,
});

export interface JobModalsDeleteConfirmProps {
  open: boolean;
  data: Domain;
  onClose: () => void;
}

interface JobModalsDeleteConfirmContentProps extends Pick<JobModalsDeleteConfirmProps, 'data' | 'onClose'> {
  t: TFunction;
}
