import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const ArrowRight: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={20} height={20} fill="none" viewBox="0 0 20 20">
    <path
      d="M4.16634 10L15.833 10M15.833 10L9.99968 4.16671M15.833 10L9.99967 15.8334"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
