import { Tag } from '@ui';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { LibraryStatus } from '../duck/libraryConstants';

export const LibraryStatusTag = ({ status, calm = false, small = false }: LibraryStatusTagProps) => {
  const { t } = useTranslation(['libraryRoot']);
  const theme = useTheme();

  const colors = calm
    ? {
        [LibraryStatus.Active]: 'green',
        [LibraryStatus.Development]: 'blue',
        [LibraryStatus.Retired]: 'red',
      }
    : {
        [LibraryStatus.Active]: 'success',
        [LibraryStatus.Development]: 'processing',
        [LibraryStatus.Retired]: 'error',
      };

  if (status) {
    return (
      <Tag color={colors[status]} bordered={!calm} css={small ? cssSmall : undefined}>
        {t(`status.${status}`)}
      </Tag>
    );
  }

  return null;
};

const cssSmall = css({
  fontSize: '80%',
});

type LibraryStatusTagProps = {
  status?: LibraryStatus;
  calm?: boolean;
  small?: boolean;
};
