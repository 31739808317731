import { ELibraryPscModalsType } from '@modules/library/psc/modals/LibraryPscModalsConstants';
import { libraryPscActions } from '@modules/library/psc/duck/libraryPscSlice';
import { selectLibraryPscModals } from '@modules/library/psc/duck/libraryPscSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryPscModalsCopyPsc, ILibraryPscModalsCopyPscProps } from './components';

export const LibraryPscModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibraryPscModals);

  const closeModal = () => {
    dispatch(libraryPscActions.dropModal());
  };

  return (
    <LibraryPscModalsCopyPsc
      open={selectedModal === ELibraryPscModalsType.copyPsc}
      data={data as ILibraryPscModalsCopyPscProps['data']}
      onClose={closeModal}
    />
  );
};
