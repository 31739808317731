import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeLabModalsPushModalPayload, CodeLabModalsState } from '../modals';

const initialState: CodeLabStore = {
  modals: { selectedModal: null },
};

export const codeLabSlice = createSlice({
  name: 'codeLab',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<CodeLabModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state) => {
      state.modals = initialState.modals;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const codeLabActions = codeLabSlice.actions;

export const codeLabReducer = codeLabSlice.reducer;

interface CodeLabStore {
  modals: CodeLabModalsState;
}
