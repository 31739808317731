import { Button, DraggableModal, Space, Spin, Tag, Typography } from '@ui';
import { ModelLogsType } from '@modules/model/ModelTypes';
import { Refetch } from '@components/icons';
import { useModelLogsQuery, useReviewModelLogsQuery } from '@modules/model/duck/modelApi';
import { Loader, PageSkeleton, QueryError } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { selectModelDataViewer } from '@modules/model/duck/modelSelectors';
import { SerializedError } from '@reduxjs/toolkit';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';

const ModelModalsLogContent = ({ data, isFetching, isLoading, error, t }: ModelModalsLogContentProps) => {
  const theme = useTheme();

  return (
    <>
      {isLoading && <PageSkeleton />}
      {isFetching && !isLoading && data?.finished_at && <Loader mode="absolute" />}
      {error && <QueryError error={error} title={t('logErrorTitle')} />}
      {!isLoading &&
        !error &&
        (data?.log ? (
          <Space full direction="vertical" css={cssContainer}>
            <Space block>
              <Typography.Text strong>Started:</Typography.Text>
              <Typography.Text type="secondary">
                {data?.started_at ? new Date(data?.started_at).toLocaleString() : ''}
              </Typography.Text>
            </Space>

            <Space block>
              <Typography.Text strong>Finished:</Typography.Text>
              <Typography.Text type="secondary">
                {data?.finished_at ? new Date(data?.finished_at).toLocaleString() : ''}
              </Typography.Text>
            </Space>

            <Scrollbars
              css={cssLogsLayout}
              autoHide={false}
              autoHeight
              autoHeightMin={500}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    width: '8px',
                    backgroundColor: theme['color-grey-300'],
                    borderRadius: 'inherit',
                  }}
                />
              )}
              renderThumbHorizontal={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    width: '8px',
                    backgroundColor: theme['color-grey-300'],
                    borderRadius: 'inherit',
                  }}
                />
              )}
            >
              <pre css={cssLogs}>{data?.log}</pre>
            </Scrollbars>
            {/*</Space>*/}
          </Space>
        ) : (
          <div css={cssNotFound}>
            <Typography.Title
              type="secondary"
              children={data?.message_id ? t('errors.logsNotStartedYet') : t('errors.logsNotFound')}
            />
          </div>
        ))}
    </>
  );
};

export const ModelModalsLog = ({ open, data, onClose }: ModelModalsLogProps) => {
  const { t } = useTranslation(['model']);
  const { currentAppEnv } = useEnvironments(false);
  const { isReview } = useSelector(selectModelDataViewer);

  const logQuery = useModelLogsQuery(data.modelId!, { skip: isReview || !data.modelId });
  const reviewLogsQuery = useReviewModelLogsQuery(
    { modelName: data.tableId!, source_env: currentAppEnv?.env },
    { skip: !isReview },
  );
  const currentLogQuery = isReview ? reviewLogsQuery : logQuery;
  const isFetching = currentLogQuery.isFetching;

  useEffect(() => {
    if (!currentLogQuery?.data?.finished_at && currentLogQuery?.data?.message_id) {
      const id = setTimeout(() => currentLogQuery.refetch(), 10000);
      return () => clearTimeout(id);
    }
  }, [currentLogQuery, currentLogQuery?.data?.finished_at]);

  const footerActions = (
    <div css={cssFooter}>
      <Button key="close" onClick={onClose} size="large">
        {t('close')}
      </Button>
      {/*<Button*/}
      {/*  key="download"*/}
      {/*  disabled={isFetching}*/}
      {/*  onClick={() => {*/}
      {/*    console.log('Download');*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {t('download')}*/}
      {/*</Button>,*/}
      <Button
        key="refresh"
        size="large"
        disabled={isFetching}
        onClick={currentLogQuery.refetch}
        icon={<Refetch css={{ color: 'inherit', fontSize: 24 }} />}
      >
        {t('refresh')}
      </Button>
    </div>
  );

  return (
    <DraggableModal
      width="80%"
      open={open}
      onCancel={onClose}
      title={
        <>
          {data.tableName} {t('logTitle')}
          {isReview && <Tag type="review" style={{ marginLeft: '10px' }} text="REVIEW" upperCaseText />}
          {!currentLogQuery?.data?.finished_at && currentLogQuery?.data?.message_id && (
            <Spin css={cssSpinner} indicator={<LoadingOutlined />} size="large" />
          )}
        </>
      }
      footer={footerActions}
      withActionDivider
      destroyOnClose
    >
      {open && (
        <ModelModalsLogContent
          data={currentLogQuery.data}
          isFetching={currentLogQuery.isFetching}
          isLoading={currentLogQuery.isLoading}
          error={currentLogQuery.error}
          t={t}
        />
      )}
    </DraggableModal>
  );
};

export interface ModelModalsLogProps {
  open: boolean;
  data: Partial<{ modelId: number; tableName: string; tableId: string }>;
  onClose: () => void;
}

export interface ModelModalsLogContentProps {
  isFetching: boolean;
  isLoading: boolean;
  error?: QueryErrorType | SerializedError;
  data?: ModelLogsType;
  t: TFunction;
}

const cssContainer = (): CSSObject => ({
  gap: 4,
});

const cssFooter = (): CSSObject => ({
  display: 'flex',
  justifyContent: 'end',
  columnGap: 12,
});

const cssLogsLayout = (): CSSObject => ({
  height: '500px',
  marginTop: '12px',
});

const cssLogs = (theme: Theme): CSSObject => ({
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme['color-grey-600'],
  overflow: 'visible',
  // TODO It's not readable with style as in mockup
  // whiteSpace: 'pre-wrap',
});

const cssNotFound = (): CSSObject => ({
  height: '500px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const cssSpinner = (): CSSObject => ({
  paddingLeft: '0.25rem',
});
