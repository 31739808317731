import { selectViewerLeftSideData, selectViewerSnapshot } from '@modules/viewer/duck/viewerSelectors';
import { SNAPSHOT_STATUS } from '@modules/snapshot/SnapshotTypes';
import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { Button, Select } from '@ui';
import { dateToTimezoneString } from '@shared/utils/Date';
import { LATEST_DATA_SNAPSHOT } from '@modules/snapshot/duck/snapshotConstants';
import { selectAppliedENVSwitch, selectGlobalStudy } from '@app/duck/appSelectors';
import { CrossStudyId } from '@config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

export const DataViewerSnapshotSelector = ({ tableName }: DataViewerSnapshotSelectorProps) => {
  const { t } = useTranslation(['snapshot']);
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const snapshot = useSelector(selectViewerSnapshot);
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch);
  const snapshotListQuery = useSnapshotListQuery(undefined, { skip: globalStudy?.id === CrossStudyId });
  const leftSideData = useSelector(selectViewerLeftSideData);

  const completedSnapshotList = useMemo(
    () =>
      snapshotListQuery.data
        ?.map((el) => ({
          ...el,
          tablesDetails:
            el.tablesDetails.filter(
              (item) => item.status === SNAPSHOT_STATUS.COMPLETED && leftSideData?.tableName === item.tableName,
            ) ?? [],
        }))
        .filter((el) => el.tablesDetails.length) ?? [],
    [snapshotListQuery.data, leftSideData?.tableName],
  );

  const snapshottedTables = useMemo(
    () => completedSnapshotList?.flatMap((el) => el.tablesDetails.map((item) => item.tableName)) ?? [],
    [completedSnapshotList],
  );

  const isSnapshottedSelectedTable = tableName && snapshottedTables.includes(tableName);

  useEffect(() => {
    if (snapshotListQuery.data?.length && !snapshot) {
      dispatch(viewerActions.setSnapshot({ ...LATEST_DATA_SNAPSHOT, value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotListQuery.data]);

  const snapshotOptionList = useMemo(
    () =>
      [{ label: LATEST_DATA_SNAPSHOT.description, value: '' } as { label: string; value?: string }].concat(
        ...(completedSnapshotList || []).map((el) => ({
          label: `${dateToTimezoneString(el.createdAt)} (${el.description})`,
          value: el.id,
        })),
      ),
    [completedSnapshotList],
  );

  const onChangeSnapshot = (value: string) => {
    const selectedSnapshot = snapshotListQuery.data!.find((el) => el.id === value) || LATEST_DATA_SNAPSHOT;

    dispatch(
      viewerActions.setSnapshot({
        ...selectedSnapshot,
        value: selectedSnapshot.id,
      }),
    );
  };

  if (!snapshotListQuery.data || !snapshotListQuery.data.length) {
    return null;
  }

  return !appliedENVSwitch && isSnapshottedSelectedTable ? (
    <Space.Compact style={{ order: 20 }}>
      <Button type="default" disabled style={{ cursor: 'auto' }}>
        {t('snapshotSelectLabel')}
      </Button>
      <Select
        size="large"
        popupMatchSelectWidth={false}
        value={snapshot?.value}
        options={snapshotOptionList}
        defaultActiveFirstOption
        onChange={onChangeSnapshot}
      />
    </Space.Compact>
  ) : null;
};

interface DataViewerSnapshotSelectorProps {
  tableName?: string | null;
}
