import { DraggableModal, Form, FormLayout, FormItem, Alert } from '@ui';
import { useDeleteJobConfigMutation, useSaveJobConfigMutation } from '@modules/codeLab/duck/codeLabApi';
import { ButtonWithConfirmation } from '@components';
import {
  getDefaultInitialValue,
  prepareFields,
  prepareUpdateData,
  RUN_TYPE,
  SchedulerRunForm,
  SchedulerRunFormValues,
} from '@components/form';
import { ICodeLabJob } from '@modules/codeLab/CodeLabTypes';
import { useAppContext } from '@app/AppContext';
import { parseCodeLabFilePath } from '@modules/codeLab/components/CodeLabFilePath';
import { QueryErrorType } from '@shared/utils/Error';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const CodeLabModalsSchedulerContent = ({ data, t, onClose }: CodeLabModalsSchedulerContentProps) => {
  const [form] = Form.useForm();
  const { appPermissions } = useAppPermissions();
  const [saveJobConfig, saveJobConfigQuery] = useSaveJobConfigMutation();
  const [deleteJobConfig, deleteJobConfigQuery] = useDeleteJobConfigMutation();
  const { notificationApi } = useAppContext();

  const isEdit = !!data.id;

  const onDelete = async () => {
    try {
      await deleteJobConfig(data.notebook_id!).unwrap();
      notificationApi.success({
        message: t('modals.scheduler.successMessageDelete', { name: parseCodeLabFilePath(data.name!) }),
      });
      onClose();
    } catch (error) {
      notificationApi.error({
        message: t('shared.errors.smthWrong'),
        description: (error as QueryErrorType).data.userMsg,
      });
    }
  };

  const onSubmit = async (values: CodeLabModalsShedulerFormFields) => {
    try {
      const updatedFields: Pick<ICodeLabJob, 'name' | 'notebook_id' | 'job'> & { id?: number } = {
        id: data.id,
        name: data.name!,
        notebook_id: data.notebook_id!,
        job: values.job ? prepareUpdateData({ values: values.job }) : data.job!,
      };

      await saveJobConfig(updatedFields).unwrap();
      notificationApi.success({
        message: t('modals.scheduler.successMessageSave', { name: parseCodeLabFilePath(data.name!) }),
      });
      onClose();
    } catch (error) {
      notificationApi.error({
        message: t('shared.errors.smthWrong'),
        description: (error as QueryErrorType).data.userMsg,
      });
    }
  };

  const notCorrectInputData = !data.name || !data.notebook_id;

  const initValues: CodeLabModalsShedulerFormFields = {
    job: data.job ? prepareFields(data.job!) : getDefaultInitialValue(RUN_TYPE.once),
  };

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      disabled={notCorrectInputData || !appPermissions.canCrossClSchedulerInsert}
      hideOkBtn={notCorrectInputData}
      submitIsDisabled={deleteJobConfigQuery.isLoading || !appPermissions.canCrossClSchedulerInsert}
      cancelIsDisabled={deleteJobConfigQuery.isLoading || saveJobConfigQuery.isLoading}
      extraActions={
        isEdit &&
        appPermissions.canCrossClSchedulerDelete && (
          <ButtonWithConfirmation
            submitFunc={onDelete}
            confirmContent={t('confirmation.description')}
            confirmTitle={t('confirmation.title')}
            loading={deleteJobConfigQuery.isLoading}
            disabled={saveJobConfigQuery.isLoading}
          />
        )
      }
      errors={saveJobConfigQuery.error ? [(saveJobConfigQuery.error as QueryErrorType).data.userMsg!] : undefined}
    >
      {notCorrectInputData && (
        <FormItem wrapperCol={{ span: 18 }}>
          <Alert type="warning" message={t('modals.scheduler.wrongInputData')} />
        </FormItem>
      )}
      <FormItem wrapperCol={{ span: 24 }}>
        <SchedulerRunForm
          isDisabledProp={notCorrectInputData}
          showShedulerLabel
          hideAutomaticOptions
          renderActiveField
        />
      </FormItem>
    </FormLayout>
  );
};

export const CodeLabModalsScheduler = ({ open, data, onClose }: CodeLabModalsSchedulerProps) => {
  const { t } = useTranslation(['codeLab']);

  return (
    <DraggableModal
      width="500px"
      open={open}
      onCancel={onClose}
      title={t('modals.scheduler.title', { filePath: parseCodeLabFilePath(data?.name || '') })}
      destroyOnClose
      footer={null}
    >
      {open && <CodeLabModalsSchedulerContent data={data} t={t} onClose={onClose} />}
    </DraggableModal>
  );
};

export interface CodeLabModalsSchedulerProps {
  open: boolean;
  data: Partial<Pick<ICodeLabJob, 'name' | 'notebook_id' | 'job' | 'id'>>;
  onClose: () => void;
}

export interface CodeLabModalsSchedulerContentProps extends Omit<CodeLabModalsSchedulerProps, 'open'> {
  t: TFunction;
}

interface CodeLabModalsShedulerFormFields {
  job: SchedulerRunFormValues;
}
