import { Link as BaseLink } from 'react-router-dom';
import { CSSObject, Theme } from '@emotion/react';

const LinkWithDisabled = (props: LinkProps) => <BaseLink {...props} css={cssLink(props?.disabled)} />;

type LinkProps = React.ComponentProps<typeof BaseLink> & { disabled?: boolean };

export { LinkWithDisabled as Link };

const cssLink = (disabled?: boolean): CSSObject => ({
  '&&': {
    fontWeight: 500,
    textDecoration: disabled ? 'none' : 'underline 1px transparent',
    transition: 'text-decoration-color 300ms',
    '&:hover': {
      textDecoration: disabled ? 'none' : 'underline',
    },
  },
});
