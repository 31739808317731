import { Tag } from '@ui';
import { AntdIconBox } from '@components/icons';
import { InformationModal } from '@components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const dashSymbol = <>&mdash;</>;

export const StatusTag = ({ showSuccessTag, showErrorTag, errorMessage }: StatusTagProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  if (showErrorTag) {
    return (
      <>
        <Tag color="error" children={t('status.errorTag')} />
        {errorMessage && (
          <AntdIconBox
            icon={QuestionCircleOutlined}
            tip={t('status.tooltipErrorIcon')}
            color="danger"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
        {openModal && (
          <InformationModal
            width="600px"
            autoHeightMin={200}
            title={t('errorDetails')}
            message={errorMessage}
            onClose={closeModal}
          />
        )}
      </>
    );
  }
  const success = <Tag color="success" children={t('status.successTag')} />;

  return showSuccessTag ? success : dashSymbol;
};

interface StatusTagProps {
  showErrorTag: boolean;
  showSuccessTag: boolean;
  errorMessage?: string;
}
