import { MenuType } from '@routes/appMenu';
import { TreeDataNode } from 'antd';
import { Key } from 'antd/es/table/interface';

export interface MenuTreeNode extends TreeDataNode {
  path?: string;
}

export const getGroupKey = (key: string): Key => `${key}-group`;

export const getMenuTreeData = (menu: MenuType): MenuTreeNode[] =>
  menu.map(({ key, title, icon, path, children }) => ({
    path: children ? undefined : path,
    title,
    key: children ? `${key}-group` : key,
    icon: icon ? icon : <></>,
    children: children && getMenuTreeData(children),
    isLeaf: !children,
  }));

export const getParentKeysByChildKey = (tree: MenuTreeNode[], childKey: Key, parentKeys: Key[] = []): Key[] => {
  for (const node of tree) {
    if (node.children) {
      const childFound = node.children.some((child) => child.key === childKey);
      if (childFound) {
        parentKeys.push(node.key);
        return parentKeys;
      }

      const result = getParentKeysByChildKey(node.children, childKey, [...parentKeys, node.key]);
      if (result.length) {
        return result;
      }
    }
  }

  return [];
};
