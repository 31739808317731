import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const Network: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M15 10.5H19.5C19.8978 10.5 20.2794 10.342 20.5607 10.0607C20.842 9.77936 21 9.39782 21 9V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3H15C14.6022 3 14.2206 3.15804 13.9393 3.43934C13.658 3.72064 13.5 4.10218 13.5 4.5V6H12C11.4033 6 10.831 6.23705 10.409 6.65901C9.98705 7.08097 9.75 7.65326 9.75 8.25V11.25H6.75V10.5C6.75 10.1022 6.59196 9.72064 6.31066 9.43934C6.02936 9.15804 5.64782 9 5.25 9H2.25C1.85218 9 1.47064 9.15804 1.18934 9.43934C0.908035 9.72064 0.75 10.1022 0.75 10.5V13.5C0.75 13.8978 0.908035 14.2794 1.18934 14.5607C1.47064 14.842 1.85218 15 2.25 15H5.25C5.64782 15 6.02936 14.842 6.31066 14.5607C6.59196 14.2794 6.75 13.8978 6.75 13.5V12.75H9.75V15.75C9.75 16.3467 9.98705 16.919 10.409 17.341C10.831 17.7629 11.4033 18 12 18H13.5V19.5C13.5 19.8978 13.658 20.2794 13.9393 20.5607C14.2206 20.842 14.6022 21 15 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V15C21 14.6022 20.842 14.2206 20.5607 13.9393C20.2794 13.658 19.8978 13.5 19.5 13.5H15C14.6022 13.5 14.2206 13.658 13.9393 13.9393C13.658 14.2206 13.5 14.6022 13.5 15V16.5H12C11.8011 16.5 11.6103 16.421 11.4697 16.2803C11.329 16.1397 11.25 15.9489 11.25 15.75V8.25C11.25 8.05109 11.329 7.86032 11.4697 7.71967C11.6103 7.57902 11.8011 7.5 12 7.5H13.5V9C13.5 9.39782 13.658 9.77936 13.9393 10.0607C14.2206 10.342 14.6022 10.5 15 10.5ZM5.25 13.5H2.25V10.5H5.25V13.5ZM15 15H19.5V19.5H15V15ZM15 4.5H19.5V9H15V4.5Z"
      fill="currentColor"
    />
  </BaseIcon>
));
