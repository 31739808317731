import { Empty, Typography } from '@ui';
import { NoData } from '@components/icons';
import { useTranslation } from 'react-i18next';

export const NoDataFound = ({ title, description }: NoDataFoundProps) => {
  const { t } = useTranslation();

  return (
    <Empty
      image={<NoData css={{ width: 138, height: 138 }} />}
      description={
        <>
          <Typography.Title level={4}>{title ?? t('noDataTitle')}</Typography.Title>
          <Typography.Text type="secondary" style={{ fontSize: 18, paddingTop: 4, lineHeight: 1.7 }}>
            {description ?? t('noDataDescription')}
          </Typography.Text>
        </>
      }
    />
  );
};

interface NoDataFoundProps {
  title?: string;
  description?: string;
}
