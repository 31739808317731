import { Store } from '@modules/stores/StoreTypes';
import { TableGroup } from '@modules/viewer/duck/viewerApi';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';

export const SYSTEM_STORE_NAMES = {
  REFERENCE_TABLE: 'Reference Table',
  GLOBAL_REFERENCE_TABLE: 'Global Reference Table',
  INTERNALS: 'Internals',
  GLOBAL_INTERNALS: 'Global Internals',
};

export enum STORE_TAG_DESCRIPTION {
  'ID' = 'STORE_ID',
  'LIST' = 'STORE_LIST',
  'STUDY_USER_LIST' = 'SOURCE_STUDY_USER_LIST',
  'SEARCH_USERS' = 'SEARCH_USERS',
}

export enum EXTERNAL_STORES {
  'GRT' = 'Global Reference Table',
  'NONCRF' = 'Non-CRF',
  'ClinicalModel' = 'Clinical Models',
  'RDEs' = 'RDEs',
  'Operational' = 'Operational',
  'Metadata' = 'Metadata',
  'GDS' = 'Global Internals',
  'GO' = 'Global Operational',
  'GSD' = 'Global Stack Data',
}

export const DEFAULT_STORES_CONNECTION = {
  value: -1,
  text: 'ClickHouse',
};

export const DEFAULT_EXTERNAL_STORES: Record<EXTERNAL_STORES, Required<TableGroup>> = {
  [EXTERNAL_STORES.GSD]: {
    id: -9,
    name: EXTERNAL_STORES.GSD,
    type: ViewerGroupType.globalStackDS,
    tables: [],
  },
  [EXTERNAL_STORES.GO]: {
    id: -8,
    name: EXTERNAL_STORES.GO,
    type: ViewerGroupType.globalOperational,
    tables: [],
  },
  [EXTERNAL_STORES.GDS]: {
    id: -7,
    name: EXTERNAL_STORES.GDS,
    type: ViewerGroupType.globalDS,
    tables: [],
  },
  [EXTERNAL_STORES.GRT]: {
    id: -6,
    name: EXTERNAL_STORES.GRT,
    type: ViewerGroupType.crossStudyRT,
    tables: [],
  },
  [EXTERNAL_STORES.Operational]: {
    id: -1,
    name: EXTERNAL_STORES.Operational,
    type: ViewerGroupType.external,
    tables: [],
  },
  [EXTERNAL_STORES.Metadata]: {
    id: -2,
    name: EXTERNAL_STORES.Metadata,
    type: ViewerGroupType.external,
    tables: [],
  },
  [EXTERNAL_STORES.ClinicalModel]: {
    id: -3,
    name: EXTERNAL_STORES.ClinicalModel,
    type: ViewerGroupType.external,
    tables: [],
  },
  [EXTERNAL_STORES.NONCRF]: {
    id: -5,
    name: EXTERNAL_STORES.NONCRF,
    type: ViewerGroupType.external,
    tables: [],
  },
  [EXTERNAL_STORES.RDEs]: {
    id: -4,
    name: EXTERNAL_STORES.RDEs,
    type: ViewerGroupType.external,
    tables: [],
  },
};

export const CREATED_BY_SYSTEM = 'System';

export const DEFAULT_STORES: Array<Store> = [
  {
    ...DEFAULT_EXTERNAL_STORES[EXTERNAL_STORES.GRT],
    description: `${EXTERNAL_STORES.GRT} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1715086978,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.Operational,
    description: `${EXTERNAL_STORES.Operational} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.Metadata,
    description: `${EXTERNAL_STORES.Metadata} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES[EXTERNAL_STORES.ClinicalModel],
    description: `${EXTERNAL_STORES.ClinicalModel} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES[EXTERNAL_STORES.NONCRF],
    description: `${EXTERNAL_STORES.NONCRF} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1715086978,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.RDEs,
    description: `${EXTERNAL_STORES.RDEs} views`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
];

export const DEFAULT_JOB_SETTINGS = {
  active: false,
  run_once: true,
  crontab: '',
  crontab_ui_repr: '',
};
