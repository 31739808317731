import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const Beaker: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M20.7834 18.7284L15 9.08625V3.75H15.75C15.9489 3.75 16.1397 3.67098 16.2803 3.53033C16.421 3.38968 16.5 3.19891 16.5 3C16.5 2.80109 16.421 2.61032 16.2803 2.46967C16.1397 2.32902 15.9489 2.25 15.75 2.25H8.25001C8.05109 2.25 7.86033 2.32902 7.71968 2.46967C7.57902 2.61032 7.50001 2.80109 7.50001 3C7.50001 3.19891 7.57902 3.38968 7.71968 3.53033C7.86033 3.67098 8.05109 3.75 8.25001 3.75H9.00001V9.08625L3.21657 18.7284C3.08017 18.9558 3.00649 19.2154 3.00304 19.4805C2.99959 19.7457 3.0665 20.007 3.19695 20.2379C3.3274 20.4688 3.51672 20.6609 3.74562 20.7948C3.97452 20.9287 4.23483 20.9995 4.50001 21H19.5C19.7654 21 20.0261 20.9295 20.2554 20.7959C20.4847 20.6622 20.6744 20.4701 20.8052 20.2391C20.936 20.0081 21.0031 19.7466 20.9998 19.4812C20.9965 19.2158 20.9228 18.9561 20.7863 18.7284H20.7834ZM10.3931 9.67969C10.4633 9.56342 10.5002 9.43016 10.5 9.29437V3.75H13.5V9.29437C13.4998 9.43016 13.5367 9.56342 13.6069 9.67969L17.19 15.6562C16.065 15.8784 14.4647 15.7847 12.3384 14.7084C10.8469 13.9537 9.42751 13.5534 8.09907 13.5075L10.3931 9.67969ZM4.50001 19.5L7.17563 15.0394C8.51157 14.8763 10.0172 15.2128 11.6588 16.0444C13.44 16.9453 14.94 17.2519 16.1588 17.2519C16.7786 17.2546 17.3957 17.1694 17.9916 16.9988L19.5 19.5H4.50001Z"
      fill="currentColor"
    />
  </BaseIcon>
));
