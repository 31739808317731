import {
  ILibraryPscListQueryParams,
  ILibraryPscQuery,
  ILibraryPscResponse,
  ILibraryPscValidateQuery,
} from '@modules/library/psc/PscTypes';
import { appApi } from '@config/appApi';
import { LIBRARY_PSC_TAG_DESCRIPTION } from '@modules/library/psc/duck/libraryPscConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibraryPscApiRoutes = {
  list: 'api/library/libraries/psc',
  item: (id: number) => `api/library/libraries/psc/${id}`,
  validate: '/api/library/libraries/psc/validate',
};

const LibraryPscInvalidations: {
  LIST: TagDescription<LIBRARY_PSC_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<LIBRARY_PSC_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_PSC_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (id: number) => ({ type: LIBRARY_PSC_TAG_DESCRIPTION.ID, id }),
};

export const LibraryPscApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryPscList: builder.query<ILibraryPscResponse, ILibraryPscListQueryParams | void>({
      providesTags: [LibraryPscInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibraryPscApiRoutes.list,
      }),
    }),
    libraryDeletePsc: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [LibraryPscInvalidations.LIST, LibraryPscInvalidations.ID(id)],
      query: (id) => ({
        method: 'DELETE',
        url: LibraryPscApiRoutes.item(id),
      }),
    }),
    libraryCopyPsc: builder.mutation<void, ILibraryPscQuery>({
      invalidatesTags: [LibraryPscInvalidations.LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: {
          data,
          cascade_update,
        },
        params: { overwrite },
        method: 'POST',
        url: LibraryPscApiRoutes.list,
      }),
    }),
    validatePsc: builder.mutation<void, ILibraryPscValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryPscApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibraryPscListQuery,
  useLazyLibraryPscListQuery,
  useLibraryDeletePscMutation,
  useValidatePscMutation,
  useLibraryCopyPscMutation,
} = LibraryPscApi;
