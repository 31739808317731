import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ILibraryImportPsc } from '@modules/library/psc/components/LibraryImportPsc';
import { tableListToOptions } from '@shared/utils/Table';
import { EPscType, EPscTypeNames } from '@modules/library/psc/PscTypes';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePscColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportPsc>();
  const { t } = useTranslation(['libraryPsc']);

  const typeListOption = tableListToOptions(Object.entries(EPscTypeNames).map(([k, v]) => ({ name: v, id: k })));

  const pscColumns: TableColumnsType<ILibraryImportPsc> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: t('rootTable.type'),
        dataIndex: 'type',
        key: 'type',
        filters: typeListOption,
        onFilter: (value, record) => value === record.type,
        render: (type: EPscType) => (type ? EPscTypeNames[type] : 'N/A'),
      },
      {
        title: t('rootTable.libraryVersion'),
        dataIndex: 'version',
        render: (_, record) => record.version || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { pscColumns, locale };
};
