import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const CaretUpDownIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={12} height={21} viewBox="0 0 12 21">
    <path
      fill="currentColor"
      d="M11.031 14.4695C11.1008 14.5391 11.1561 14.6218 11.1938 14.7129C11.2316 14.8039 11.251 14.9015 11.251 15.0001C11.251 15.0987 11.2316 15.1963 11.1938 15.2873C11.1561 15.3784 11.1008 15.4611 11.031 15.5307L6.53104 20.0307C6.46139 20.1005 6.37867 20.1558 6.28762 20.1935C6.19657 20.2313 6.09898 20.2507 6.00042 20.2507C5.90185 20.2507 5.80426 20.2313 5.71321 20.1935C5.62216 20.1558 5.53945 20.1005 5.46979 20.0307L0.969792 15.5307C0.829061 15.39 0.75 15.1991 0.75 15.0001C0.75 14.8011 0.829061 14.6102 0.969792 14.4695C1.11052 14.3287 1.30139 14.2497 1.50042 14.2497C1.69944 14.2497 1.89031 14.3287 2.03104 14.4695L6.00042 18.4398L9.96979 14.4695C10.0394 14.3997 10.1222 14.3444 10.2132 14.3067C10.3043 14.2689 10.4019 14.2495 10.5004 14.2495C10.599 14.2495 10.6966 14.2689 10.7876 14.3067C10.8787 14.3444 10.9614 14.3997 11.031 14.4695ZM2.03104 6.53073L6.00042 2.56041L9.96979 6.53073C10.1105 6.67146 10.3014 6.75052 10.5004 6.75052C10.6994 6.75052 10.8903 6.67146 11.031 6.53073C11.1718 6.39 11.2508 6.19912 11.2508 6.0001C11.2508 5.80108 11.1718 5.61021 11.031 5.46948L6.53104 0.969477C6.46139 0.899744 6.37867 0.844425 6.28762 0.806682C6.19657 0.768939 6.09898 0.749512 6.00042 0.749512C5.90185 0.749512 5.80426 0.768939 5.71321 0.806682C5.62216 0.844425 5.53945 0.899744 5.46979 0.969477L0.969792 5.46948C0.829061 5.61021 0.75 5.80108 0.75 6.0001C0.75 6.19912 0.829062 6.39 0.969792 6.53073C1.11052 6.67146 1.30139 6.75052 1.50042 6.75052C1.69944 6.75052 1.89031 6.67146 2.03104 6.53073Z"
    />
  </BaseIcon>
));
