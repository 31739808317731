import { CSSObject, useTheme } from '@emotion/react';
import {
  default as OriginReactDiffViewer,
  DiffMethod,
  ReactDiffViewerProps as OriginReactDiffViewerProps,
} from 'react-diff-viewer';

export const ReactDiffViewer = ({
  leftTitle,
  rightTitle,
  oldValue,
  newValue,
  styles,
  compareMethod,
  splitView = true,
}: ReactDiffViewerProps) => {
  const theme = useTheme();

  return (
    <div css={cssDiff}>
      <OriginReactDiffViewer
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        oldValue={oldValue}
        newValue={newValue}
        splitView={splitView}
        styles={
          styles ?? {
            line: { fontSize: '14px' },
            diffContainer: {
              pre: {
                fontSize: '14px',
                lineHeight: '16px',
              },
            },
            gutter: {
              textAlign: 'left',
              backgroundColor: theme['color-grey-200'],
            },
            lineNumber: {
              opacity: '1 !important',
              lineHeight: '16px !important',
            },
          }
        }
        compareMethod={compareMethod ?? DiffMethod.WORDS}
      />
    </div>
  );
};

const cssDiff = (): CSSObject => ({
  height: '50vh',
  minHeight: '300px',
  overflowY: 'auto',
  overflowX: 'hidden',
  overflowWrap: 'anywhere',
  marginBottom: 64,
});

interface ReactDiffViewerProps {
  leftTitle?: OriginReactDiffViewerProps['leftTitle'];
  rightTitle?: OriginReactDiffViewerProps['rightTitle'];
  oldValue: string;
  newValue: string;
  splitView?: boolean;
  styles?: CSSObject;
  compareMethod?: OriginReactDiffViewerProps['compareMethod'];
}
