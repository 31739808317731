import { UtilConfig } from '@modules/modelEditor/components/builder/UtilConfig';
import {
  DEFAULT_STAGE_HEIGHT,
  DEFAULT_STAGE_WIDTH,
  TABLE_LIST_SIDER_HEIGHT,
  TABLE_LIST_SIDER_WIDTH,
} from '@modules/modelEditor/components/builder/constants';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import {
  CustomSqlStage,
  GroupByStage,
  JoinStage,
  PivotStage,
  ResultStage,
  TransformStage,
  UnionStage,
  UnpivotStage,
} from '@components/icons';
import React from 'react';

export const StageConfig: StageConfigProps = {
  [ModelEditorNodeType.join]: {
    operation: ModelEditorNodeType.join,
    background: '#E4CCFF',
    icon: <JoinStage />,
    validation: {
      minIncomingConnections: 2,
      maxIncomingConnections: 2,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.unpivot],
    },
    show: UtilConfig.STAGES.JOIN,
  },
  [ModelEditorNodeType.transform]: {
    operation: ModelEditorNodeType.transform,
    background: '#a0e9e6',
    icon: <TransformStage />,
    validation: {
      minIncomingConnections: 1,
      maxIncomingConnections: 1,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
    },
    show: UtilConfig.STAGES.TRANSFORM,
    center: true,
    width: 50,
    height: 50,
    scaleX: 1,
    scaleY: 1,
  },
  [ModelEditorNodeType.union]: {
    operation: ModelEditorNodeType.union,
    background: '#E4CCFF',
    icon: <UnionStage />,
    validation: {
      minIncomingConnections: 2,
      maxIncomingConnections: 2,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.unpivot],
    },
    show: UtilConfig.STAGES.UNION,
  },
  [ModelEditorNodeType.pivot]: {
    operation: ModelEditorNodeType.pivot,
    background: '#AFF4C6',
    icon: <PivotStage />,
    validation: {
      minIncomingConnections: 1,
      maxIncomingConnections: 1,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.union, ModelEditorNodeType.pivot, ModelEditorNodeType.groupBy],
    },
    show: UtilConfig.STAGES.PIVOT,
  },
  [ModelEditorNodeType.unpivot]: {
    operation: ModelEditorNodeType.unpivot,
    background: '#AFF4C6',
    icon: <UnpivotStage />,
    validation: {
      minIncomingConnections: 1,
      maxIncomingConnections: 1,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.union, ModelEditorNodeType.unpivot, ModelEditorNodeType.groupBy],
    },
    show: UtilConfig.STAGES.UNPIVOT,
  },
  [ModelEditorNodeType.groupBy]: {
    operation: ModelEditorNodeType.groupBy,
    background: '#AFF4C6',
    icon: <GroupByStage />,
    validation: {
      minIncomingConnections: 1,
      maxIncomingConnections: 1,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.groupBy, ModelEditorNodeType.unpivot],
    },
    show: UtilConfig.STAGES.GROUP_BY,
  },
  [ModelEditorNodeType.sql]: {
    operation: ModelEditorNodeType.sql,
    background: '#E6E6E6',
    icon: <CustomSqlStage />,
    validation: {
      minIncomingConnections: 0,
      maxIncomingConnections: 0,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [ModelEditorNodeType.unpivot],
    },
    show: UtilConfig.STAGES.CUSTOM_SQL,
  },
  [ModelEditorNodeType.result]: {
    operation: ModelEditorNodeType.result,
    background: '#d7cdcd',
    icon: <ResultStage />,
    validation: {
      minIncomingConnections: 1,
      maxIncomingConnections: 1,
      minOutgoingConnections: 0,
      maxOutgoingConnections: 0,
    },
    show: UtilConfig.STAGES.RESULT,
  },
  [ModelEditorNodeType.domain]: {
    operation: ModelEditorNodeType.domain,
    background: '#E6E6E6',
    icon: null,
    validation: {
      minIncomingConnections: 0,
      maxIncomingConnections: 0,
      minOutgoingConnections: 1,
      maxOutgoingConnections: 1,
      notConnectTo: [],
    },
    show: UtilConfig.STAGES.DOMAIN,
    scaleX: DEFAULT_STAGE_WIDTH / TABLE_LIST_SIDER_WIDTH,
    scaleY: DEFAULT_STAGE_HEIGHT / TABLE_LIST_SIDER_HEIGHT,
  },
};

export interface ValidationConfigProps {
  minIncomingConnections: number;
  maxIncomingConnections: number;
  minOutgoingConnections: number;
  maxOutgoingConnections: number;
  notConnectTo?: string[];
}

export interface NodeConfigProps {
  operation: string;
  background: string;
  icon: React.ReactNode;
  validation: ValidationConfigProps;
  show: boolean;
  center?: boolean;
  width?: number;
  height?: number;
  scaleX?: number;
  scaleY?: number;
}

interface StageConfigProps {
  [key: string]: NodeConfigProps;
}
