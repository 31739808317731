import { IEnvironment } from '@app/AppTypes';
import { Space } from '@ui';
import { CSSObject, Theme } from '@emotion/react';

export const FooterMenu = ({ appEnv }: { appEnv: IEnvironment | null }) => (
  <Space css={cssVersion} full direction="vertical" justify="end">
    {appEnv?.label}
    {`v${process.env.VERSION}`}
  </Space>
);

const cssVersion = (theme: Theme): CSSObject => ({
  color: theme['color-white'],
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 1.5,
  fontFamily: 'Rubik',
  alignItems: 'center',
  gap: 4,
  padding: 16,
});
