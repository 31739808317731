import { Badge, Dropdown } from '@ui';
import { NotificationsPanel } from '@app/components/Notifications/components/NotificationsPanel';
import { Bell } from '@components/icons';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectNotifications } from './duck/notificationsSelector';

export const NotificationsButton = () => {
  const { archived, latest } = useSelector(selectNotifications);
  const unreadNotifications = useMemo(() => latest.filter((notification) => !notification.isRead), [latest]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: [{ key: 'panel', label: <NotificationsPanel notifications={latest} archived={archived} /> }],
      }}
    >
      <Badge count={unreadNotifications.length} css={cssBadge(!!unreadNotifications.length)}>
        <Bell css={cssNotificationsButton} />
      </Badge>
    </Dropdown>
  );
};

const cssBadge = (hasNotifications: boolean) => css`
  width: 42px;
  height: 42px;

  display: flex;
  align-items: ${!hasNotifications ? 'center' : 'end'};
  justify-content: ${!hasNotifications ? 'center' : 'left'};

  .ant-badge-count {
    height: 20px;
    transform: translate(40%, -75%);
    box-shadow: 0 0 0 1.5px #ffffff;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
    padding: 0 4px;

    inset-inline-end: auto;
    top: auto;
  }
`;

const cssNotificationsButton = css`
  font-size: 26px;
`;
