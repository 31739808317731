import { ICodeLab } from '@modules/codeLab/CodeLabTypes';
import { prepareLibraryObject } from '@modules/library/root/duck/libraryUtils';
import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { IPrepareCodeLabTableData, ICodeLabObject, ICodeLabObjectConfiguration } from '../CodeLabObjectTypes';

export function prepareCodeLabTableData(props: IPrepareCodeLabTableDataFromSource): IPrepareCodeLabTableData<ICodeLab>;
export function prepareCodeLabTableData(
  props: IPrepareCodeLabTableDataFromDnAObject,
): IPrepareCodeLabTableData<ICodeLabObject>;
export function prepareCodeLabTableData(
  props: IPrepareCodeLabTableDataFromDnAObject | IPrepareCodeLabTableDataFromSource,
) {
  if (!Array.isArray(props.data)) return [];

  if (props.sourceType === ELibrarySourceType.Library) {
    return props.data.map((item) => ({
      id: item.id,
      name: item.name,
      filePath: item.object_file_path || `${item.name}${item.configuration?.ext || ''}`,
      libraryVersion: Array.isArray(item.versions) ? item.version : undefined,
      rawData: item,
    })) as IPrepareCodeLabTableData<ICodeLabObject>;
  }

  return props.data.map((item) => ({
    id: item.id,
    name: item.name,
    filePath: item.file_path,
    rawData: item,
  })) as IPrepareCodeLabTableData<ICodeLab>;
}

export function prepareCodeLabObjectForImport(
  values: ICodeLabObject[] | ICodeLab[],
  sourceType: ELibrarySourceType,
  systemInfo: IBaseLibraryEntitySystemInfo,
) {
  return prepareLibraryObject<ICodeLabObjectConfiguration>(
    values.map((item) => {
      if (sourceType === ELibrarySourceType.Library) {
        const objectItem = item as ICodeLabObject;
        return {
          ...objectItem,
          configuration: objectItem.configuration!,
          source: objectItem.source || systemInfo.source,
          env: objectItem.env || systemInfo.env,
        };
      }

      const _codeLab = item as ICodeLab;
      const ext = /(?:\.([^.]+))?$/.exec(_codeLab.file_path) || [];
      return {
        name: _codeLab.name,
        configuration: {
          name: _codeLab.name,
          ext: ext[0],
          file: _codeLab.content,
          file_path: _codeLab.file_path,
          type: _codeLab.type,
        } as ICodeLabObjectConfiguration,
      };
    }),
    systemInfo,
  ).map((item) => ({ ...item, source_id: null, description: '' }));
}

interface IPrepareCodeLabTableDataFromDnAObject {
  data: ICodeLabObject[];
  sourceType: ELibrarySourceType.Library;
}

interface IPrepareCodeLabTableDataFromSource {
  data: ICodeLab[];
  sourceType: ELibrarySourceType.Study;
}
