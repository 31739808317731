import { default as AntRadio, RadioProps } from 'antd/es/radio';
import { ForwardedRef, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { CSSObject, Theme } from '@emotion/react';

interface RadioComponent extends ForwardRefExoticComponent<RadioProps & RefAttributes<any>> {
  Group: typeof AntRadio.Group;
  Button: typeof AntRadio.Button;
}

const Radio = forwardRef(({ children, ...props }: RadioProps, ref: ForwardedRef<any>) => {
  return <AntRadio css={cssRadio} {...props} ref={ref} children={children} />;
}) as RadioComponent;

Radio.Group = AntRadio.Group;
Radio.Button = AntRadio.Button;

const cssRadio = (theme: Theme): CSSObject => ({
  '& .ant-radio-inner': {
    borderColor: theme['color-grey-500'],
    borderWidth: 1.5,
  },
  '& .ant-radio-checked .ant-radio-inner': {
    backgroundColor: theme['color-white'],
  },
  '& .ant-radio-checked .ant-radio-inner::after': {
    backgroundColor: theme['color-grey-900'],
  },
});

export { Radio };
