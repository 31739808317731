import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const RccLogo: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={42} height={42} fill="none" viewBox="0 0 42 42">
    <path
      d="M4.458 0H37.544C38.726 0.000530278 39.8594 0.470445 40.695 1.30642C41.5306 2.1424 42 3.27601 42 4.458V37.542C42 38.724 41.5306 39.8576 40.695 40.6936C39.8594 41.5296 38.726 41.9995 37.544 42H4.458C3.27567 42 2.14176 41.5303 1.30572 40.6943C0.469681 39.8582 0 38.7243 0 37.542L0 4.458C0 3.27567 0.469681 2.14176 1.30572 1.30572C2.14176 0.469681 3.27567 0 4.458 0Z"
      fill="#405866"
    />
    <path
      d="M3.77148 5.25684H29.3015C31.2287 5.26181 33.0788 6.01475 34.4618 7.35699C35.8448 8.69922 36.6527 10.5259 36.7154 12.4522C36.778 14.3784 36.0904 16.2537 34.7975 17.683C33.5046 19.1122 31.7073 19.9837 29.7845 20.1138L34.7265 25.4078V28.6368C31.2345 24.8618 28.3145 21.7018 24.8435 17.9368H29.3015C30.692 17.9368 32.0256 17.3845 33.0088 16.4012C33.9921 15.4179 34.5445 14.0844 34.5445 12.6938C34.5445 11.3033 33.9921 9.96973 33.0088 8.98647C32.0256 8.00322 30.692 7.45084 29.3015 7.45084H5.76548L3.77148 5.25884V5.25684Z"
      fill="#BDD4DF"
    />
    <path d="M10.897 10.4307L34.814 35.3207V38.5607L7.95703 10.4307H10.897Z" fill="#BDD4DF" />
  </BaseIcon>
));
