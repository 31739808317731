import { Button, Empty, Input, Space, TableExtraConfig, TableFiltersConfig, TableSorterConfig } from '@ui';
import { prepareFilters, prepareSorter } from '@shared/utils/common';
import { SearchSvg } from '@components/icons';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { get } from 'lodash';

export const getTablePagination = (
  pagination: TableInitialPaginationProps,
  totalItems?: number,
): TablePaginationConfig => ({
  ...pagination,
  total: totalItems ?? pagination?.total,
  hideOnSinglePage: true,
  showSizeChanger: !!pagination.pageSizeOptions.length,
});

// export const useTablePaginationState = <T>(initPagination: TableInitialPaginationProps)=> {
export function useTablePaginationState<T>(initPagination: TableInitialPaginationProps) {
  const [paginationState, setPaginationState] = useState(initPagination);
  const [filters, setFilters] = useState<TableFiltersConfig>({});
  const [sorter, setSorter] = useState<TableSorterConfig<T>>({});

  const setPagination = (page: number, pageSize?: number, totalItems?: number) => {
    setPaginationState((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize || prev.pageSize,
      total: totalItems,
    }));
  };

  const getPagination = (totalItems?: number) => getTablePagination(paginationState, totalItems);

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<T>,
    extra: TableExtraConfig<T>,
  ) => {
    switch (extra.action) {
      case 'paginate':
        setPagination(tablePagination.current!);
        break;
      case 'sort':
        setSorter({ ...sorter });
        break;
      case 'filter':
        setPagination(1);
        setFilters({ ...filters });
        break;
    }
  };

  const preparedFilters = prepareFilters(filters);
  const preparedSorter = prepareSorter(sorter);

  return {
    paginationState,
    setPagination,
    getPagination,
    onTableChange,
    preparedFilters,
    preparedSorter,
  };
}

export const useUpdatePageListener = (
  { paginationState, setPagination }: Omit<ReturnType<typeof useTablePaginationState>, 'getPagination'>,
  dataSource?: any[],
) => {
  const requireToUpdatePage = !(dataSource || []).length && paginationState.current !== 1;

  useEffect(() => {
    if (requireToUpdatePage) {
      setPagination(paginationState.current - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requireToUpdatePage]);

  return {
    requireToUpdatePage,
  };
};

const getFilterIconId = (filterIconId: string) => `antd_filter_icon-${filterIconId}`;

export function useTableLocale(emptyText?: string) {
  const { t } = useTranslation(['shared']);

  return {
    triggerAsc: t('table.triggerAsc'),
    cancelSort: t('table.cancelSort'),
    filterReset: t('table.filterReset'),
    filterConfirm: t('table.filterConfirm'),
    emptyText: emptyText ? (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
    ) : (
      t('table.emptyText')
    ),
  };
}

function TableSearchPopoverForm<T>(
  props: FilterDropdownProps & {
    searchInput: React.RefObject<InputRef>;
    dataIndex: keyof T | string;
    filterIconId: string;
    getContentScrollElement?: () => Element | null;
  },
) {
  const { t } = useTranslation(['shared']);
  const prevVisible = useRef(props.visible);

  useEffect(
    () => {
      const contentScrollElement = props.getContentScrollElement ? props.getContentScrollElement() : document.body;
      let dropdownParent: HTMLElement | null | undefined = null;

      if (props.searchInput.current?.input) {
        dropdownParent = props.searchInput.current?.input?.parentElement?.parentElement?.parentElement;
      }

      const onScroll = () => {
        if (!dropdownParent) {
          dropdownParent = props.searchInput.current?.input?.parentElement?.parentElement?.parentElement;
        }

        if (dropdownParent) {
          const clientRect = document.getElementById(props.filterIconId)?.parentElement?.getBoundingClientRect();
          dropdownParent.style.top = `${(clientRect?.top || 0) + (clientRect?.height || 0) + 5}px`;
          dropdownParent.style.left = `${
            (clientRect?.left || 0) + (clientRect?.width || 0) - dropdownParent.clientWidth
          }px`;
        }
      };

      contentScrollElement?.addEventListener('scroll', onScroll);

      if (props.visible !== prevVisible.current && !props.visible) {
        props.confirm();
      }
      prevVisible.current = props.visible;

      return () => {
        contentScrollElement?.removeEventListener('scroll', onScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.visible],
  );

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={props.searchInput}
        placeholder={t('table.searchBy', {
          name: props.dataIndex as string,
        }).toString()}
        value={props.selectedKeys[0]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => props.confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space full justify="space-between">
        <Button
          onClick={() => {
            props.clearFilters && props.clearFilters();
            props.confirm({ closeDropdown: true });
          }}
          size="small"
          type="link"
          disabled={!props.selectedKeys.length}
          style={{ width: 90 }}
          children={t('table.filterReset')}
        />
        <Button
          type="primary"
          onClick={() => props.confirm({ closeDropdown: false })}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          children={t('table.searchText')}
        />
      </Space>
    </div>
  );
}

export function useColumnSearch<T>(emptyText?: string) {
  const locale = useTableLocale(emptyText);
  const searchInput = React.useRef<InputRef>(null);

  const getColumnSearchProps = React.useCallback((dataIndexes: keyof T | [keyof T, ...string[]]): ColumnType<T> => {
    const dataIndex = Array.isArray(dataIndexes) ? dataIndexes.at(-1) : dataIndexes;

    const filterIconId = getFilterIconId(dataIndex as string);

    const getNestedValue = <T,>(record: T) => get(record, dataIndexes);

    return {
      filterDropdown: (filterProps) => (
        <TableSearchPopoverForm
          {...filterProps}
          dataIndex={dataIndex!}
          searchInput={searchInput}
          filterIconId={filterIconId}
        />
      ),
      filterIcon: (filtered: boolean) => <SearchSvg id={filterIconId} color={filtered ? 'primary' : 'darkGrey'} />,
      onFilter: (value, record) => {
        const nestedValue = getNestedValue<T>(record);
        return (nestedValue ?? '')
          .toString()
          .toLowerCase()
          .includes((value as string).trim().toLowerCase());
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (v, record) => {
        const nestedValue = getNestedValue<T>(record);
        return nestedValue ?? v;
      },
    };
  }, []);

  return {
    getColumnSearchProps,
    locale,
  };
}

export interface TableInitialPaginationProps {
  pageSize: number;
  current: number;
  pageSizeOptions: number[];
  total?: number;
}
