import { IAnalysisObjectPscModalsCopyPscProps } from '@modules/library/analysisObjects/psc/modals/components/copy/AnalysisObjectPscModalsCopyPsc';
import { EAnalysisObjectPscModalsType } from '@modules/library/analysisObjects/psc/modals/AnalysisObjectPscModalsConstants';
import { selectAnalysisObjectPscModals } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscSelectors';
import { analysisObjectPscActions } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AnalysisObjectPscModalsCopyPsc } from './components';

export const AnalysisObjectPscModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectPscModals);

  const closeModal = () => {
    dispatch(analysisObjectPscActions.dropModal());
  };

  return (
    <AnalysisObjectPscModalsCopyPsc
      open={selectedModal === EAnalysisObjectPscModalsType.copyPsc}
      data={data as IAnalysisObjectPscModalsCopyPscProps['data']}
      onClose={closeModal}
    />
  );
};
