import { notification, Space } from '@ui';
import { ButtonWithConfirmation, ButtonWithConfirmationProps } from '@components';
import { Archive, CheckCircle, CloudUpload } from '@components/icons';
import { useSaveLibraryMutation } from '@modules/library/root/duck/libraryApi';
import { Library } from '@modules/library/root/LibraryTypes';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { TFunction } from 'i18next';

export const LibraryActionButtons = ({ record, t }: ILibraryActionMenuProps) => {
  const [saveLibrary, { isError, error, isLoading }] = useSaveLibraryMutation();
  const { id, name, status } = record;

  const handleActionClick = async (id: number, status: LibraryStatus) => {
    try {
      await saveLibrary({ id, status });
      notification.success({
        message: t('libraryRoot.root.statusChangedSuccess'),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('libraryRoot.root.statusChangedError'),
      });
    }
  };

  const RenderButton = ({
    confirmTitle,
    confirmContent,
    icon,
    status,
  }: Omit<ButtonWithConfirmationProps, 'submitFunc'> & { status: LibraryStatus }) => (
    <ButtonWithConfirmation
      confirmTitle={confirmTitle}
      tooltipTitle={confirmTitle}
      confirmContent={confirmContent}
      icon={icon}
      loading={isLoading}
      submitFunc={() => handleActionClick(id, status)}
      confirmOkText={t('yes')}
      confirmCancelText={t('no')}
      okButtonProps={{ danger: false }}
    />
  );

  const moveToDevButton = () => (
    <RenderButton
      confirmTitle={t('root.table.actionMenu.moveToDev')}
      confirmContent={t('root.table.actionMenu.confirmation.moveToDevDescription', { name })}
      icon={<CloudUpload color="darkGrey" />}
      status={LibraryStatus.Development}
    />
  );

  const moveToArchiveButton = () => (
    <RenderButton
      confirmTitle={t('root.table.actionMenu.moveToArchive')}
      confirmContent={t('root.table.actionMenu.confirmation.moveToArchiveDescription', { name })}
      icon={<Archive />}
      status={LibraryStatus.Retired}
    />
  );

  const approveButton = () => (
    <RenderButton
      confirmTitle={t('root.table.actionMenu.approve')}
      confirmContent={t('root.table.actionMenu.confirmation.approveDescription', { name })}
      icon={<CheckCircle />}
      status={LibraryStatus.Active}
    />
  );

  const getButtons = (status: LibraryStatus) => {
    switch (status) {
      case LibraryStatus.Active:
        return (
          <>
            {moveToDevButton()}
            {moveToArchiveButton()}
          </>
        );
      case LibraryStatus.Development:
        return (
          <>
            {approveButton()}
            {moveToArchiveButton()}
          </>
        );
      case LibraryStatus.Retired:
        return <>{moveToDevButton()}</>;
      default:
        return null;
    }
  };

  return <Space>{getButtons(status)}</Space>;
};

interface ILibraryActionMenuProps {
  record: Library;
  t: TFunction;
}
