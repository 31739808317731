import { IBaseLibraryEntitySystemInfo, IBaseLibraryEntityUpdate } from '@modules/library/root/LibraryTypes';
import { clearUndefinedFields } from '@shared/utils/common';

export function prepareLibraryObject<TConfiguration extends Record<string, any>>(
  data: Array<{
    name: string;
    id?: number;
    source?: string;
    env?: string;
    tenant_info?: string;
    version_id?: number;
    configuration: TConfiguration;
  }>,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): IBaseLibraryEntityUpdate<TConfiguration>[] {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    return clearUndefinedFields({
      id: item.id,
      name: item.name,
      configuration: item.configuration,
      version_id: item.version_id,
      source: item?.source || systemInfo?.source!,
      env: item?.env || systemInfo?.env!,
      tenant_info: item?.tenant_info || systemInfo?.tenant_info!,
    });
  });
}
