import { CSSObject, Theme } from '@emotion/react';
import { ReactNode } from 'react';

export const PageLayout = ({ children, navigation, width }: PageLayoutProps) => {
  return (
    <div css={cssContainer}>
      <div css={cssBody({ width })}>
        {navigation}
        <div css={cssContent}>{children}</div>
      </div>
    </div>
  );
};

const cssContainer = (theme: Theme): CSSObject => ({
  padding: `${theme.padding}px`,
  width: '100%',
  flex: 1,
  backgroundColor: theme['color-grey-50'],
});

const cssBody = ({ width }: Pick<PageLayoutProps, 'width'>): CSSObject => ({
  maxWidth: width ? `${width}px` : '100%',
  width: '100%',
  margin: 'auto',
  height: '100%',
});

const cssContent = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column' as const,
  padding: '16px',
  borderRadius: '4px',
  height: '100%',
  backgroundColor: theme['color-grey-50'],
});

interface PageLayoutProps {
  children: ReactNode;
  width?: number;
  navigation?: ReactNode;
}
