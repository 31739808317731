import { Button } from '@ui';
import { ArrowIcon } from '@components/icons';
import { css, CSSObject, Theme } from '@emotion/react';
import { createRef, MouseEventHandler, useEffect, useState } from 'react';

const DIVIDER_HEIGHT = 5;

export const VerticalSplit = ({ top, bottom, collapsed = false, onCollapse }: VerticalSplitProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const bottomPaneRef = createRef<HTMLDivElement>();
  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => setIsCollapsed(collapsed), [collapsed]);

  useEffect(() => {
    if (isCollapsed && onCollapse) {
      onCollapse();
    }
  }, [onCollapse, isCollapsed]);

  useEffect(() => {
    if (bottomPaneRef.current && containerRef.current) {
      if (!isCollapsed) {
        bottomPaneRef.current.style.height = `${containerRef.current.offsetHeight * 0.4}px`;
      } else {
        bottomPaneRef.current.style.height = '0px';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  const onMouseUp = () => {
    setIsResizing(false);
    setIsCollapsed(bottomPaneRef.current!.clientHeight <= 0);
  };

  const onMouseMove: EventListener = (event: MouseEventInit) => {
    if (isResizing && containerRef.current && bottomPaneRef.current && event.clientY) {
      const newHeight = Math.min(
        containerRef.current.offsetHeight -
          (event.clientY - containerRef.current.getBoundingClientRect().top) -
          DIVIDER_HEIGHT,
        containerRef.current.offsetHeight,
      );

      bottomPaneRef.current.style.height = `${newHeight}px`;
    }
  };

  const onMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsResizing(true);
  };

  const onCollapseClicked = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div css={cssContainer} ref={containerRef}>
      <div css={cssTop}>{top}</div>
      <div css={cssDividerWrapper}>
        <div css={cssDivider} onMouseDown={onMouseDown}></div>
        <Button css={cssCollapseButton(isCollapsed)} onClick={onCollapseClicked}>
          <ArrowIcon color="white" />
        </Button>
      </div>
      <div css={cssBottom} ref={bottomPaneRef}>
        {bottom}
      </div>
    </div>
  );
};

interface VerticalSplitProps {
  top: JSX.Element;
  bottom: JSX.Element;
  collapsed?: boolean;
  onCollapse?: () => void;
}

const cssContainer = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  position: 'relative',
  minWidth: 0,
});

const cssTop = (): CSSObject => ({
  width: '100%',
  height: 'calc(100% - 5px)',
  overflow: 'auto',
  display: 'flex',
});

const cssBottom = (): CSSObject => ({
  width: '100%',
  height: '0px',
  flexGrow: 0,
  flexShrink: 0,
});

const cssDivider = (theme: Theme): CSSObject => ({
  backgroundColor: theme['natural-2'],
  width: '100%',
  height: `${DIVIDER_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'row-resize',
  flexGrow: 0,
  flexShrink: 0,
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.colorPrimary,
  },
});

const cssDividerWrapper = css({
  position: 'relative',
});

const cssCollapseButton =
  (isCollapsed?: boolean) =>
  (theme: Theme): CSSObject => ({
    transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0)',
    transition: 'transform 300ms',
    background: theme['natural-6'],
    position: 'absolute',
    left: '50%',
    top: '-12px',
    marginLeft: '-12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    '&&': {
      padding: 0,
    },
    '&&&:hover': {
      borderColor: theme.colorBorder,
      background: theme['color-grey-700'],
    },
    width: '24px',
    height: '24px',
  });

// const cssCollapseButton = (theme: Theme): CSSObject => ({
//   position: 'absolute',
//   left: '50%',
//   marginLeft: '-12px',
//   top: '-11px',
//   color: 'white',
//   cursor: 'pointer',
//   height: '16px',
//   width: '24px',
//   zIndex: 1,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   overflow: 'hidden',
//   '& > svg': {
//     fontSize: '16px',
//     marginTop: '11px',
//   },
// });
