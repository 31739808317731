import { DirectoryTree } from '@components/ui';
import { selectMainMenuState } from '@app/duck/appSelectors';
import { MenuType } from '@routes/appMenu';
import { appSlice } from '@app/duck/appSlice';
import { CustomRouteMatch } from '@routes/RoutesTypes';
import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatches, useNavigate } from 'react-router-dom';
import { TreeProps } from 'antd';
import { ResizablePanel } from './components/ResizablePanel';
import { getMenuTreeData, getParentKeysByChildKey, MenuTreeNode } from './duck/utils';

export const MainMenu = ({ children, items, isLoading = false }: MainMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMatches();
  const match = matches.at(-1) as CustomRouteMatch;

  const menuState = useSelector(selectMainMenuState);

  const menuTreeData = useMemo(() => getMenuTreeData(items), [items]);

  // Expand parents that contain selected nodes
  useEffect(() => {
    if (!isLoading) {
      const parentKeysOfSelectedNode = getParentKeysByChildKey(menuTreeData, match.id);

      const { expandedKeys = [] } = menuState;
      const newExpandedKeys = parentKeysOfSelectedNode.filter((key) => !expandedKeys.includes(key));

      if (newExpandedKeys.length) {
        dispatch(appSlice.actions.setMainMenuState({ expandedKeys: expandedKeys.concat(newExpandedKeys) }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSelectItem: TreeProps<MenuTreeNode>['onSelect'] = (_, { node }) => {
    if (node.path) {
      navigate(node.path);
    }
  };

  const handleExpandGroupItem: TreeProps<MenuTreeNode>['onExpand'] = (expandedKeys) => {
    dispatch(appSlice.actions.setMainMenuState({ expandedKeys }));
  };

  const handlePanelWidthChange = (width: number) => {
    dispatch(appSlice.actions.setMainMenuState({ width }));
  };

  return (
    <ResizablePanel onWidthChange={handlePanelWidthChange} width={menuState.width}>
      {children}
      <DirectoryTree
        treeData={menuTreeData}
        isLoading={isLoading}
        showLine={{ showLeafIcon: false }}
        expandedKeys={menuState.expandedKeys}
        selectedKeys={[match.id]}
        onExpand={handleExpandGroupItem}
        onSelect={handleSelectItem}
      />
    </ResizablePanel>
  );
};

interface MainMenuProps {
  children?: ReactNode;
  items: MenuType;
  isLoading?: boolean;
}
