import { MenuType } from '@routes/appMenu';
import { TAuthAppPermissions } from '@modules/auth/AuthTypes';
import {
  AuditLogIcon,
  CdrReportIcon,
  CodeLabIcon,
  DiagramIcon,
  GlobeAlt,
  PscFiltersIcon,
  SqlLabIcon,
  SqlQueriesIcon,
} from '@components/icons';
import libraryRoutes from './RoutesLibrary';

export const libraryMenu = (libraryId: number, appPermissions: Partial<TAuthAppPermissions>): MenuType => {
  const items = [
    appPermissions.canGlDmoRead && {
      path: libraryRoutes.models.root.resolver({ libraryId }),
      key: libraryRoutes.models.root.id,
      title: 'Data Models',
      icon: <DiagramIcon />,
    },
    appPermissions.canGlCdroRead && {
      path: libraryRoutes.cdr.root.resolver({ libraryId }),
      key: libraryRoutes.cdr.root.id,
      title: 'CDR Reports',
      icon: <CdrReportIcon />,
    },
    appPermissions.canGlSloRead && {
      path: libraryRoutes.sqlLab.root.resolver({ libraryId }),
      key: libraryRoutes.sqlLab.root.id,
      title: 'SQL Lab',
      icon: <SqlLabIcon />,
    },
    appPermissions.canGlSqoRead && {
      path: libraryRoutes.sqlQuery.root.resolver({ libraryId }),
      key: libraryRoutes.sqlQuery.root.id,
      title: 'SQL Queries',
      icon: <SqlQueriesIcon />,
    },
    appPermissions.canGlCloRead && {
      path: libraryRoutes.notebook.root.resolver({ libraryId }),
      key: libraryRoutes.notebook.root.id,
      title: 'Code Lab',
      icon: <CodeLabIcon />,
    },
    appPermissions.canGlPscRead && {
      path: libraryRoutes.psc.root.resolver({ libraryId }),
      key: libraryRoutes.psc.root.id,
      title: 'PSC/DPSC Filters',
      icon: <PscFiltersIcon />,
    },
    appPermissions.canGlAuditLogsRead && {
      path: libraryRoutes.audit.root.resolver({ libraryId }),
      key: libraryRoutes.audit.root.id,
      title: 'Audit Log',
      icon: <AuditLogIcon />,
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const appStudyLibraryMenu = () => [
  {
    path: libraryRoutes.root.path,
    key: libraryRoutes.root.id,
    title: 'Global Library',
    icon: <GlobeAlt color="grey" />,
  },
];
