import { Button, Checkbox, Input, Select, Space, Table, Tooltip } from '@ui';
import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';
import { ModelEditorSchemaEditorItem } from '@modules/modelEditor/ModelEditorTypes';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorModalsType } from '@modules/modelEditor/modals';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import type { DragEndEvent } from '@dnd-kit/core';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { FunctionOutlined } from '@ant-design/icons';
import { ModelEditorSchemaEditorRow } from './ModelEditorSchemaEditorRow';
import { ModelEditorSchemaEditorDataTypesOptions } from './ModelEditorSchemaEditorConstants';
import { useModelEditorSchemaEditor } from './ModelEditorSchemaEditorHooks';

export const ModelEditorSchemaEditor = ({ initData, tableInfo, isLoading, readOnly }: ModelEditorSchemaEditorProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { schemaEditorData = [], actions, deletedAllValue, moveRow } = useModelEditorSchemaEditor(tableInfo, initData);
  const getVariables = schemaEditorData.map((el) => el.name);

  const columns: ColumnsType<ModelEditorSchemaEditorItem> = useMemo(
    () => [
      !readOnly
        ? {
            width: 70,
            key: 'sort',
            align: 'center',
          }
        : { width: 0 },
      {
        width: 60,
        key: 'index',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, record) => (
          <Input
            name={`${record.index}:name`}
            onBlur={actions.onChangeItemWithInputText}
            size="middle"
            variant="borderless"
            defaultValue={name}
            style={{ color: theme['color-grey-900'] }}
            disabled={!record.custom}
          />
        ),
      },
      {
        title: 'New Name',
        render: (_, record) => (
          <Input
            name={`${record.index}:newName`}
            size="middle"
            variant="borderless"
            defaultValue={record.newName}
            onBlur={actions.onChangeItemWithInputText}
          />
        ),
      },
      {
        width: 200,
        title: 'Data Type',
        dataIndex: 'type',
        render: (type, record) => (
          <Select
            css={cssSelect}
            id={`${record.index}:type`}
            size="middle"
            variant="borderless"
            value={type || ModelEditorSchemaEditorDataTypesOptions[0].value}
            style={{ width: '100%', color: theme['color-grey-900'] }}
            disabled={!record.custom}
            onChange={(value) => actions.onChangeItemWithSelect(`${record.index}:type`, value)}
            options={ModelEditorSchemaEditorDataTypesOptions}
          />
        ),
      },
      {
        width: 100,
        title: 'Nullable',
        render: (_, record) => (
          <Checkbox
            disabled
            name={`${record.index}:nullable`}
            onChange={actions.onChangeItemWithCheckbox}
            checked={record?.nullable}
          />
        ),
      },
      {
        width: 100,
        title: () => (
          <Space size="middle">
            <FunctionOutlined style={{ opacity: 0 }} />
            <Tooltip title="Delete Column">
              <Checkbox
                css={cssCheckbox}
                onChange={actions.onChangeDeletedAllCheckbox}
                indeterminate={deletedAllValue === null}
                checked={deletedAllValue !== null ? deletedAllValue : undefined}
              />
            </Tooltip>
          </Space>
        ),
        render: (_, record) => (
          <Space size="middle" style={{ marginLeft: '8px', gap: '4px' }}>
            <Button
              type="text"
              css={cssTransformation(!!record.expression && record.expression !== record.name)}
              style={{ padding: 4, fontSize: 16, width: 24, height: 24 }}
              onClick={() =>
                dispatch(
                  modelEditorActions.pushModal({
                    type: ModelEditorModalsType.transformSettings,
                    callback: (result) => actions.onChangeItemWithExpression(`${record.index}:expression`, result),
                    data: { variables: getVariables, value: record.expression || record.name },
                  }),
                )
              }
            >
              fx
            </Button>
            <Checkbox
              name={`${record.index}:deleted`}
              css={cssCheckbox}
              onChange={actions.onChangeItemWithCheckbox}
              checked={record.deleted}
            />
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, deletedAllValue, dispatch, getVariables],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = schemaEditorData.findIndex((i) => i.key === active.id);
      const overIndex = schemaEditorData.findIndex((i) => i.key === over?.id);
      moveRow(activeIndex, overIndex);
    }
  };

  const disableAddButton = schemaEditorData.some((item) => item.name === '');

  return (
    <>
      {!readOnly && (
        <Space full justify="end">
          <Button
            type="primary"
            style={{ marginBottom: 16, marginLeft: 'auto' }}
            onClick={actions.onAddNewItem}
            disabled={disableAddButton}
          >
            Add Derived Variable
          </Button>
        </Space>
      )}
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={schemaEditorData.map((i) => i.key ?? 'empty')} strategy={verticalListSortingStrategy}>
          <Table
            css={cssTable}
            components={{
              body: {
                row: ModelEditorSchemaEditorRow,
              },
            }}
            rowClassName={(item) => (item.deleted ? 'deleted-row' : '')}
            rowKey="key"
            columns={columns}
            dataSource={schemaEditorData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 400px)' }}
            sticky
            loading={isLoading}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

const cssTransformation =
  (isActive: boolean) =>
  (theme: Theme): CSSObject =>
    isActive
      ? {
          color: theme['color-brand-blue-500'],
        }
      : { color: theme['color-grey-900'] };

const cssTable = (theme: Theme): CSSObject => ({
  '& .ant-input': {
    padding: 0,
  },
  '& .deleted-row': {
    backgroundColor: theme['color-error-red-light-background'],
  },

  '&& .anticon': {
    color: theme['color-grey-500'],
    fontSize: 20,
  },
});

const cssCheckbox = (theme: Theme): CSSObject => ({
  // TODO: add style for border when disable
  '&&:hover .ant-checkbox-inner': {
    borderColor: theme['color-error-red-primary'],
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: theme['color-error-red-primary'],
    borderColor: theme['color-error-red-primary'],
  },
  '&&&:hover .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: theme['color-error-red-primary'],
    borderColor: theme['color-error-red-primary'],
  },
  '&&& .ant-checkbox-checked:after': {
    borderColor: theme['color-error-red-primary'],
  },
  '&&&:hover .ant-checkbox-checked:after': {
    borderColor: theme['color-error-red-primary'],
  },
  '& .ant-checkbox-indeterminate .ant-checkbox-inner:after': {
    backgroundColor: theme['color-error-red-primary'],
  },
});

const cssSelect = (): CSSObject => ({
  '&&& .ant-select-selector': {
    color: 'inherit',
  },
});

interface ModelEditorSchemaEditorProps {
  readOnly?: boolean;
  tableInfo?: ViewerCHTableInfoData[];
  initData: ModelEditorSchemaEditorItem[];
  isLoading?: boolean;
}
