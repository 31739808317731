import {
  Checkbox,
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  notification,
  Select,
  Tabs,
  Typography,
} from '@ui';
import { ButtonWithConfirmation } from '@components';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { useConnectionListQuery, useDeleteSourceMutation, useSaveSourceMutation } from '@modules/source/duck/sourceApi';
import { Source } from '@modules/source/SourceTypes';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { IFormValue } from 'react-auto-form';
import { SourceModalsSaveConnection } from './SourceModalsSaveConnection';

const SourceModalsSaveSourceContent = ({ data, onClose, isEdit, t }: SourceModalsSaveSourceContentProps) => {
  const [form] = Form.useForm();
  const connectionListQuery = useConnectionListQuery();
  const [deleteSource, deleteSourceQuery] = useDeleteSourceMutation();
  const [saveSource, saveSourceQuery] = useSaveSourceMutation();

  const {
    userPermissions: { canDataSourceUpdate, canDataSourceDelete },
  } = useStudyPermissions();

  const innerRef = useRef<any>(null);
  const typeOptions = connectionListQuery?.data?.map((item) => ({ label: item.name, value: item.id }));

  const deleteLoading = deleteSourceQuery.isLoading;

  const errorMessage = [deleteSourceQuery, saveSourceQuery]
    .map(
      (result) =>
        result.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  const onSubmit = async (values: SourceSaveFieldFormValues) => {
    try {
      if (getConnectionConfig(values['connection_id'])) {
        await innerRef.current.submitForm();
        if (innerRef.current.isValid) {
          const connection_properties = innerRef.current.values;
          await saveSource({
            ...values,
            connection_properties,
            id: data?.id,
          }).unwrap();
          onClose();
        }
      } else {
        await saveSource({
          ...values,
          connection_properties: {},
          id: data?.id,
        }).unwrap();
        onClose();
      }
      notification.success({
        message: t(isEdit ? 'notification.form.successMessageEdit' : 'notification.form.successMessageCreate', {
          name: values.name,
        }),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t(isEdit ? 'notification.form.errorMessageEdit' : 'notification.form.errorMessageCreate', {
          name: values.name,
        }),
      });
    }
  };

  const onDeleteSource = async () => {
    try {
      await deleteSource(data.id!).unwrap();
      onClose();
      notification.success({ message: t('notification.form.successMessageDelete', { name: data.name }) });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('notification.form.errorMessageDelete', { name: data.name }),
      });
    }
  };

  const getConnectionConfig = (connectionId: number) => {
    const selectedConnection = connectionListQuery?.data?.find(
      // eslint-disable-next-line eqeqeq
      (item) => item.id == connectionId,
    );

    if (!Object.keys(selectedConnection?.configuration || {}).length) {
      return null;
    }

    return selectedConnection?.configuration;
  };

  const initValues = (isEdit &&
    data && {
      name: data.name,
      connection_name: data.connection_name,
      connection_id: data.connection_id,
      connection_properties: data.connection_properties,
      active: data.active,
    }) || {
    connection_id: (connectionListQuery?.data || [])[0]?.id,
    active: true,
  };

  const isDisabledForm = isEdit && !canDataSourceUpdate;

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      initialValues={initValues}
      disabled={isDisabledForm}
      hideOkBtn={isDisabledForm}
      submitIsDisabled={deleteLoading}
      cancelIsDisabled={deleteLoading}
      extraActions={
        canDataSourceDelete &&
        isEdit && (
          <ButtonWithConfirmation
            submitFunc={onDeleteSource}
            confirmContent={t('confirmation.description')}
            confirmTitle={t('confirmation.title')}
            loading={deleteLoading}
            disabled={saveSourceQuery.isLoading}
          />
        )
      }
      isEdit={isEdit}
    >
      <FormItem name="name" label={t('saveForm.name')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem name="connection_id" label={t('saveForm.connectionType')} rules={[{ required: true }]}>
        <Select options={typeOptions} placeholder={t('select')} loading={connectionListQuery.isLoading} />
      </FormItem>
      <FormItem dependencies={['connection_id']} wrapperCol={{ span: 24 }} noStyle>
        {() => {
          const selectedConnectionConfig = getConnectionConfig(form.getFieldValue('connection_id'));
          return (
            selectedConnectionConfig && (
              <FormItem name="connection_name" label={t('saveForm.connectionName')} rules={[{ required: true }]}>
                <Input />
              </FormItem>
            )
          );
        }}
      </FormItem>
      <FormItem name="active" label={t('saveForm.active')} valuePropName="checked">
        <Checkbox />
      </FormItem>
      <FormItem dependencies={['connection_id']} wrapperCol={{ span: 24 }} noStyle>
        {() => {
          const selectedConnectionConfig = getConnectionConfig(form.getFieldValue('connection_id'));

          return (
            selectedConnectionConfig && (
              <FormItem wrapperCol={{ span: 24 }}>
                <Tabs
                  defaultActiveKey="connection"
                  size="small"
                  items={[
                    {
                      key: 'connection',
                      label: t('saveForm.connection'),
                      children: (
                        <SourceModalsSaveConnection
                          source_id={data?.id}
                          definition={selectedConnectionConfig}
                          initValues={form.getFieldValue('connection_properties')}
                          onSave={(values) => form.setFieldValue('connection_properties', values)}
                          isEdit={isEdit}
                          innerRef={innerRef}
                        />
                      ),
                    },
                  ]}
                />
              </FormItem>
            )
          );
        }}
      </FormItem>
      {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </FormLayout>
  );
};

export const SourceModalsSaveSource = ({ open, data, onClose }: SourceModalsSaveSourceProps) => {
  const { t } = useTranslation(['datasource']);
  const isEdit = !!(data as Source)?.id;

  return (
    <DraggableModal
      width={535}
      open={open}
      onCancel={onClose}
      title={isEdit ? t('saveForm.titleEdit') : t('saveForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <SourceModalsSaveSourceContent data={data} onClose={onClose} isEdit={isEdit} t={t} />}
    </DraggableModal>
  );
};

export interface SourceModalsSaveSourceProps {
  open: boolean;
  data: Partial<Source>;
  onClose: () => void;
}

interface SourceModalsSaveSourceContentProps extends Pick<SourceModalsSaveSourceProps, 'data' | 'onClose'> {
  t: TFunction;
  isEdit: boolean;
}

interface SourceSaveFieldFormValues {
  name: string;
  connection_name: string;
  connection_id: number;
  connection_properties: IFormValue;
  active: boolean;
}
