import { Button, Tooltip } from '@ui';
import { ConfirmModal, useConfirmModal } from '@components';
import { ReactNode } from 'react';
import { ButtonProps, ModalProps, TooltipProps } from 'antd';
import { useTranslation } from 'react-i18next';

export const ButtonWithConfirmation = ({
  icon,
  confirmTitle,
  confirmContent,
  submitFunc,
  loading,
  disabled,
  confirmOkText,
  confirmCancelText,
  btnText,
  tooltipTitle,
  okButtonProps,
  tooltipPlacement,
  ...props
}: ButtonWithConfirmationProps) => {
  const { t } = useTranslation(['shared']);
  const confirmModal = useConfirmModal();

  return (
    <>
      <Button
        title={tooltipTitle ?? t('delete')}
        icon={icon ?? null}
        danger={!icon}
        loading={loading}
        disabled={disabled}
        onClick={() => {
          confirmModal.openConfirmation({
            content: confirmContent,
          });
        }}
        placement={tooltipPlacement}
        {...props}
      >
        {/*TODO change it later*/}
        {icon ? null : btnText ?? t('delete')}
      </Button>
      <ConfirmModal
        title={confirmTitle}
        submitFunc={submitFunc}
        okText={confirmOkText ?? t('delete')}
        cancelText={confirmCancelText}
        okButtonProps={okButtonProps ?? { danger: true }}
        {...confirmModal}
      />
    </>
  );
};

export interface ButtonWithConfirmationProps extends ButtonProps {
  icon?: ReactNode;
  btnText?: string;
  loading?: boolean;
  submitFunc: () => void;
  disabled?: boolean;
  tooltipTitle?: string;
  confirmTitle: string;
  confirmContent?: ReactNode;
  confirmOkText?: string;
  confirmCancelText?: string;
  okButtonProps?: ModalProps['okButtonProps'];
  tooltipPlacement?: TooltipProps['placement'];
}
