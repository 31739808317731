import { I18nLocales } from '@i18n';
import { I18nDnAObjectTranslations } from '@i18n/dnaObject';
import en from './analysisObjects_en.json';
import enDnAObjects from '../dnaObject/dnaObject_en.json';

export const analysisObjectsLocales: Record<I18nLocales, I18nAnalysisObjectsTranslations> = {
  en: {
    ...en,
    codeLabObject: { ...en.codeLabObject, ...enDnAObjects.codeLabObject },
    sqlLab: { ...en.sqlLab, ...enDnAObjects.sqlLab },
  },
};

interface I18nAnalysisObjectsTranslations {
  sqlLab: {
    rootPageName: string;
    modals: {
      deleteSuccess: string;
    };
    loadingListError: string;
    importForm: {
      title: string;
    };
    confirmation: {
      title: string;
      description: string;
    };
  } & I18nDnAObjectTranslations['sqlLab'];
  codeLabObject: {
    rootPageName: string;
    modals: {
      deleteSuccess: string;
    };
    loadingListError: string;
    importForm: {
      title: string;
    };
    confirmation: {
      title: string;
      description: string;
    };
  } & I18nDnAObjectTranslations['codeLabObject'];
}
