import { ChevronLeftIcon, ChevronRightIcon } from '@components/icons';
import { SIDEBAR_MENU_WIDTH } from '@config/constants';
import { CSSObject, Theme } from '@emotion/react';
import { MouseEventHandler, ReactNode, useRef, useState } from 'react';

export const DEFAULT_PANEL_WIDTH = 360;
const COLLAPSED_WIDTH = 20;
const HIDE_BORDER_LIMIT = 200;

export const ResizablePanel = ({
  children,
  width: initialWidth = DEFAULT_PANEL_WIDTH,
  onWidthChange,
}: ResizablePanelProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(initialWidth);

  const isCollapsed = width === COLLAPSED_WIDTH;

  const onResize = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;

    let updatedWidth = width;

    if (containerRef.current && target instanceof Element) {
      const elementDrag = (e: MouseEvent) => {
        const { clientX } = e;
        const elementWidth = clientX - SIDEBAR_MENU_WIDTH;

        if (elementWidth >= width) {
          if (elementWidth < HIDE_BORDER_LIMIT) {
            if (elementWidth >= HIDE_BORDER_LIMIT / 2) {
              updatedWidth = HIDE_BORDER_LIMIT;
            }
          } else {
            updatedWidth = elementWidth;
          }
        } else {
          if (elementWidth < HIDE_BORDER_LIMIT) {
            if (elementWidth < HIDE_BORDER_LIMIT / 2) {
              updatedWidth = COLLAPSED_WIDTH;
            }
          } else {
            updatedWidth = elementWidth;
          }
        }

        setWidth(updatedWidth);
      };

      const closeDragElement = () => {
        onWidthChange?.(updatedWidth);
        document.removeEventListener('mousemove', elementDrag);
        document.removeEventListener('mouseup', closeDragElement);
      };

      document.addEventListener('mousemove', elementDrag);
      document.addEventListener('mouseup', closeDragElement);
    }
  };

  const handleResizeButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (isCollapsed) {
      setWidth(DEFAULT_PANEL_WIDTH);
    } else {
      setWidth(COLLAPSED_WIDTH);
    }
  };

  return (
    <div ref={containerRef} css={cssContainer(width)}>
      <div css={cssBody}>
        <div css={cssContent(isCollapsed)}>{children}</div>
      </div>
      <div aria-label="resizer" css={cssResizer} onMouseDown={onResize} role="button" tabIndex={0} />
      <button css={cssResizeButton} type="button" onClick={handleResizeButtonClick}>
        {isCollapsed ? <ChevronRightIcon css={cssResizeIcon} /> : <ChevronLeftIcon css={cssResizeIcon} />}
      </button>
    </div>
  );
};

interface ResizablePanelProps {
  children: ReactNode;
  width: number;
  onWidthChange?: (width: number) => void;
}

const cssContainer =
  (width: number) =>
  (theme: Theme): CSSObject => ({
    position: 'relative',
    flex: '1 0 auto',
    width: `${width}px`,
    height: '100%',
    borderRight: `1px solid ${theme['color-grey-300']}`,
  });

const cssBody = (): CSSObject => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
});

const cssContent = (isCollapsed: boolean) => (): CSSObject => ({
  display: 'flex',
  flex: '1 0 100%',
  flexDirection: 'column',
  maxHeight: '100%',
  width: '100%',
  padding: '24px 16px',
  ...(isCollapsed && {
    opacity: 0,
    pointerEvents: 'none',
  }),
});

const cssResizer = (theme: Theme): CSSObject => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2px',
  height: '100%',
  top: 0,
  right: '-2px',
  opacity: 0,
  cursor: 'ew-resize',
  transition: '0.3s all',
  zIndex: 10,

  ':after': {
    content: "''",
    width: '2px',
    height: '100%',
    backgroundColor: theme['color-brand-blue-500'],
  },

  ':hover, :active': {
    opacity: 0.7,
  },
});

const cssResizeButton = (theme: Theme): CSSObject => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  top: '6px',
  right: '-12px',
  border: `1px solid ${theme['color-grey-400']}`,
  borderRadius: '100%',
  background: 'white',
  position: 'absolute',
  zIndex: '10',
  cursor: 'pointer',

  ':focus': {
    outline: 'none',
  },
});

const cssResizeIcon = (theme: Theme): CSSObject => ({
  position: 'absolute',
  height: '16px',
  width: 'auto',
  color: theme.colorText,
});
