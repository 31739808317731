import { Form, FormItem, SelectWithSearch, Skeleton, Space } from '@ui';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

export const usersToValues = (users: User[]): UserValue[] =>
  users.map((user) => ({
    label: `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)} (${user.email})`,
    value: user.rcc_user_id,
  }));

export const normalizeUsers = (users: UserValue[] | number[]) =>
  users?.map((user) => (typeof user === 'number' ? user : user.value)) || [];

export const NotificationsForm = ({ form, data, search }: NotificationsFormProps) => {
  const { t } = useTranslation(['datastore']);
  const [value, setValue] = useState<UserValue[]>([]);
  const [fetching, setFetching] = useState(data?.users && data.users.length > 0);

  useEffect(() => {
    if (data?.users && data.users.length > 0) {
      setFetching(true);
      search({ ids: data?.users.join(',') })
        .then((users) => form.setFieldValue(['notification', 'users'], usersToValues(users)))
        .finally(() => setFetching(false));
    } else {
      form.setFieldValue(['notification', 'users'], []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.users]);

  const fetchUserList = useCallback(
    (value: string) => search({ value }).then((users) => usersToValues(users)),
    [search],
  );

  const selectedUser = form.getFieldValue(['notification', 'users']).length;

  return (
    <>
      {/*<FormItem name={['notification', 'send_email']} label={t('notifications.sendEmail')} valuePropName="checked">*/}
      {/*  <Checkbox />*/}
      {/*</FormItem>*/}
      <FormItem
        labelCol={{ span: 6 }}
        name={['notification', 'users']}
        label={
          <Space direction="vertical" style={{ gap: 0 }}>
            {t('notifications.sendTo')}
            {selectedUser > 0 ? `(${selectedUser} added)` : ''}
          </Space>
        }
        normalize={normalizeUsers}
      >
        {fetching ? (
          <Skeleton.Input active size="small" />
        ) : (
          <SelectWithSearch
            mode="multiple"
            value={value}
            placeholder="Select users"
            fetchOptions={fetchUserList}
            onChange={(newValue) => setValue(newValue as UserValue[])}
            style={{ width: '100%' }}
            fetchOnMount
          />
        )}
      </FormItem>
    </>
  );
};

export interface SearchUsersQuery {
  value?: string;
  ids?: string; // list delimited by comma
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
  rcc_user_id: number;
}

export type SearchUsersResponse = User[];

interface NotificationsFormProps {
  form: ReturnType<typeof Form.useForm>[0];
  data?: TNotificationConfig;
  search: (params: SearchUsersQuery) => Promise<SearchUsersResponse>;
}

export type TNotificationConfig = {
  send_email?: boolean;
  users: number[];
};

interface UserValue {
  label: string;
  value: number;
}
