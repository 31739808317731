import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const RequiredMark: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={16} height={16} fill="none" viewBox="0 0 16 16">
    <path
      d="M8.64502 8.424L10.93 11.524L10.046 12.114L8.01902 8.9L5.99102 12.111L5.14302 11.521L7.42802 8.421L3.99902 7.54L4.33002 6.581L7.68702 7.76L7.46602 4H8.57202L8.38702 7.76L11.705 6.581L12.005 7.581L8.64502 8.424Z"
      fill="#D21C1C"
    />
  </BaseIcon>
));
