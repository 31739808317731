import {
  IDataset,
  IDatasetListQueryParams,
  IDatasetListResponse,
  IStackDatasetCreateQueryParams,
} from '@modules/dataset/DatasetTypes';
import { isCrossStudy } from '@shared/utils/common';
import { DATASET_TAG_DESCRIPTION } from '@modules/dataset/duck/datasetConstants';
import { appApi } from '@config/appApi';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const DatasetApiRoutes = {
  list: 'api/datasets/study/list',
  globalList: 'api/datasets/global/list',
  stackDatasetList: `api/datasets/stack/list`,
  dataset: (datasetId: number) => `/api/datasets/${datasetId}`,
  // TODO this new api doesn't work if we use them from datastage. Need to create the copies of these api's on backend for using in datastage
  datasetStudy: 'api/internal/datasets/study',
  datasetGlobal: 'api/internal/datasets/global',
  envSQLDatasetList: `/api/external/env/sql-lab`,
  stackDatasetTable: `/api/datasets/stack/table`,
};

export const DatasetInvalidations: {
  LIST: TagDescription<DATASET_TAG_DESCRIPTION.LIST>;
  STACK_LIST: TagDescription<DATASET_TAG_DESCRIPTION.STACK_LIST>;
  ID: (id: number) => TagDescription<DATASET_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: DATASET_TAG_DESCRIPTION.LIST, id: 'LIST' },
  STACK_LIST: { type: DATASET_TAG_DESCRIPTION.STACK_LIST, id: 'STACK_LIST' },
  ID: (datasetId: number) => ({ type: DATASET_TAG_DESCRIPTION.ID, datasetId }),
};

export const DatasetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    datasetListPaginated: builder.query<IDatasetListResponse, IDatasetListQueryParams | void>({
      providesTags: [DatasetInvalidations.LIST],
      query: (params) => {
        const isGlobal = isCrossStudy(params?.study_id!);
        return {
          params,
          url: isGlobal ? DatasetApiRoutes.globalList : DatasetApiRoutes.list,
        };
      },
    }),
    stackDatasetListPaginated: builder.query<IDatasetListResponse, IDatasetListQueryParams | void>({
      providesTags: [DatasetInvalidations.STACK_LIST],
      query: (params) => ({
        params,
        url: DatasetApiRoutes.stackDatasetList,
      }),
    }),
    envSQLDatasetList: builder.query<IDataset[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: DatasetApiRoutes.envSQLDatasetList,
      }),
    }),
    // TODO this new api doesn't work if we use them from datastage. Need to create the copies of these api's on backend for using in datastage
    // deleteDataset: builder.mutation<void, IDatasetDeleteParams>({
    //   invalidatesTags: [DatasetInvalidations.LIST],
    //   query: ({ name, isGlobal }) => ({
    //     params: {
    //       name,
    //     },
    //     method: 'DELETE',
    //     url: isGlobal ? DatasetApiRoutes.datasetGlobal : DatasetApiRoutes.datasetStudy,
    //   }),
    // }),
    // TODO this api work
    deleteDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [DatasetInvalidations.LIST, DatasetInvalidations.ID(id)],
      query: (id) => ({
        method: 'DELETE',
        url: DatasetApiRoutes.dataset(id),
      }),
    }),
    createStackDataset: builder.mutation<void, IStackDatasetCreateQueryParams>({
      invalidatesTags: [DatasetInvalidations.STACK_LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: DatasetApiRoutes.stackDatasetTable,
      }),
    }),
  }),
});

export const {
  useStackDatasetListPaginatedQuery,
  useLazyEnvSQLDatasetListQuery,
  useDatasetListPaginatedQuery,
  useDeleteDatasetMutation,
  useCreateStackDatasetMutation,
} = DatasetApi;
