import {
  ILibrarySqlQuery,
  ILibrarySqlQueryResponse,
  TLibrarySqlQueryDeleteProps,
} from '@modules/library/sqlQuery/LibrarySqlQueryTypes';
import { Table } from '@ui';
import { Trash } from '@components/icons';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, ButtonWithConfirmation } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';

export const LibrarySqlQueryList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDelete,
  t,
}: SqlQueryListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const columns: TableColumnsType<ILibrarySqlQuery> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          title: t('rootTable.description'),
          dataIndex: 'description',
        },
        {
          width: 130,
          title: t('rootTable.libraryVersion'),
          dataIndex: 'version',
        },
        {
          title: t('rootTable.source'),
          key: 'narrow',
          dataIndex: 'source',
          render: (_: any, record: ILibrarySqlQuery) => (
            <ActorAndInfo
              info={supportedEnvs[record.env]?.label || t('na')}
              actor={record.source || t('crossStudy')}
              actorSize={'sm'}
            />
          ),
        },
        {
          title: t('rootTable.created'),
          key: 'narrow',
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (createdAt: number, record: ILibrarySqlQuery) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
          ),
        },
        onDelete && {
          width: 150,
          title: t('delete'),
          dataIndex: 'actionDelete',
          render: (_: any, { id, name }: ILibrarySqlQuery) => (
            <ButtonWithConfirmation
              icon={<Trash color="darkGrey" />}
              submitFunc={() => onDelete({ id, name })}
              confirmContent={t('confirmation.description', { name })}
              confirmTitle={t('confirmation.title')}
              loading={loadingDelete}
            />
          ),
        },
      ].filter(Boolean) as TableColumnsType<ILibrarySqlQuery>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, onDelete],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface SqlQueryListProps {
  libraryId: number;
  data?: ILibrarySqlQueryResponse;
  pagination?: TableProps<ILibrarySqlQueryResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibrarySqlQueryResponse['items'][0]>['onChange'];
  onDelete?: (val: TLibrarySqlQueryDeleteProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
