import { DraggableModal } from '@ui';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { ISQLLabObjectSelectFormProps, SQLLabObjectSelectForm } from '@modules/dnaObject/SQLLab/components';
import { ISQLLabObject } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { prepareSQLLabObjectForImport } from '@modules/dnaObject/SQLLab/duck/sqlLabUtils';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useImportAnalysisObjectSQLDatasetMutation } from '../duck/analysisObjectApi';

const AnalysisObjectSQLLabModalsImportContent = ({ onClose }: IAnalysisObjectSQLLabModalsImportContentProps) => {
  const [importAOSQLDataset] = useImportAnalysisObjectSQLDatasetMutation();

  const onImport: ISQLLabObjectSelectFormProps['onSubmit'] = async (
    values: IDataset[] | ISQLLabObject[],
    { systemInfo, kind },
  ) => {
    return await importAOSQLDataset({
      data: prepareSQLLabObjectForImport(values, systemInfo!),
      source: kind,
    }).unwrap();
  };

  return (
    <SQLLabObjectSelectForm
      onClose={onClose}
      onSubmit={onImport}
      hideOverwriteHandles
      onlyCurrentEnv
      libraryStatuses={[LibraryStatus.Active]}
    />
  );
};

export const AnalysisObjectSQLLabModalsImport = ({ open, data, onClose }: IAnalysisObjectSQLLabModalsImportProps) => {
  const { t } = useTranslation(['analysisObjects']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('sqlLab.importForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisObjectSQLLabModalsImportContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IAnalysisObjectSQLLabModalsImportProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectSQLLabModalsImportContentProps
  extends Pick<IAnalysisObjectSQLLabModalsImportProps, 'data' | 'onClose'> {
  t: TFunction;
}
