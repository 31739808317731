import { useAppPermissions } from '@modules/user/duck/userHooks';
import { DefaultDeniedFallback } from '@modules/user/UserRoleGate';
import { libraryRoutes } from '@routes/library';
import { Navigate, useParams } from 'react-router-dom';

export const LibraryViewPage = () => {
  const params = useParams() as { libraryId: string };
  const { appPermissions } = useAppPermissions();

  const redirectRouteMatrix = [
    appPermissions.canGlDmoRead && libraryRoutes.models.root.resolver(params),
    appPermissions.canGlCdroRead && libraryRoutes.cdr.root.resolver(params),
    appPermissions.canGlSloRead && libraryRoutes.sqlLab.root.resolver(params),
    appPermissions.canGlSqoRead && libraryRoutes.sqlQuery.root.resolver(params),
    appPermissions.canGlCloRead && libraryRoutes.notebook.root.resolver(params),
    appPermissions.canGlPscRead && libraryRoutes.psc.root.resolver(params),
    appPermissions.canGlAuditLogsRead && libraryRoutes.audit.root.resolver(params),
  ].filter((item) => typeof item === 'string') as string[];

  if (!redirectRouteMatrix.at(0)) {
    return <DefaultDeniedFallback />;
  }

  return <Navigate to={redirectRouteMatrix.at(0)!} replace />;
};
