import { GlobeAlt, LibrariesSwitcherIcon } from '@components/icons';
import { MenuSelector } from '@components/MainMenu/components/MenuSelector';
import { Library } from '@modules/library/root/LibraryTypes';
import { useLibraryListQuery } from '@modules/library/root/duck/libraryApi';
import { libraryRoutes } from '@routes/library';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { TreeDataNode, TreeProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const LibrarySelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['libraryRoot']);

  // TODO create on backend side api to get all libraries without pagination
  const libraryListQuery = useLibraryListQuery({ page_size: 9999 });

  const globalLibrary = useSelector(selectGlobalLibrary);

  const libraries = sortBy(libraryListQuery.data?.items, 'name');

  const { isLoading } = libraryListQuery;

  const librariesTree: LibraryDataNode[] = useMemo(
    () =>
      isLoading
        ? []
        : libraries.map((library) => ({
            title: library.name,
            key: library.id,
            isLeaf: true,
            rawData: library,
            icon: <GlobeAlt />,
          })),
    [libraries, isLoading],
  );

  const handleSelectNode: TreeProps<LibraryDataNode>['onSelect'] = (_, { node }) => {
    const { isLeaf, rawData } = node;
    if (isLeaf && rawData) {
      navigate(libraryRoutes.view.resolver({ libraryId: rawData.id }), {
        state: { name: rawData.name, status: rawData.status },
      });
    }
  };

  return (
    <MenuSelector
      treeData={librariesTree}
      selectedKeys={globalLibrary ? [globalLibrary.id] : []}
      onSelect={handleSelectNode}
      title={globalLibrary?.name ?? ''}
      category={t('selector.category')}
      noDataText={t('selector.noLibraries')}
      isLoading={isLoading}
      Icon={LibrariesSwitcherIcon}
    />
  );
};

interface LibraryDataNode extends TreeDataNode {
  rawData?: Library;
}
