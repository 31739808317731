import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const AuditLogIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M19.5 14.25V11.625C19.5 10.7299 19.1444 9.87145 18.5115 9.23851C17.8786 8.60558 17.0201 8.25 16.125 8.25H14.625C14.3266 8.25 14.0405 8.13147 13.8295 7.9205C13.6185 7.70952 13.5 7.42337 13.5 7.125V5.625C13.5 4.72989 13.1444 3.87145 12.5115 3.23851C11.8785 2.60558 11.0201 2.25 10.125 2.25H8.25M13.481 15.731L15 17.25M13.481 15.731C13.7248 15.4872 13.9183 15.198 14.0502 14.8795C14.1821 14.5611 14.25 14.2197 14.25 13.875C14.25 13.1788 13.9734 12.5111 13.4812 12.0188C12.9889 11.5266 12.3212 11.25 11.625 11.25C10.9288 11.25 10.2611 11.5266 9.76884 12.0188C9.27656 12.5111 9 13.1788 9 13.875C9 14.2197 9.0679 14.5611 9.19982 14.8795C9.33173 15.198 9.52509 15.4874 9.76884 15.7312C10.0126 15.9749 10.302 16.1683 10.6205 16.3002C10.9389 16.4321 11.2803 16.5 11.625 16.5C11.9697 16.5 12.3111 16.4321 12.6295 16.3002C12.948 16.1683 13.2372 15.9748 13.481 15.731ZM10.5 2.25H5.625C5.004 2.25 4.5 2.754 4.5 3.375V19.875C4.5 20.496 5.004 21 5.625 21H18.375C18.996 21 19.5 20.496 19.5 19.875V11.25C19.5 8.86305 18.5518 6.57387 16.864 4.88604C15.1761 3.19821 12.8869 2.25 10.5 2.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
