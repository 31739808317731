import { Loader, QueryError } from '@components';
import { useLibraryAppListener } from '@modules/library/root/duck/libraryHooks';
import { selectLibraryAppPreloaded } from '@modules/library/root/duck/librarySelectors';
import { LibraryMenu } from '@modules/library/root/components/LibraryMenu';
import { CSSObject } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LibraryAppRouterProvider = () => {
  const { queryError } = useLibraryAppListener();

  const libraryAppPreloaded = useSelector(selectLibraryAppPreloaded);

  if (queryError) {
    return <QueryError error={{ status: 404, data: { userMsg: 'Library Not Found' } }} title="Library Not Found" />;
  }

  return (
    <div css={cssBody}>
      {<LibraryMenu />}
      {!libraryAppPreloaded ? <Loader mode="fixed" size="large" /> : <Outlet />}
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});
