import { default as AntTypography } from 'antd/es/typography';
import { css, CSSObject, Theme } from '@emotion/react';
import { TitleProps } from 'antd/es/typography/Title';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import { LinkProps } from 'antd/es/typography/Link';

const CustomTitle: React.FunctionComponent<TitleProps> = ({ level = 1, children, ...props }) => {
  return <AntTypography.Title {...props} css={customTitleStyle(level)} level={level} children={children} />;
};

const CustomParagraph: React.FunctionComponent<ParagraphProps> = ({ children, ...props }) => {
  return <AntTypography.Paragraph {...props} css={customParagraphStyle} children={children} />;
};

const CustomLink: React.FunctionComponent<LinkProps> = ({ children, ...props }) => {
  return <AntTypography.Link {...props} css={customLinkStyle} children={children} />;
};

const Typography = {
  ...AntTypography,
  Title: CustomTitle,
  Paragraph: CustomParagraph,
  Link: CustomLink,
};

export { Typography };

const customTitleStyle = (level: number) => css`
  && {
    margin-bottom: 0;
    ${level === 2
      ? {
          fontWeight: 400,
        }
      : {
          fontFamily: 'Raleway',
          fontWeight: 600,
        }}
  }
`;

const customParagraphStyle = css`
  && {
    margin-bottom: 0;
  }
`;

const customLinkStyle = (theme: Theme): CSSObject => ({
  '&&': {
    fontWeight: 500,
    textDecoration: 'underline 1px transparent',
    transition: 'text-decoration-color 300ms',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme['color-brand-blue-500'],
    },
  },
});
