import { IAnalysisPackageResponse } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { analysisPackageActions } from '@modules/library/analysisPackage/duck/analysisPackageSlice';
import { EAnalysisPackageType } from '@modules/library/analysisPackage/modals';
import { CloudUpload, PackageObjects } from '@components/icons';
import { Button, Table } from '@ui';
import { ActorAndInfo } from '@components';
import { dateToString } from '@shared/utils/Date';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { useDispatch } from 'react-redux';
import {
  AnalysisPackageModalsDeployAnalysisPackageProps,
  AnalysisPackageModalsSaveAnalysisPackageProps,
} from '../modals/components';

export const AnalysisPackageList = ({ data, onChange, pagination, loading, t }: IAnalysisPackageListProps) => {
  const dispatch = useDispatch();

  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisPackageResponse['items'][0]>();

  const viewObjects = (data: AnalysisPackageModalsSaveAnalysisPackageProps['data']) =>
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.saveAnalysisPackage, data }));

  const deployObjects = (data: AnalysisPackageModalsDeployAnalysisPackageProps['data']) =>
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.deployAnalysisPackage, data }));

  const columns: TableColumnsType<IAnalysisPackageResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.version'),
        dataIndex: 'version',
        ...getColumnSearchProps('version'),
        onFilter: undefined,
      },
      { title: t('rootTable.description'), dataIndex: 'description' },
      {
        title: t('rootTable.created'),
        key: 'narrow',
        dataIndex: 'created_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
        ),
      },
      {
        width: 160,
        title: t('rootTable.packageObjects'),
        dataIndex: 'packageObjects',
        render: (_, record) => (
          <Button
            title={t('rootTable.packageObjects')}
            size="middle"
            icon={<PackageObjects />}
            onClick={() => viewObjects(record)}
          />
        ),
      },
      {
        width: 160,
        title: t('rootTable.deployPackage'),
        dataIndex: 'deployPackage',
        render: (_, record) => (
          <Button
            title={t('rootTable.deployPackage')}
            size="middle"
            icon={<CloudUpload />}
            onClick={() => deployObjects(record)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface IAnalysisPackageListProps {
  data?: IAnalysisPackageResponse;
  pagination?: TableProps<IAnalysisPackageResponse['items'][0]>['pagination'];
  onChange?: TableProps<IAnalysisPackageResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  t: TFunction;
}
