import { IStructure, TableDataRowProps, TabProps } from '@modules/job/JobTypes';
import { COLUMN_TYPES, SYSTEM_COLUMNS } from '@modules/job/duck/constants';
import { DEFAULT_PK } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { MandatoryColumnsWithAutogeneration } from '@config/constants';

export const initValues = (tabs: TabProps[]) =>
  tabs.reduce(
    (acc, val) => ({
      ...acc,
      [val.tableName]: val,
    }),
    {},
  );

export const typeOptions = Object.entries(COLUMN_TYPES).map(([key, value]) => ({ label: value, value: key }));

export const getMapping = (structure: IStructure, tabMapping?: TableDataRowProps[]) => {
  const mappingFromFile = Object.keys(structure).map((key) => ({
    sourceColumn: key,
    targetColumn: key,
    type: structure[key],
    nullable: !SYSTEM_COLUMNS.includes(key),
    primaryKey: key === DEFAULT_PK,
  }));

  if (tabMapping && !!tabMapping.length) {
    const updateFileMapping = mappingFromFile.map((el) => {
      const findTabMappingColumn = tabMapping.find((col) => col.sourceColumn === el.sourceColumn);

      if (findTabMappingColumn) {
        return { ...el, ...findTabMappingColumn, sourceMappingColumn: el.sourceColumn, skip: false };
      }
      return { ...el, sourceMappingColumn: '', skip: true };
    });

    const fileMappingColumns = mappingFromFile.map((el) => el.sourceColumn);
    const remainTabMappingColumns = tabMapping
      .filter((el) => !fileMappingColumns.includes(el.sourceColumn!))
      .map((item) => ({
        ...item,
        sourceMappingColumn: item.sourceColumn,
        sourceColumn: '',
      }));

    return [...updateFileMapping, ...remainTabMappingColumns];
  }

  return mappingFromFile.map((el) => ({
    ...el,
    sourceMappingColumn: el.sourceColumn,
    skip: false,
  }));
};

export const generatedMappingTypes = (mapping: TableDataRowProps[]) =>
  mapping.reduce((acc, item) => ({ ...acc, [item.sourceColumn!]: item.type }), {});

export const findTabObject = (tabName: string, tabs: TabProps[]) => tabs.find((tab) => tab.tableName === tabName);

export const removeSkipColumns = (mapping: TableDataRowProps[]) => mapping.filter((el) => !el.skip);

export const isNoMapping = (tab: TabProps) => !tab.mapping.length;

export const isNoSourceColumn = (tab: TabProps) =>
  tab.mapping
    .filter((item) => !item.skip && !item.sourceColumn)
    .map((item) => item.sourceMappingColumn)
    .join(', ');

export const missingColumns = (tab: TabProps) =>
  tab.missingRequiredColumns.filter((item) => !MandatoryColumnsWithAutogeneration.includes(item)).join(', ');

export const isEmptyPK = (tab: TabProps) => tab.mapping.every((item) => !item.primaryKey);

export const isAllTabConfirmed = (tab: TabProps) => tab.confirmed;

export const isNoColumnInUploadedMapping = (tabs: TabProps[]) =>
  tabs.some((el) => el.mapping.some((item) => !item.sourceMappingColumn));

export const isSystemColumn = (column: string) => SYSTEM_COLUMNS.includes(column);

export const isAutoConfirmTab = (tab: any) => !isNoMapping(tab) && !isNoSourceColumn(tab) && !isEmptyPK(tab);

export const truncateString = (str: string, countSymbols: number) =>
  str.length > countSymbols ? str.substring(0, countSymbols) + '...' : str;

export const isNoRT = (tab: TabProps) => !tab.referenceTable;
