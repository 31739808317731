import { appApi } from '@config/appApi';
import { IPsc } from '@modules/library/psc/PscTypes';
import { ICDRReport } from '@modules/library/cdr/LibraryCdrTypes';
import { EnvStudyListQuery, LibraryListResponse, Library } from '@modules/library/root/LibraryTypes';
import { Model } from '@modules/model/ModelTypes';
import { StudyListResponse } from '@modules/study/StudyTypes';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { LIBRARY_TAG_DESCRIPTION, LibraryStatus } from './libraryConstants';

export const LibraryApiRoutes = {
  list: '/api/library/libraries',
  library: (libraryId: number | string) => `/api/library/libraries/${libraryId}`,
  envStudyList: `/api/external/env/studies`,
  envModelList: `/api/external/env/models`,
  envCDRList: `/api/external/env/cdr`,
  envPscList: `/api/external/env/psc`,
};

const LibraryInvalidates: {
  LIST: TagDescription<LIBRARY_TAG_DESCRIPTION.LIST>;
  ID: (libraryId: number | string) => TagDescription<LIBRARY_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (libraryId: number | string) => ({ type: LIBRARY_TAG_DESCRIPTION.ID, libraryId }),
};

export const LibraryApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    library: builder.query<Library, number | string>({
      providesTags: (request, error, libraryId) => [LibraryInvalidates.ID(libraryId)],
      query: (libraryId) => ({
        url: LibraryApiRoutes.library(libraryId),
      }),
    }),
    libraryList: builder.query<LibraryListResponse, LibraryListQueryParams | void>({
      providesTags: [LibraryInvalidates.LIST],
      query: (params) => ({
        params,
        url: LibraryApiRoutes.list,
      }),
    }),
    envStudyList: builder.query<StudyListResponse, EnvStudyListQuery>({
      query: (params) => ({
        params,
        url: LibraryApiRoutes.envStudyList,
      }),
    }),
    envModelList: builder.query<Model[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: LibraryApiRoutes.envModelList,
      }),
    }),
    envCDRList: builder.query<ICDRReport[], EnvDataListQueryBase>({
      query: (params) => ({
        params: { ...params, analysis_objects: 'true' },
        url: LibraryApiRoutes.envCDRList,
      }),
    }),
    envPscList: builder.query<IPsc[], EnvDataListQueryBase>({
      query: (params) => ({
        params,
        url: LibraryApiRoutes.envPscList,
      }),
    }),
    saveLibrary: builder.mutation<void, ILibrarySaveQuery | ILibraryUpdateQuery>({
      invalidatesTags: (request, error, data) =>
        data.id ? [LibraryInvalidates.LIST, LibraryInvalidates.ID(data.id)] : [LibraryInvalidates.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? LibraryApiRoutes.library(data.id) : LibraryApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useLibraryListQuery,
  useLibraryQuery,
  useLazyLibraryListQuery,
  useSaveLibraryMutation,
  useLazyEnvStudyListQuery,
  useLazyEnvModelListQuery,
  useLazyEnvCDRListQuery,
  useLazyEnvPscListQuery,
} = LibraryApi;

interface ILibrarySaveQuery {
  id?: number;
  name: string;
  description: string;
  status?: LibraryStatus;
}

interface ILibraryUpdateQuery {
  id: number;
  status: LibraryStatus;
}

interface LibraryListQueryParams {
  page?: number;
  page_size?: number;
  status?: string;
  order?: string;
  sort_by?: string;
}
