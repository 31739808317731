import { Button, Table, Tag } from '@ui';
import { InformationModal, MoreButton } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { jobActions } from '@modules/job/duck/jobSlice';
import { JobModalsType } from '@modules/job/modals';
import { Trash } from '@components/icons';
import routes from '@routes';
import { getStudyPathName } from '@routes/utils';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { getNumberWithSeparator, isCrossStudy } from '@shared/utils/common';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { useDispatch } from 'react-redux';
import { CSSObject, Theme } from '@emotion/react';
import { Domain, Status } from '../JobTypes';

export const JobDomainList = ({ data, studyId, loading, onHandleDeleteDomain, t }: JobDomainProps) => {
  const {
    userPermissions: { canImportJobDelete },
  } = useStudyPermissions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getColumnSearchProps, locale } = useColumnSearch<Domain[][0]>();

  const [errorDetails, setErrorDetails] = useState('');

  const actionItems = [
    { key: 'viewData', label: t('editTable.actionMenu.viewData') },
    { key: 'viewVariables', label: t('editTable.actionMenu.viewVariables') },
    { key: 'viewHistory', label: t('editTable.actionMenu.viewHistory') },
  ];

  const columns: TableColumnsType<Domain[][0]> = useMemo(
    () => [
      {
        width: 250,
        title: t('editTable.tableName'),
        dataIndex: 'table_name',
        key: 'name',
        ...getColumnSearchProps('table_name'),
      },
      {
        width: 170,
        title: t('editTable.totalColumns'),
        dataIndex: 'total_columns',
        sorter: (a, b) => a.total_columns - b.total_columns,
        render: (totalColumns: number) => getNumberWithSeparator(totalColumns),
      },
      {
        width: 110,
        title: t('editTable.totalRows'),
        dataIndex: 'total_rows',
        render: (total_rows: number) => getNumberWithSeparator(total_rows),
      },
      {
        width: 150,
        title: t('editTable.rowsImported'),
        dataIndex: 'imported_rows',
        render: (imported_rows: number) => getNumberWithSeparator(imported_rows),
      },
      {
        width: 160,
        title: t('editTable.rowsErrored'),
        dataIndex: 'errored_rows',
        render: (errored_rows: number) => getNumberWithSeparator(errored_rows),
      },
      {
        width: 250,
        title: t('editTable.message'),
        dataIndex: 'message',
        render: (message?: string) =>
          message ? (
            <button onClick={() => setErrorDetails(message)} css={cssActionBtn}>
              {message!.slice(0, 40)}...
            </button>
          ) : null,
      },
      {
        width: 150,
        title: t('editTable.importStatus'),
        dataIndex: 'status',
        render: (status: Status) => <Tag color={status}>{status}</Tag>,
      },
      canImportJobDelete && !isCrossStudy(studyId)
        ? {
            width: 85,
            title: t('editTable.btnDelete'),
            dataIndex: 'actionDelete',
            render: (_, record) => (
              <Button
                onClick={() => onHandleDeleteDomain(record)}
                icon={<Trash color="darkGrey" />}
                disabled={!record.table_exists}
                title={record.table_exists ? 'Delete table' : 'Table was not created'}
              />
            ),
          }
        : { width: 0 },
      // {
      //   width: 150,
      //   title: t('editTable.btnMove'),
      //   dataIndex: 'actionMoveToDataStore',
      //   render: (_, record) => (
      //     <Button onClick={() => console.log('Move To Data Store', record)} icon={<ImportOutlined />} />
      //   ),
      // },
      {
        width: 120,
        title: t('actions'),
        dataIndex: 'actionMenu',
        render: (_, record) => (
          <MoreButton
            label={t('editTable.actionMenu.label')}
            menu={{
              items: actionItems,
              onClick: ({ key }) => {
                switch (key) {
                  case 'viewData':
                    dispatch(viewerActions.setLeftSideData({}));
                    navigate(
                      routes[getStudyPathName(studyId)].dataViewer.resolver({
                        studyId,
                        tableId: record.table_name,
                      }),
                      { state: { external: true } },
                    );
                    break;
                  case 'viewVariables':
                    dispatch(jobActions.pushModal({ type: JobModalsType.viewVariables, data: record }));
                    break;
                  case 'viewHistory':
                    dispatch(jobActions.pushModal({ type: JobModalsType.viewHistory, data: record }));
                    break;
                  default:
                    break;
                }
              },
            }}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div>
      <Table
        locale={locale}
        columns={columns}
        dataSource={data?.items}
        loading={loading}
        rowKey={(item) => item.id}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={false}
      />
      {errorDetails && (
        <InformationModal title={t('errorDetails')} message={errorDetails} onClose={() => setErrorDetails('')} />
      )}
    </div>
  );
};

export interface DataDomainProps {
  name: string;
  items?: Array<Domain>;
}
interface JobDomainProps {
  studyId: number;
  data?: DataDomainProps | undefined;
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  onHandleDeleteDomain: (domain: Domain) => void;
  t: TFunction;
}

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
  textAlign: 'left',
});
