import { TLibraryPscModalsPushModalPayload, TLibraryPscModalsState } from '@modules/library/psc/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibraryPscStore = {
  modals: { selectedModal: null },
};

export const libraryPscSlice = createSlice({
  name: 'libraryPsc',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibraryPscModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const libraryPscActions = libraryPscSlice.actions;

export const libraryPscReducer = libraryPscSlice.reducer;

interface LibraryPscStore {
  modals: TLibraryPscModalsState;
}
