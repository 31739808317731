import { appApi } from '@config/appApi';
import {
  ILibrarySqlQueryListQueryParams,
  ILibrarySqlQueryQuery,
  ILibrarySqlQueryResponse,
  ILibrarySqlQueryValidateQuery,
} from '@modules/library/sqlQuery/LibrarySqlQueryTypes';
import { LIBRARY_SQL_QUERY_TAG_DESCRIPTION } from '@modules/library/sqlQuery/duck/librarySqlQueryConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibrarySqlQueryApiRoutes = {
  list: `api/library/libraries/sql-queries`,
  item: (sqlId: number) => `api/library/libraries/sql-queries/${sqlId}`,
  validate: `/api/library/libraries/sql-queries/validate`,
};

const LibrarySqlQueryInvalidations: {
  LIST: TagDescription<LIBRARY_SQL_QUERY_TAG_DESCRIPTION.LIST>;
  ID: (sqlId: number) => TagDescription<LIBRARY_SQL_QUERY_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_SQL_QUERY_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (sqlId: number) => ({ type: LIBRARY_SQL_QUERY_TAG_DESCRIPTION.ID, sqlId }),
};

export const LibrarySqlQueryApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    librarySqlQueryList: builder.query<ILibrarySqlQueryResponse, ILibrarySqlQueryListQueryParams | void>({
      providesTags: [LibrarySqlQueryInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibrarySqlQueryApiRoutes.list,
      }),
    }),
    libraryDeleteSqlQuery: builder.mutation<void, number>({
      invalidatesTags: (request, error, sqlId) => [
        LibrarySqlQueryInvalidations.LIST,
        LibrarySqlQueryInvalidations.ID(sqlId),
      ],
      query: (sqlId) => ({
        method: 'DELETE',
        url: LibrarySqlQueryApiRoutes.item(sqlId),
      }),
    }),
    libraryCopySqlQuery: builder.mutation<void, ILibrarySqlQueryQuery>({
      invalidatesTags: [LibrarySqlQueryInvalidations.LIST],
      query: ({ data, overwrite }) => ({
        data,
        params: { overwrite },
        method: 'POST',
        url: LibrarySqlQueryApiRoutes.list,
      }),
    }),
    validateSqlQuery: builder.mutation<void, ILibrarySqlQueryValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibrarySqlQueryApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibrarySqlQueryListQuery,
  useLazyLibrarySqlQueryListQuery,
  useLibraryDeleteSqlQueryMutation,
  useLibraryCopySqlQueryMutation,
  useValidateSqlQueryMutation,
} = LibrarySqlQueryApi;
