import { selectLibrarySqlQueryModals } from '@modules/library/sqlQuery/duck/librarySqlQuerySelectors';
import { librarySqlQueryActions } from '@modules/library/sqlQuery/duck/librarySqlQuerySlice';
import { ELibrarySqlQueryModalsType } from '@modules/library/sqlQuery/modals/LibrarySqlQueryModalsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { LibrarySqlQueryModalsCopySqlQuery, ILibrarySqlQueryModalsCopySqlQueryProps } from './components';

export const LibrarySqlQueryModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectLibrarySqlQueryModals);

  const closeModal = () => {
    dispatch(librarySqlQueryActions.dropModal());
  };

  return (
    <LibrarySqlQueryModalsCopySqlQuery
      open={selectedModal === ELibrarySqlQueryModalsType.copySqlQuery}
      data={data as ILibrarySqlQueryModalsCopySqlQueryProps['data']}
      onClose={closeModal}
    />
  );
};
