import { Button } from '@ui';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const RefreshButton = ({ onClick, iconOnly }: RefreshButtonProps) => {
  const { t } = useTranslation(['shared']);

  return <Button size="large" icon={<ReloadOutlined />} children={iconOnly ? null : t('refresh')} onClick={onClick} />;
};

interface RefreshButtonProps {
  onClick: () => void;
  iconOnly?: boolean;
}
