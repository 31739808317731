import { appApi } from '@config/appApi';
import { EnvDataListQueryBase } from '@shared/GlobalTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { CODE_LAB_TAG_DESCRIPTION } from './codeLabConstants';
import {
  ICodeLabJobListResponse,
  ICodeLabJobLogsResponse,
  ICodeLabSourceListResponse,
  IEnvCodeLabListResponse,
  ISaveCodeLabJobQuery,
} from '../CodeLabTypes';

export const CodeLabApiRoutes = {
  sourceList: '/api/notebook-scheduler/files',
  jobList: '/api/notebook-scheduler',
  jobItem: (codeLabJobId: number) => `/api/notebook-scheduler/${codeLabJobId}`,
  jobLogs: (codeLabJobId: number) => `/api/notebook-scheduler/${codeLabJobId}/logs`,
  envCodeLabList: 'api/external/env/notebook',
};

export const CodeLabInvalidations: {
  JOB_LIST: TagDescription<CODE_LAB_TAG_DESCRIPTION.JOB_LIST>;
  SOURCE_LIST: TagDescription<CODE_LAB_TAG_DESCRIPTION.SOURCE_LIST>;
} = {
  JOB_LIST: { type: CODE_LAB_TAG_DESCRIPTION.JOB_LIST, id: 'JOB_LIST' },
  SOURCE_LIST: { type: CODE_LAB_TAG_DESCRIPTION.SOURCE_LIST, id: 'SOURCE_LIST' },
};

export const CodeLabApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    codeLabSourceList: builder.query<ICodeLabSourceListResponse, void>({
      providesTags: [CodeLabInvalidations.SOURCE_LIST],
      query: () => ({ url: CodeLabApiRoutes.sourceList }),
    }),
    codeLabJobList: builder.query<ICodeLabJobListResponse, void>({
      providesTags: [CodeLabInvalidations.JOB_LIST],
      query: () => ({ url: CodeLabApiRoutes.jobList }),
    }),
    envCodeLabList: builder.query<IEnvCodeLabListResponse, EnvDataListQueryBase>({
      query: (params) => ({ params, url: CodeLabApiRoutes.envCodeLabList }),
    }),
    codeLabJobLogs: builder.query<ICodeLabJobLogsResponse, number>({
      query: (codeLabJobId) => ({ url: CodeLabApiRoutes.jobLogs(codeLabJobId) }),
    }),
    saveJobConfig: builder.mutation<void, ISaveCodeLabJobQuery>({
      invalidatesTags: [CodeLabInvalidations.JOB_LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? CodeLabApiRoutes.jobItem(data.notebook_id) : CodeLabApiRoutes.jobList,
      }),
    }),
    deleteJobConfig: builder.mutation<void, number>({
      invalidatesTags: [CodeLabInvalidations.JOB_LIST],
      query: (codeLabJobId) => ({
        method: 'DELETE',
        url: CodeLabApiRoutes.jobItem(codeLabJobId),
      }),
    }),
  }),
});

export const {
  useLazyEnvCodeLabListQuery,
  useCodeLabJobListQuery,
  useCodeLabSourceListQuery,
  useCodeLabJobLogsQuery,
  useSaveJobConfigMutation,
  useDeleteJobConfigMutation,
} = CodeLabApi;
