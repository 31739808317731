import { StrOrNum } from '@shared/GlobalTypes';
import { include, reverse } from 'named-urls';

const libraryRouteId = {
  root: 'library-root',
  view: 'library-view',
  models: {
    root: 'library-models-root',
  },
  cdr: {
    root: 'library-cdr-root',
  },
  sqlQuery: {
    root: 'library-sqlQuery-root',
  },
  sqlLab: {
    root: 'library-sqlLab-root',
  },
  notebook: {
    root: 'library-notebook-root',
  },
  psc: {
    root: 'library-psc-root',
  },
  audit: {
    root: 'library-audit-root',
  },
};

const libraryRoute = include('/library', {
  root: '',
  view: ':libraryId',
  models: include(':libraryId/models', {
    root: '',
  }),
  cdr: include(':libraryId/cdr', {
    root: '',
  }),
  sqlQuery: include(':libraryId/sql-query', {
    root: '',
  }),
  sqlLab: include(':libraryId/sql-lab', {
    root: '',
  }),
  notebook: include(':libraryId/code-lab', {
    root: '',
  }),
  psc: include(':libraryId/psc', {
    root: '',
  }),
  audit: include(':libraryId/audit', {
    root: '',
  }),
});

const libraryResolver = {
  root: () => libraryRoute.root,
  view: (params: LibraryResolver['view']['params']) => reverse(libraryRoute.view, params),
  models: {
    root: (params: LibraryResolver['models']['root']['params']) => reverse(libraryRoute.models.root, params),
  },
  cdr: {
    root: (params: LibraryResolver['cdr']['root']['params']) => reverse(libraryRoute.cdr.root, params),
  },
  sqlQuery: {
    root: (params: LibraryResolver['sqlQuery']['root']['params']) => reverse(libraryRoute.sqlQuery.root, params),
  },
  sqlLab: {
    root: (params: LibraryResolver['sqlLab']['root']['params']) => reverse(libraryRoute.sqlLab.root, params),
  },
  notebook: {
    root: (params: LibraryResolver['notebook']['root']['params']) => reverse(libraryRoute.notebook.root, params),
  },
  psc: {
    root: (params: LibraryResolver['psc']['root']['params']) => reverse(libraryRoute.psc.root, params),
  },
  audit: {
    root: (params: LibraryResolver['audit']['root']['params']) => reverse(libraryRoute.audit.root, params),
  },
};

export interface LibraryResolver {
  view: {
    params: { libraryId: StrOrNum };
  };
  models: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  cdr: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  sqlQuery: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  sqlLab: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  notebook: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  psc: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
  audit: {
    root: {
      params: { libraryId: StrOrNum };
    };
  };
}

export default {
  root: {
    path: libraryRoute.root,
    resolver: libraryResolver.root,
    id: libraryRouteId.root,
  },
  view: {
    path: libraryRoute.view,
    resolver: libraryResolver.view,
    id: libraryRouteId.view,
  },
  models: {
    root: {
      path: libraryRoute.models.root,
      resolver: libraryResolver.models.root,
      id: libraryRouteId.models.root,
    },
  },
  cdr: {
    root: {
      path: libraryRoute.cdr.root,
      resolver: libraryResolver.cdr.root,
      id: libraryRouteId.cdr.root,
    },
  },
  sqlQuery: {
    root: {
      path: libraryRoute.sqlQuery.root,
      resolver: libraryResolver.sqlQuery.root,
      id: libraryRouteId.sqlQuery.root,
    },
  },
  sqlLab: {
    root: {
      path: libraryRoute.sqlLab.root,
      resolver: libraryResolver.sqlLab.root,
      id: libraryRouteId.sqlLab.root,
    },
  },
  notebook: {
    root: {
      path: libraryRoute.notebook.root,
      resolver: libraryResolver.notebook.root,
      id: libraryRouteId.notebook.root,
    },
  },
  psc: {
    root: {
      path: libraryRoute.psc.root,
      resolver: libraryResolver.psc.root,
      id: libraryRouteId.psc.root,
    },
  },
  audit: {
    root: {
      path: libraryRoute.audit.root,
      resolver: libraryResolver.audit.root,
      id: libraryRouteId.audit.root,
    },
  },
};
