import AntdTag, { TagProps as AntdTagProps } from 'antd/es/tag';
import { ForwardedRef, forwardRef } from 'react';
import { CSSObject } from '@emotion/react';

const colorsMap: Record<TagType, string> = {
  blinded: 'green',
  filter: 'purple',
  snapshot: 'geekblue',
  source: 'cyan',
  review: 'magenta',
};

const getTagColor = (color?: string) => {
  const preparedColor = color?.toLowerCase();
  if (preparedColor === 'running') {
    return 'processing';
  }
  return preparedColor ?? 'default';
};

export const Tag = forwardRef(({ type, color, text, upperCaseText, ...props }: TagProps, ref: ForwardedRef<any>) => {
  if (type) {
    return (
      <AntdTag
        {...props}
        children={text ? (upperCaseText ? text.toUpperCase() : text) : props.children}
        color={colorsMap[type]}
        ref={ref}
      />
    );
  }
  return <AntdTag css={cssTag} {...props} color={getTagColor(color)} ref={ref} />;
});

const cssTag = (): CSSObject => ({
  border: 'none',
  height: '32px',
  alignContent: 'center',
  padding: '5.5px 12px',
});

type TagType = 'filter' | 'source' | 'snapshot' | 'blinded' | 'review';
type TagStatusType = AntdTagProps['color'] | 'running';

interface TagProps extends AntdTagProps {
  type?: TagType;
  color?: TagStatusType;
  text?: string;
  upperCaseText?: boolean;
}
