import { useGlobalTablesQuery, useTablesQuery } from '@modules/viewer/duck/viewerApi';
import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { viewerActions, ViewerGroup } from '@modules/viewer/duck/viewerSlice';
import { selectExpandedGroupsMemo, selectViewerGroupsMemo } from '@modules/viewer/duck/viewerSelectors';
import { DEFAULT_EXTERNAL_STORES, EXTERNAL_STORES } from '@modules/stores/duck/storeConstants';
import { getTableNameWithSchema } from '@shared/utils/Viewer';
import { selectAppliedENVSwitch, selectGlobalStudy } from '@app/duck/appSelectors';
import { selectStudyActiveUserRole, selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { getOnMouseClickCoordinates } from '@modules/modelEditor/components/builder/Utils';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { IAppStudyContext } from '@app/AppTypes';
import { useEffect, useMemo, useRef, DragEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNode } from 'antd/es/tree';
import {
  INode,
  ITreeViewOnExpandProps,
  ITreeViewOnNodeSelectProps,
  ITreeViewProps,
  NodeId,
} from 'react-accessible-treeview';

const storeTablesFallback: ViewerGroup[] = [];

export const useTableListSider = ({
  selectedTable,
  defaultTableName,
  onSelectTable,
  globalStudy,
  treeData,
}: IUseTaleListSiderProps) => {
  const dispatch = useDispatch();
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch) || '';
  const expandedGroups = useSelector(selectExpandedGroupsMemo(globalStudy!.id));

  const defaultTableNameProcessed = useRef(false);

  const selectedKeys = useMemo(() => (selectedTable ? [selectedTable] : []), [selectedTable]);

  const filteredExpandedGroups = useMemo(() => {
    return expandedGroups.filter((id) => id === 'root' || treeData?.some((tree) => tree.id === id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedGroups]);

  useEffect(() => {
    defaultTableNameProcessed.current = false;
  }, [appliedENVSwitch]);

  useEffect(() => {
    if (defaultTableName && !defaultTableNameProcessed.current) {
      let defaultTable: DataNode | undefined;

      treeData?.some((parent) =>
        parent.children?.some((child) => {
          if (child.id.toString().match(new RegExp(`[.:]${defaultTableName}$`))) {
            defaultTable = { key: child.id, title: child.name };
            return true;
          }
          return false;
        }),
      );

      if (defaultTable) {
        const key = parseInt((defaultTable['key'] as string).split(':').at(0) ?? '0', 10);

        if (key && !filteredExpandedGroups.includes(key)) {
          dispatch(
            viewerActions.updateExpanded({
              studyId: globalStudy!.id,
              keys: [...filteredExpandedGroups, key],
            }),
          );
        }

        defaultTableNameProcessed.current = true;
        onSelectTable(defaultTable.key as string, defaultTable.title as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTableName, treeData, filteredExpandedGroups]);

  const onDragStart: any = (event: DragEvent, nodeProps: INode) => {
    const { isBranch, name, metadata } = nodeProps;

    if (!isBranch) {
      event.dataTransfer.setData('application/reactflow', ModelEditorNodeType.domain);
      event.dataTransfer.setData('application/reactflow/name', name?.toString()!);
      event.dataTransfer.setData('application/reactflow/tableName', metadata?.originalId as string);
      event.dataTransfer.setData(
        'application/reactflow/mouse_click_coordinates',
        JSON.stringify(getOnMouseClickCoordinates(event)),
      );
      event.dataTransfer.effectAllowed = 'move';
    } else {
      event.preventDefault();
    }
  };

  const onSelect: ITreeViewProps['onNodeSelect'] = (props: ITreeViewOnNodeSelectProps) => {
    const { isBranch } = props.element;
    if (!isBranch) {
      onSelectTable(props.element.id as string, props.element.name);
    }
  };

  const onExpand: ITreeViewProps['onExpand'] = (props: ITreeViewOnExpandProps) => {
    const keys = Array.from(props.treeState.expandedIds);
    dispatch(
      viewerActions.updateExpanded({
        studyId: globalStudy!.id,
        keys: keys ?? [],
      }),
    );
  };

  return {
    expandedGroups: filteredExpandedGroups,
    selectedKeys,
    onDragStart,
    onSelect,
    onExpand,
  };
};

export const useStoreAggregation = (draggable?: boolean) => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch);

  const {
    rccGroups: rccGroupsTables = storeTablesFallback,
    internal: internalTables = storeTablesFallback,
    external: externalTables = storeTablesFallback,
    studyRT: studyRTTables = storeTablesFallback,
    crossStudyRT: crossStudyRTTables = storeTablesFallback,
    globalDS: globalDSTables = storeTablesFallback,
    globalStackDS: globalStackDSTables = storeTablesFallback,
    globalOperational: globalOperationalTables = storeTablesFallback,
  } = useSelector(selectViewerGroupsMemo(globalStudy!.id));
  const studyActiveRole = useSelector(selectStudyActiveUserRole);

  const tablesQuery = useTablesQuery({
    study_id: globalStudy!.id,
    source_env: appliedENVSwitch,
    role_id: studyActiveRole?.role_id,
  });

  useEffect(() => {
    if (externalTables?.length && !tablesQuery.currentData) {
      return;
    }

    const externalData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.external) ??
      Object.values(DEFAULT_EXTERNAL_STORES)
    ).map(({ name, tables }) => ({
      name,
      tables: tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
      id: DEFAULT_EXTERNAL_STORES[name as EXTERNAL_STORES].id ?? 0,
    }));
    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.external,
        data: externalData,
      }),
    );

    if (!tablesQuery.currentData || !fallbackCHDB) {
      return;
    }

    const groupsData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.rccGroups) ?? []
    ).map((group, index) => ({
      id: -index - 100, // to not conflict with data stores and default data stores
      name: group.name!,
      tables: group.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));
    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.rccGroups,
        data: groupsData,
      }),
    );

    const internalData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.internal) ?? []
    ).map((store) => ({
      id: store.id!,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.internal,
        data: internalData,
      }),
    );

    const studyRTData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.studyRT) ?? []
    ).map((store) => ({
      id: store.id!,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.studyRT,
        data: studyRTData,
      }),
    );

    const crossStudyRTData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.crossStudyRT) ?? []
    ).map((store) => ({
      id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.crossStudyRT,
        data: crossStudyRTData,
      }),
    );

    const globalDSData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.globalDS) ?? []
    ).map((store) => ({
      id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.globalDS,
        data: globalDSData,
      }),
    );

    const globalStackDSData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.globalStackDS) ?? []
    ).map((store) => ({
      id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.globalStackDS,
        data: globalStackDSData,
      }),
    );

    const globalOperationalData: ViewerGroup[] = (
      tablesQuery.currentData?.filter((store) => store.type === ViewerGroupType.globalOperational) ?? []
    ).map((store) => ({
      id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
      name: store.name,
      tables: store.tables.map((tableName) => ({
        id: tableName,
        name: getTableNameWithSchema(tableName).name || tableName,
      })),
    }));

    dispatch(
      viewerActions.setGroup({
        studyId: globalStudy!.id,
        group: ViewerGroupType.globalOperational,
        data: globalOperationalData,
      }),
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalTables?.length, tablesQuery.currentData, globalStudy?.id]);

  const treeData: NodeData[] = useMemo(() => {
    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        id: store.id,
        name: store.name,
        isBranch: true,
        metadata: {
          groupType,
        },
        children: (store.tables || []).map((table) => ({
          id: `${store.id}:${table.id}`,
          name: table.name,
          metadata: {
            originalId: table.id,
          },
        })),
      }));

    return [
      ...getTreeData(externalTables, ViewerGroupType.external),
      ...getTreeData(studyRTTables, ViewerGroupType.studyRT),
      ...getTreeData(crossStudyRTTables, ViewerGroupType.crossStudyRT),
      ...getTreeData(globalDSTables, ViewerGroupType.globalDS),
      ...getTreeData(globalStackDSTables, ViewerGroupType.globalStackDS),
      ...getTreeData(globalOperationalTables, ViewerGroupType.globalOperational),
      ...getTreeData(internalTables, ViewerGroupType.internal),
      ...getTreeData(rccGroupsTables, ViewerGroupType.rccGroups),
    ];
  }, [
    draggable,
    rccGroupsTables,
    internalTables,
    externalTables,
    studyRTTables,
    crossStudyRTTables,
    globalDSTables,
    globalStackDSTables,
  ]);

  return { treeData, tablesQuery, globalStudy };
};

export const useCrossStoreAggregation = (draggable?: boolean) => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const {
    crossStudyRT: crossStudyRTTables = storeTablesFallback,
    globalDS: globalDSTables = storeTablesFallback,
    globalStackDS: globalStackDSTables = storeTablesFallback,
    globalOperational: globalOperationalTables = storeTablesFallback,
  } = useSelector(selectViewerGroupsMemo(globalStudy!.id));
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch);

  const globalTablesQuery = useGlobalTablesQuery({
    study_id: globalStudy!.id,
    source_env: appliedENVSwitch,
  });

  // Set Cross Study Reference Tables
  useEffect(() => {
    if (!globalTablesQuery.currentData) {
      return;
    }

    globalTablesQuery.currentData.forEach((store) => {
      dispatch(
        viewerActions.setGroup({
          studyId: globalStudy!.id,
          group: store.type,
          data: [
            {
              id: DEFAULT_EXTERNAL_STORES[store.name as EXTERNAL_STORES].id ?? 0,
              name: store.name,
              tables: store.tables.map((tableName) => ({
                id: tableName,
                name: getTableNameWithSchema(tableName).name || tableName,
              })),
            },
          ],
        }),
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStudy!.id, globalTablesQuery.currentData]);

  const treeData: NodeData[] = useMemo(() => {
    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        id: store.id,
        name: store.name,
        isBranch: true,
        metadata: {
          groupType,
        },
        children: (store.tables || []).map((table) => ({
          id: `${store.id}:${table.id}`,
          name: table.name,
          metadata: {
            originalId: table.id,
          },
        })),
      }));

    return [
      ...getTreeData(crossStudyRTTables, ViewerGroupType.crossStudyRT),
      ...getTreeData(globalDSTables, ViewerGroupType.globalDS),
      ...getTreeData(globalStackDSTables, ViewerGroupType.globalStackDS),
      ...getTreeData(globalOperationalTables, ViewerGroupType.globalOperational),
    ];
  }, [crossStudyRTTables, globalDSTables, globalStackDSTables, globalOperationalTables]);

  return { treeData, globalTablesQuery, globalStudy };
};

export interface IMetadata extends IFlatMetadata {
  groupType?: ViewerGroupType;
  originalId?: string;
}

export interface NodeData {
  id: NodeId;
  name: string;
  children?: NodeData[];
  isBranch?: boolean;
  metadata?: IMetadata;
}

interface IUseTaleListSiderProps {
  onSelectTable: (tableKey: string, tableName: string) => void;
  selectedTable?: string | null;
  defaultTableName?: string | null;
  treeData: NodeData[];
  globalStudy: IAppStudyContext | null;
}
