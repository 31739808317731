import { Typography } from '@ui';

export const parseCodeLabFilePath = (filePath: string) => {
  return filePath.replace(/^(\d+_[A-Za-z]+)\//, '');
};

export const CodeLabFilePath = ({ filePath }: ICodeLabFilePathProps) => {
  return <Typography.Text>{parseCodeLabFilePath(filePath)}</Typography.Text>;
};

interface ICodeLabFilePathProps {
  filePath: string;
}
