import { useColumnSearch } from '@components/ui/table/tableHooks';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILibraryImportSqlQuery } from '../components/LibraryImportSqlQuery';

export const useSqlQueryColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ILibraryImportSqlQuery>();
  const { t } = useTranslation(['librarySqlQuery']);

  const sqlQueryColumns: TableColumnsType<ILibraryImportSqlQuery> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: t('rootTable.description'),
        dataIndex: 'description',
      },
      {
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { sqlQueryColumns, locale };
};
