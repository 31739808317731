import { getMenuItem, LayoutSider, Menu } from '@ui';
import { SIDEBAR_MENU_LOGO_CONTAINER_HEIGHT, SIDEBAR_MENU_WIDTH } from '@config/constants';
import { appMenu } from '@routes/appMenu';
import routes from '@routes';
import { CustomRouteMatch } from '@routes/RoutesTypes';
import { selectAppENV } from '@app/duck/appSelectors';
import { useAppPermissions, useFeatures } from '@modules/user/duck/userHooks';
import { RccLogo } from '@components/icons';
import { appStudyLibraryMenu, libraryRoutes } from '@routes/library';
import { FooterMenu } from '@app/components/Sider/FooterMenu';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { Link, useMatches } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2';

export const AppSider = () => {
  const theme = useTheme();
  const appEnv = useSelector(selectAppENV);
  const matches = useMatches();
  const match = matches.at(-1) as CustomRouteMatch;
  let appLink = routes.app.root.resolver;
  const { appPermissions } = useAppPermissions();
  const features = useFeatures();
  const appMenuItems = appMenu(appPermissions);

  if (features.hasGL && appPermissions.canGlLibraryRead) {
    appMenuItems.splice(2, 0, ...appStudyLibraryMenu());
  }

  const selectedKeys = [match.id];

  if (match.handle?.parentId) {
    selectedKeys.push(match.handle?.parentId);
  }

  if (matches.find((match) => match.pathname === routes.study.root.path)) {
    selectedKeys.push(routes.app.root.id);
  }

  if (matches.find((match) => match.pathname === libraryRoutes.root.path)) {
    selectedKeys.push(libraryRoutes.root.id);
  }

  return (
    <LayoutSider css={cssSider} width={SIDEBAR_MENU_WIDTH}>
      <div css={cssRccLogo}>
        <Link css={cssRccLogoLink} to={appLink()}>
          <RccLogo />
        </Link>
      </div>

      <div css={cssMenuContainer}>
        <Scrollbars
          autoHide={false}
          autoHeight
          autoHeightMin={'calc(100vh - 170px)'}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: 8,
                backgroundColor: theme['color-grey-700'],
                borderRadius: 4,
              }}
            />
          )}
        >
          <Menu
            css={cssMenu}
            theme="dark"
            selectedKeys={selectedKeys}
            items={appMenuItems.map((menu) =>
              getMenuItem({
                label: menu?.path && (
                  <Link to={menu.path} children={menu.title} target={menu.path.startsWith('http') ? '_blank' : ''} />
                ),
                key: menu.key,
                icon: <div css={cssMenuIcon(selectedKeys.includes(menu.key), theme)}>{menu.icon}</div>,
                style: menu.style,
                type: menu.type,
              }),
            )}
          />
        </Scrollbars>
        <FooterMenu appEnv={appEnv} />
      </div>
    </LayoutSider>
  );
};

const cssMenuContainer = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: 'red',
  height: `calc(100vh - ${SIDEBAR_MENU_LOGO_CONTAINER_HEIGHT + 16}px)`,
});

const cssSider = (theme: Theme) => ({
  '& .ant-layout-sider-children': {
    textAlign: 'center' as 'center',
    fontSize: 24,
    margin: 0,
    padding: 0,
  },
  '&&': {
    backgroundColor: theme.colorBgLayoutSecondary,
    color: theme['color-grey-300'],
    fontSize: '24px',
  },
});

const cssMenuIcon = (selectedKey: any, theme: Theme): CSSObject => ({
  marginBottom: 4,
  borderRadius: 4,
  padding: '4px 17px',
  backgroundColor: selectedKey ? theme['color-brand-grey'] : 'transparent',
});

const cssMenu = (theme: Theme): CSSObject => ({
  '&& .ant-menu-item-selected': {
    backgroundColor: 'transparent',
  },
  '& .ant-menu-item-divider': {
    backgroundColor: theme['color-white'],
    width: 32,
    display: 'inline-flex',
    margin: `${theme.marginXS}px 0 ${theme.margin}px`,
  },
  '& .ant-menu-item': {
    margin: 0,
    marginBottom: 8,
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '8px 12px 12px',
    color: theme['color-grey-300'],
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    fontFamily: 'Rubik',
    textDecoration: 'none',
  },
  '&& .ant-menu-item-icon': {
    fontSize: 24,
  },
  '&&& .ant-menu-title-content': {
    marginInlineStart: 0,
    display: 'inline-block',
    whiteSpace: 'break-spaces',
  },
  '&&': {
    marginTop: theme.padding,
    backgroundColor: theme.colorBgLayoutSecondary,
    '& .ant-menu-item:not(.ant-menu-item-selected):active': {
      backgroundColor: 'transparent',
    },
  },
});

const cssRccLogo = (theme: Theme): CSSObject => ({
  height: SIDEBAR_MENU_LOGO_CONTAINER_HEIGHT,
  borderBottom: `1px solid ${theme['color-brand-grey']}`,
  opacity: '60%',
  cursor: 'pointer',
  fontSize: 42,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
});

const cssRccLogoLink = () => ({
  height: 42,
});
