import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAnalysisObjectModalsPushModalPayload, TAnalysisObjectModalsState } from '../AnalysisObjectTypes';

const initialState: AnalysisObjectStore = {
  modals: { selectedModal: null },
};

export const analysisObject = createSlice({
  name: 'analysisObject',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TAnalysisObjectModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const analysisObjectActions = analysisObject.actions;

export const analysisObjectReducer = analysisObject.reducer;

interface AnalysisObjectStore {
  modals: TAnalysisObjectModalsState;
}
