import { AppToolbarPortal } from '@app/components/Header/AppToolbarPortal';
import { Loader, QueryError } from '@components';
import { useStudyAppListener } from '@modules/study/duck/studyHooks';
import { DatasetModalsController } from '@modules/dataset/modals';
import { CSSObject } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { StudyRoleSelector } from './components/StudyRoleSelector';
import { StudyMenu } from './components';

export const StudyAppRouterProvider = () => {
  const { queryError, studyAppPreloaded, isCrossStudy } = useStudyAppListener();

  if (queryError) {
    return <QueryError error={{ status: 404, data: { userMsg: 'Study Not Found' } }} title="Study Not Found" />;
  }

  return (
    <div css={cssBody}>
      {<StudyMenu isCrossStudy={isCrossStudy} />}
      <div css={cssContent}>
        {!isCrossStudy && (
          <AppToolbarPortal>
            <StudyRoleSelector />
          </AppToolbarPortal>
        )}
        {!studyAppPreloaded ? <Loader mode="fixed" size="large" /> : <Outlet />}
        <DatasetModalsController />
      </div>
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'auto',
});
