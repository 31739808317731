import { BaseStage } from '@modules/modelEditor/components/builder/stages';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorModalsType } from '@modules/modelEditor/modals';
import { getStageData } from '@modules/modelEditor/duck/modelEditorUtils';
import { DEFAULT_STAGE_HEIGHT } from '@modules/modelEditor/components/builder/constants';
import { NodeProps } from 'reactflow';
import { CSSObject } from '@emotion/react';
import { useDispatch } from 'react-redux';

export const TransformStage = (props: NodeProps) => {
  const dispatch = useDispatch();

  const onSettingsHandler = () => {
    dispatch(
      modelEditorActions.pushModal({
        type: ModelEditorModalsType.schemaSettings,
        data: {
          nodeId: props.id,
          initData: getStageData(props),
        },
      }),
    );
  };

  const actions = {
    onSettings: onSettingsHandler,
  };

  return <BaseStage {...props} customStyle={cssLayout} actions={actions} showLabel={false} disableDataViewer />;
};

const cssLayout = (): CSSObject => ({
  borderRadius: '50%',
  width: DEFAULT_STAGE_HEIGHT,
});
