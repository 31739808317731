import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const StudySwitcherIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} viewBox="0 0 28.6 28.6">
    <path
      fill="#F9EA71"
      d="M7.1,2.9h15c1.6,0,2.9,1.3,2.9,2.9v17.9c0,1.6-1.3,2.9-2.9,2.9h-15c-1.6,0-2.9-1.3-2.9-2.9V5.7
	C4.3,4.1,5.6,2.9,7.1,2.9z"
    />
    <path
      fill="#F96694"
      d="M2.9,0h8.6c1.6,0,2.9,1.3,2.9,2.9v8.6c0,1.6-1.3,2.9-2.9,2.9H2.9C1.3,14.3,0,13,0,11.4V2.9C0,1.3,1.3,0,2.9,0z"
    />
    <path
      fill="#F9B548"
      d="M2.9,14.3h8.6c1.6,0,2.9,1.3,2.9,2.9v8.6c0,1.6-1.3,2.9-2.9,2.9H2.9c-1.6,0-2.9-1.3-2.9-2.9v-8.6
	C0,15.6,1.3,14.3,2.9,14.3z"
    />
    <path
      fill="#0BB4F3"
      d="M17.1,0h8.6c1.6,0,2.9,1.3,2.9,2.9v8.6c0,1.6-1.3,2.9-2.9,2.9h-8.6c-1.6,0-2.9-1.3-2.9-2.9V2.9
	C14.3,1.3,15.6,0,17.1,0z"
    />
    <path
      fill="#0CC1B8"
      d="M17.1,14.3h8.6c1.6,0,2.9,1.3,2.9,2.9v8.6c0,1.6-1.3,2.9-2.9,2.9h-8.6c-1.6,0-2.9-1.3-2.9-2.9v-8.6
	C14.3,15.6,15.6,14.3,17.1,14.3z"
    />
    <path
      fill="#0BD1E3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9,14.4h-8.2c-0.8,0-1.4-0.6-1.4-1.4V5c0.4,0,0.8,0.1,1.1,0.4l8.1,8.1C23.7,13.8,23.9,14.1,23.9,14.4z"
    />
    <path
      fill="#B695DB"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7,14.4h8.2c0.8,0,1.4-0.6,1.4-1.4V5c-0.4,0-0.8,0.1-1.1,0.4l-8.1,8.1C4.8,13.8,4.7,14.1,4.7,14.4z"
    />
    <path
      fill="#7C8ED4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9,14.3h-8.2c-0.8,0-1.4,0.6-1.4,1.4v8c0.4,0,0.8-0.1,1.1-0.4l8.1-8.1C23.7,14.9,23.9,14.6,23.9,14.3z"
    />
    <path
      fill="#D57858"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7,14.3h8.2c0.8,0,1.4,0.6,1.4,1.4v8c-0.4,0-0.8-0.1-1.1-0.4l-8.1-8.1C4.8,14.9,4.7,14.6,4.7,14.3z"
    />
  </BaseIcon>
));
