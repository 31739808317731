import { useModelListPaginatedQuery } from '@modules/model/duck/modelApi';
import { ModelModalsController, ModelModalsType } from '@modules/model/modals';
import { useModelRunStatusListener } from '@modules/model/duck/modelHooks';
import { initialPage, isCrossStudy } from '@shared/utils/common';
import { useStoreListQuery } from '@modules/stores/duck/storeApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { PageSkeleton, PageTemplateSimple, QueryError } from '@components';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { Model } from '@modules/model/ModelTypes';
import { ModelList } from '@modules/model/components';
import { modelActions } from '@modules/model/duck/modelSlice';
import { Button, Select, Space } from '@ui';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useAppPermissions, useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { ELibraryModelModalsType, LibraryModelModalsController } from '@modules/library/model/modals';
import { libraryModelActions } from '@modules/library/model/duck/libraryModelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { selectSelectedModelEnv } from '../duck/modelSelectors';

export const ModelRootPage = () => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const { hasGL } = useFeatures();
  const { t } = useTranslation(['model']);
  const { statuses, isLoading: isLoadingStatuses } = useModelRunStatusListener();
  const { userCurrentEnv, envOptions, userEnvs, getLableSource } = useEnvironments(true);
  const {
    userPermissions: { canDataModelInsert },
  } = useStudyPermissions();
  const {
    appPermissions: { canCrossDataModelInsert },
  } = useAppPermissions();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<Model>(initialPage);

  const [models, setModels] = useState<Model[]>([]);

  const modelEnv = useSelector(selectSelectedModelEnv) || userCurrentEnv;
  const isCurrentModelEnv = userCurrentEnv === modelEnv;

  const modelQuery = useModelListPaginatedQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
    ...(!isCurrentModelEnv ? { source_env: modelEnv } : {}),
  });

  useEffect(() => {
    if (modelQuery.data?.items) {
      setModels(modelQuery.data.items);
    }
  }, [modelQuery.data?.items]);

  useEffect(() => {
    if (statuses?.length) {
      const finishedStatuses = statuses?.filter((item) => item.finished_at);
      if (finishedStatuses.length) {
        const updatedData =
          modelQuery.data?.items.map((el) => {
            const findFinishStatus = finishedStatuses.find((item) => item.name === el.name);
            if (findFinishStatus) {
              return {
                ...el,
                spark_job: {
                  error: findFinishStatus.error,
                  added_at: findFinishStatus.added_at,
                  started_at: findFinishStatus.started_at,
                  finished_at: findFinishStatus.finished_at,
                  failed_logs: findFinishStatus.failed_logs,
                },
              };
            }

            return el;
          }) || [];

        setModels(updatedData);
      }
    }
  }, [statuses, modelQuery.data?.items]);

  const storeQuery = useStoreListQuery({ detailed: 0, ...(!isCurrentModelEnv ? { source_env: modelEnv } : {}) });

  const onImport = async (values: any) => {
    dispatch(
      libraryModelActions.pushModal({
        type: ELibraryModelModalsType.copyModel,
        data: { importToStudyId: globalStudy?.id },
      }),
    );
  };

  const pagination = getPagination(modelQuery.data?.totalItems);

  const addModelModal = () => dispatch(modelActions.pushModal({ type: ModelModalsType.saveModel }));

  const onChangeModelEnvironment = (value: string) => dispatch(modelActions.setSelectedModelEnv(value));

  const isLoading = modelQuery.isFetching && !modelQuery.data;

  const isHasSeveralEnv = hasGL && userEnvs && Object.keys(userEnvs)?.length > 1;

  return (
    <PageTemplateSimple
      title={{
        children: (
          <Space>
            {t('pageRootName')}
            {isHasSeveralEnv && (
              <Select
                css={cssDataModeEnv}
                options={envOptions}
                defaultValue={modelEnv}
                onChange={onChangeModelEnvironment}
              />
            )}
          </Space>
        ),
        pageTitle: t('pageRootName'),
        extra: isCurrentModelEnv && !modelQuery.error && (
          <Space>
            {(canDataModelInsert || (canCrossDataModelInsert && isCrossStudy(globalStudy?.id))) && (
              <Button children={t('import')} onClick={onImport} />
            )}
            {(canDataModelInsert || (canCrossDataModelInsert && isCrossStudy(globalStudy?.id))) && (
              <Button children={t('add')} onClick={addModelModal} />
            )}
          </Space>
        ),
      }}
    >
      {isLoading && <PageSkeleton hideTitle />}
      {!isLoading && modelQuery.error && <QueryError error={modelQuery.error} title={t('loadingError')} />}
      {modelQuery.data && (
        <ModelList
          data={models || []}
          storeList={storeQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={modelQuery.isFetching}
          isCurrentModelEnv={isCurrentModelEnv}
          modelEnvLabel={getLableSource(modelEnv || userCurrentEnv)}
          modelEnv={modelEnv}
          t={t}
          statuses={statuses}
          statusesLoading={isLoadingStatuses}
        />
      )}
      <LibraryModelModalsController />
      <ModelModalsController />
    </PageTemplateSimple>
  );
};

const cssDataModeEnv = () => ({
  width: 130,
});
