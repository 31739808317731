import { ILibraryObjectModalImportCodeLabProps } from './modals/LibraryObjectModalImportCodeLab';
import { ILibraryObjectModalImportSqlLabProps } from './modals/LibraryObjectModalImportSqlLab';

export enum ELibraryObjectModalsType {
  'copySqlLab' = 'copySqlLab',
  'importCodeLab' = 'importCodeLab',
}

export type TLibrarySqlLabModalsPushModalPayload =
  | {
      type: ELibraryObjectModalsType.copySqlLab;
      data?: ILibraryObjectModalImportSqlLabProps['data'];
    }
  | {
      type: ELibraryObjectModalsType.importCodeLab;
      data?: ILibraryObjectModalImportCodeLabProps['data'];
    };

export type TLibrarySqlLabModalsState = {
  selectedModal: ELibraryObjectModalsType | null;
  data?: ILibraryObjectModalImportSqlLabProps['data'] | ILibraryObjectModalImportCodeLabProps['data'];
};
