import { Badge, Button, DraggableModal, Space, Steps, Typography } from '@ui';
import {
  SelectStep,
  ConfigureStep,
  UploadingStep,
  UploadStepMinimized,
} from '@modules/job/modals/components/upload/steps';
import { Job, TabProps } from '@modules/job/JobTypes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import {
  useUploadFileMutation,
  useProcessJobMutation,
  useDiscoveryJobMutation,
  DiscoveryJobParams,
  ProcessJobParams,
  ProcessParams,
} from '@modules/job/duck/jobApi';

import {
  useCrossStudyRTListQuery,
  useLazyReferenceTableInfoQuery,
  useStudyRTListQuery,
} from '@modules/viewer/duck/viewerApi';
import {
  generatedMappingTypes,
  getMapping,
  isAutoConfirmTab,
} from '@modules/job/modals/components/upload/duck/uploadUtils';
import { ModalDivider } from '@components';
import { useJobModalUploadContext } from '@modules/job/modals/components/upload/JobModalProvider';
import { initialMapping } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { isCrossStudy } from '@shared/utils/common';
import { INITIAL_PROGRESS } from '@modules/job/duck/constants';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { applySchemaToTable } from '@shared/utils/Viewer';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { getStudyPathName } from '@routes/utils';
import routes from '@routes';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { MandatoryColumns } from '@config/constants';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { UploadFile } from 'antd/lib/upload';
import { CSSObject, Theme, useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { StepProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowsAltOutlined, MinusOutlined } from '@ant-design/icons';

const JobModalsUploadJobContent = ({
  data,
  onClose,
  onCancel,
  cancelled,
  t,
  isMinimized,
}: JobModalsUploadJobContentProps) => {
  const { setModalTitle, setStepNumber, setIsFileProcessingFinished, isFileProcessingFinished, setModalStudyName } =
    useJobModalUploadContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const globalStudy = useSelector(selectGlobalStudy);
  const globalStudyId = globalStudy?.id!;
  const [studyId, setStudyId] = useState<number>(globalStudyId);
  const [fallbackCHDBModal, setFallbackCHDBModal] = useState<string>(fallbackCHDB);
  const skipApiCallRT = studyId === undefined || !isFileProcessingFinished;
  const skipRTForNoCrossStudy = skipApiCallRT || !isCrossStudy(studyId);
  const skipRTForNoStudy = skipApiCallRT || isCrossStudy(studyId);
  const referenceTables = useCrossStudyRTListQuery(
    { studyId },
    {
      skip: skipRTForNoCrossStudy,
    },
  );
  const referenceTablesStudy = useStudyRTListQuery(
    { studyId },
    {
      skip: skipRTForNoStudy,
    },
  );

  const {
    userPermissions: { canImportJobEditStructure },
  } = useStudyPermissions();
  const [refTableInfo, refTableInfoResult] = useLazyReferenceTableInfoQuery();

  const [uploadFile, uploadFileResult] = useUploadFileMutation();
  const [discoveryJob, discoveryJobResult] = useDiscoveryJobMutation();
  const [processJob, processJobResult] = useProcessJobMutation();

  const [current, setCurrent] = useState(0);
  const [progressUpload, setProgressUpload] = useState<Progress>(INITIAL_PROGRESS);
  const [progress, setProgress] = useState<Progress>(INITIAL_PROGRESS);
  const [isSystemStore, setIsSystemStore] = useState(false);
  const [requests, setRequests] = useState<TRequest[]>([]);
  const [processParams, setProcessParams] = useState<ProcessParams>({
    filename: '',
    store_id: 0,
    ignore_errors: false,
    offset: 0,
    separator: ',',
  });
  const [tabsData, setTabsData] = useState<TabProps[]>([]);

  const [discoveryParams, setDiscoveryParams] = useState<DiscoveryJobParams | null>(null);
  const [configuredProcessParams, setConfiguredProcessParams] = useState<ProcessJobParams | null>(null);

  useEffect(() => {
    const onBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = true;
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  // if processing is not finished studyId shouldn't be overwritten
  useEffect(() => {
    if (isFileProcessingFinished && globalStudy) {
      setStudyId(globalStudyId);
      setModalStudyName(globalStudy?.name!);
      setFallbackCHDBModal(fallbackCHDB);
    }
  }, [globalStudy, isFileProcessingFinished, fallbackCHDB]);

  const isManualUpload = !(isCrossStudy(studyId) || isSystemStore);
  const canImport = isCrossStudy(globalStudyId) ? true : canImportJobEditStructure;

  useEffect(() => {
    const preparedData =
      discoveryJobResult.data?.map((tab) => {
        const isEmptyUploadedMapping = !tab.mapping?.length;
        // findTableDataRT need to process RT data when click on minimize and go through app
        const findTableDataRT = tabsData.find((el) => el.tableName === tab.name);
        const mapping = isManualUpload ? getMapping(tab.structure, tab.mapping) : findTableDataRT?.mapping || [];
        const structure = isManualUpload ? generatedMappingTypes(mapping) : tab.structure;
        const confirmed =
          isManualUpload && !canImportJobEditStructure && !isEmptyUploadedMapping
            ? isAutoConfirmTab({ ...tab, mapping })
            : initialMapping.confirmed;
        // TODO check after move permissions to the global scope (canImportJobEditStructure for cross study is undefined)
        const isHideConfirmBtn = isManualUpload && !canImport && !isEmptyUploadedMapping;
        const columnNames = mapping.map((column) => column.sourceColumn);

        return {
          ...initialMapping,
          tableName: tab.name,
          total_rows: tab.total_rows,
          total_columns: tab.total_columns,
          sample: tab.sample,
          isMappingExists: !isEmptyUploadedMapping,
          missingRequiredColumns: MandatoryColumns.filter((column) => !columnNames?.includes(column)),
          referenceTable: findTableDataRT?.referenceTable,
          mapping,
          structure,
          confirmed,
          isHideConfirmBtn,
        };
      }) ?? [];

    setTabsData(preparedData);
  }, [discoveryJobResult.data, isManualUpload, canImportJobEditStructure, canImport]);

  useEffect(() => {
    if (cancelled) {
      requests.forEach((request, index) => request.abort());
      onClose();
    }
  }, [cancelled, requests, onClose]);

  const updateTabPartially = (tab: string, val: Partial<TabProps>) => {
    setTabsData((prev: TabProps[]) => prev.map((el) => (el.tableName === tab ? { ...el, ...val } : el)));
  };

  const updateAllTabs = (updMapping: TabProps[]) => {
    //TODO check tab validation and concat with updateMappingData
    setTabsData(updMapping);
  };

  const registerRequest = (request: TRequest) => setRequests((prev) => [...prev, request]);

  const referenceTableData = useMemo(() => {
    if (isCrossStudy(studyId)) {
      return referenceTables?.data || [];
    }
    return referenceTablesStudy?.data?.map((table) => applySchemaToTable(table, fallbackCHDBModal)) || [];
  }, [fallbackCHDBModal, globalStudy?.id, referenceTables?.data, referenceTablesStudy?.data, studyId]);

  const referenceTableDataLoading = isCrossStudy(studyId)
    ? referenceTables?.isLoading
    : referenceTablesStudy?.isLoading;

  const isError =
    uploadFileResult.isError ||
    discoveryJobResult.isError ||
    processJobResult.isError ||
    referenceTables.isError ||
    refTableInfoResult.isError;

  const increaseProgress = (value: number = 0, estimated: number = -1) => {
    setProgress((prev: any) => ({ ...prev, progress: value, estimated }));
  };

  const runDiscoveryJob = async (params: DiscoveryJobParams) => {
    setProgress({ progress: 0 });
    const discoveryRequest = discoveryJob({ ...params, study_id: studyId });
    registerRequest(discoveryRequest);

    await discoveryRequest.unwrap();

    setCurrent(2);
    setProgress(null);
    setProgressUpload(null);
  };

  const restartDiscoveryJob = async () => {
    if (discoveryParams) {
      setIsFileProcessingFinished(false);
      await runDiscoveryJob(discoveryParams);
    }
  };

  const runProcessJob = async (params: ProcessJobParams) => {
    setProgress({ progress: 0 });
    const processRequest = processJob({ ...params, study_id: studyId });
    registerRequest(processRequest);
    const jobId = await processRequest.unwrap();

    if (jobId !== undefined) {
      navigate(
        routes[getStudyPathName(studyId)].jobs.view.resolver({
          jobId,
          studyId,
        }),
      );
    }
    setProgress(null);
    setIsFileProcessingFinished(true);
    onClose();
  };

  const restartProcessJob = async () => {
    if (configuredProcessParams) {
      setIsFileProcessingFinished(false);
      await runProcessJob(configuredProcessParams);
    }
  };

  const steps = [
    {
      key: 'selectStep',
      title: t('uploadRT.selectStep'),
      content: (
        <SelectStep
          t={t}
          data={data}
          setCurrent={setCurrent}
          onClose={onClose}
          onCancel={onCancel}
          uploadFile={uploadFile}
          setProgressUpload={setProgressUpload}
          setProcessParams={setProcessParams}
          increaseProgress={increaseProgress}
          setIsSystemStore={setIsSystemStore}
          studyId={studyId}
          registerRequest={registerRequest}
          runDiscoveryJob={runDiscoveryJob}
          setDiscoveryParams={setDiscoveryParams}
        />
      ),
    },
    {
      key: 'uploadingStep',
      title: t('uploadRT.uploadingStep'),
      content: (
        <UploadingStep
          t={t}
          progress={progress}
          progressUpload={progressUpload}
          uploadFileResult={uploadFileResult}
          discoveryJobResult={discoveryJobResult}
          onCancel={onCancel}
          restartDiscoveryJob={restartDiscoveryJob}
        />
      ),
    },
    {
      key: 'сonfigureStep',
      title: t('uploadRT.configureStep'),
      content: (
        <ConfigureStep
          t={t}
          tabs={tabsData ?? []}
          updateTabPartially={updateTabPartially}
          updateAllTabs={updateAllTabs}
          onCancel={onCancel}
          setCurrent={setCurrent}
          processParams={processParams}
          increaseProgress={increaseProgress}
          referenceTables={referenceTableData ?? []}
          referenceTablesLoading={referenceTableDataLoading}
          refTableInfo={refTableInfo}
          referenceTablesInfoLoading={refTableInfoResult.isLoading}
          isManualUpload={isManualUpload}
          canImportJobEditStructure={isCrossStudy(globalStudyId) ? true : canImportJobEditStructure}
          setConfiguredProcessParams={setConfiguredProcessParams}
          runProcessJob={runProcessJob}
          studyId={studyId}
        />
      ),
    },
    {
      key: 'processingStep',
      title: t('uploadRT.processingStep'),
      content: (
        <UploadingStep
          t={t}
          progress={progress}
          processJobResult={processJobResult}
          onCancel={onCancel}
          btnText={t('close')}
          restartProcessJob={restartProcessJob}
        />
      ),
    },
  ].filter((item) => item) as CustomStepProps[];

  const stepsMinimized = [
    {
      key: 'selectStep',
      title: t('uploadRT.selectStep'),
    },
    {
      key: 'uploadingStep',
      title: t('uploadRT.uploadingStep'),
      content: (
        <UploadStepMinimized
          t={t}
          progress={progress}
          uploadFileResult={uploadFileResult}
          progressUpload={progressUpload}
          discoveryJobResult={discoveryJobResult}
        />
      ),
    },
    {
      key: 'сonfigureStep',
      title: t('uploadRT.configureStep'),
    },
    {
      key: 'processingStep',
      title: t('uploadRT.processingStep'),
      content: <UploadStepMinimized t={t} progress={progress} processJobResult={processJobResult} />,
    },
  ].filter((item) => item) as CustomStepProps[];

  useEffect(() => {
    if (isMinimized) {
      setModalTitle(steps[current].title as string);
      setStepNumber(current + 1);
    } else {
      setModalTitle(t('uploadModal.title'));
    }
  }, [isMinimized, current, steps]);

  return (
    <div css={cssContentContainer()}>
      <Steps
        css={cssSteps(theme, isMinimized)}
        current={current}
        items={steps}
        percent={progress?.progress || progressUpload?.progress}
        status={isError ? 'error' : undefined}
        direction="horizontal"
      />
      <ModalDivider top={12} />
      <div css={cssStepContent(theme, isMinimized)}>{steps[current].content}</div>
      {stepsMinimized[current]?.content && (
        <div css={cssStepContentMinimized(isMinimized)}>{stepsMinimized[current].content}</div>
      )}
    </div>
  );
};

export const JobModalsUploadJobRT = ({
  open,
  data,
  onClose,
  isMinimized,
  handleToggleMinimize,
}: JobModalsUploadJobProps) => {
  const { modalTitle, stepNumber, setIsFileProcessingFinished, modalStudyName } = useJobModalUploadContext();
  const { t } = useTranslation(['job']);
  const [cancelled, setCancelled] = useState(false);

  const handleCancel = () => {
    setCancelled(true);
    setIsFileProcessingFinished(true);
  };

  const handleClose = () => {
    setCancelled(false);
    setIsFileProcessingFinished(true);
    onClose();
  };

  const renderTitle = () => (
    <>
      <Space>
        {!isMinimized ? (
          <>
            {modalTitle}
            <Typography.Text italic>(Study: "{modalStudyName}")</Typography.Text>
          </>
        ) : (
          <>
            <Badge css={cssMinimizeBadge} count={stepNumber} showZero color="#12161A" />
            {modalTitle}
          </>
        )}
      </Space>
      <Button
        css={cssMinimizeButton}
        type="link"
        title={isMinimized ? t('uploadModal.maximize') : t('uploadModal.minimize')}
        icon={isMinimized ? <ArrowsAltOutlined /> : <MinusOutlined />}
        onClick={handleToggleMinimize}
      />
    </>
  );

  return (
    <DraggableModal
      css={isMinimized ? cssMinimizedModal : cssModal}
      width="60%"
      open={open}
      onCancel={handleCancel}
      title={renderTitle()}
      footer={null}
      destroyOnClose
      isMinimized={isMinimized}
      wrapProps={{ style: { pointerEvents: 'none' } }}
      maskClosable={false}
    >
      {open && (
        <JobModalsUploadJobContent
          data={data}
          onClose={handleClose}
          onCancel={handleCancel}
          cancelled={cancelled}
          t={t}
          isMinimized={isMinimized}
        />
      )}
    </DraggableModal>
  );
};

const cssSteps = (theme: Theme, isMinimized?: boolean): CSSObject => ({
  flexDirection: 'row',
  display: isMinimized ? 'none' : 'flex',

  '&&': {
    '.ant-steps-item-wait .ant-steps-item-icon': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme['color-grey-900']}`,
      '.ant-steps-icon': {
        color: theme['color-grey-900'],
      },
    },

    '.ant-steps-item-finish .ant-steps-item-icon': {
      backgroundColor: theme['color-green-icon-light-bg'],
      borderColor: theme['color-green-icon-light-bg'],
      '.ant-steps-icon': {
        color: theme['color-white'],
      },
    },
  },
});

const cssMinimizeBadge = (): CSSObject => ({
  '& .ant-scroll-number': {
    width: 28,
    height: 28,
    borderRadius: '100px',
  },
  '& .ant-badge-count': {
    fontSize: '18px',
    lineHeight: '27px',
  },
});

const cssMinimizeButton = (): CSSObject => ({
  position: 'absolute',
  top: '24px',
  insetInlineEnd: '60px',
  zIndex: 1010,
  color: 'rgba(0, 0, 0, 0.45)',
  fontWeight: 600,
  lineHeight: 1,
  textDecoration: 'none',
  background: 'transparent',
  borderRadius: '4px',
  width: '40px',
  height: '40px',
  border: 0,
  outline: 0,
  cursor: 'pointer',
  transition: 'color 0.2s',
  backgroundColor: '0.2s',
  padding: 0,
});

const cssModal = (): CSSObject => ({
  top: 20,
  maxHeight: `calc(100vh - 40px)`,
  pointerEvents: 'none',
  height: 850,

  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 800,
    minHeight: 590,
  },
  '& .ant-form': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.ant-modal-body': {
    flex: 1,
  },
});

const cssMinimizedModal = (): CSSObject => ({
  top: 'auto',
  bottom: '54px',
  right: '32px',
  maxWidth: '280px',
  position: 'absolute',
  paddingBottom: 0,
  '& .ant-modal-header': {
    marginBottom: 0,
  },
  '& .ant-modal-body': {
    marginTop: 8,
  },
});

const cssContentContainer = (isMinimized?: boolean): CSSObject => ({
  display: isMinimized ? 'none' : 'flex',
  flexDirection: 'column',
  height: '100%',
});

const cssStepContent = (theme: Theme, isMinimized?: boolean): CSSObject => ({
  borderRadius: theme.borderRadiusLG,
  marginTop: 16,
  padding: 8,
  height: '100%',
  display: isMinimized ? 'none' : 'block',
});

const cssStepContentMinimized = (isMinimized?: boolean): CSSObject => ({
  display: isMinimized ? 'block' : 'none',
});

export type TRequest = ReturnType<MutationTrigger<any>>;

export interface JobModalsUploadJobProps {
  open: boolean;
  data: Partial<Job> & { studyId: number; studyName: string };
  onClose: () => void;
  isMinimized?: boolean;
  handleToggleMinimize: () => void;
}

export type Progress = Record<string, any> | null;
export interface JobUploadFormValues {
  skipBlankRows: boolean;
  skipHashOfRows: number;
  ignoreErrors: boolean;
  importToStaging: boolean;
  // timeFormat: 'basic' | 'best_effort';
  separator: string;
  store_id: number;
  file: UploadFile[];
}

export interface JobModalsUploadJobContentProps extends Pick<JobModalsUploadJobProps, 'data' | 'onClose'> {
  onCancel: () => void;
  cancelled: boolean;
  t: TFunction;
  isMinimized?: boolean;
}

interface CustomStepProps extends StepProps {
  content: ReactNode;
  contentMinimized?: ReactNode;
}
