import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const CheckFilled: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={100} height={100} fill="none" viewBox="0 0 100 100">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.1657e-07 50C3.1657e-07 40.1109 2.93245 30.444 8.42652 22.2215C13.9206 13.9991 21.7295 7.59043 30.8658 3.80605C40.0021 0.0216644 50.0555 -0.968502 59.7545 0.960758C69.4536 2.89002 78.3627 7.65206 85.3553 14.6447C92.348 21.6373 97.11 30.5465 99.0392 40.2455C100.969 49.9445 99.9783 59.9979 96.194 69.1342C92.4096 78.2705 86.001 86.0794 77.7785 91.5735C69.556 97.0676 59.8891 100 50 100C43.4337 100.001 36.9316 98.708 30.8649 96.1955C24.7983 93.683 19.2861 90.0001 14.643 85.357C9.99994 80.7139 6.31699 75.2017 3.80452 69.1351C1.29205 63.0684 -0.000738591 56.5663 3.1657e-07 50ZM68.5081 40.6953C68.8256 40.2861 69.0571 39.8169 69.1889 39.316C69.3207 38.8151 69.35 38.2927 69.2751 37.7802C69.2001 37.2677 69.0225 36.7756 68.7528 36.3334C68.4831 35.8912 68.1269 35.5079 67.7056 35.2067C67.2842 34.9055 66.8064 34.6924 66.3007 34.5803C65.795 34.4681 65.2719 34.4593 64.7627 34.5542C64.2535 34.6491 63.7687 34.8458 63.3374 35.1326C62.906 35.4193 62.537 35.7902 62.2525 36.223L45.6571 59.451L37.3312 51.1251C36.5988 50.4655 35.6411 50.1121 34.6557 50.1379C33.6703 50.1637 32.7324 50.5666 32.0354 51.2636C31.3384 51.9606 30.9355 52.8985 30.9097 53.8839C30.8839 54.8693 31.2373 55.8269 31.8969 56.5594L43.435 68.0974C43.83 68.4914 44.306 68.7947 44.83 68.9864C45.354 69.178 45.9133 69.2535 46.4693 69.2075C47.0254 69.1614 47.5647 68.9951 48.05 68.7198C48.5353 68.4446 48.955 68.0672 49.2799 67.6136L68.5081 40.6897V40.6953Z"
      fill="#16A864"
    />
  </BaseIcon>
));
