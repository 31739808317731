import { SupportedEnvs } from '@app/AppTypes';
import { Col, Form, FormItem, FormLayout, Radio, Row, Select, Table } from '@components/ui';
import { useAuthUserEnvs } from '@modules/auth/duck/authHooks';
import { itemsToOptions } from '@shared/utils/Form';
import { useLazyEnvStudyListQuery, useLazyLibraryListQuery } from '@modules/library/root/duck/libraryApi';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { useColumnSearch, useTablePaginationState } from '@components/ui/table/tableHooks';
import { ELibrarySourceType, ESelectedActions, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { useAppContext } from '@app/AppContext';
import { QueryErrorType } from '@shared/utils/Error';
import { useLazyEnvSQLDatasetListQuery } from '@modules/dataset/duck/datasetApi';
import { useFeatures } from '@modules/user/duck/userHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useLazyLibraryObjectSqlLabListQuery } from '@modules/library/libraryObject/duck/libraryObjectApi';
import { isCrossStudy } from '@shared/utils/common';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { TableColumnsType } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { prepareSqlLabTableData } from '../duck/sqlLabUtils';
import { IPrepareSqlLabTableData, ISQLLabObject } from '../SQLLabObjectTypes';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const SQLLabObjectSelectForm = ({
  onClose,
  onSubmit,
  libraryStatuses,
  onlyCurrentEnv,
  hideCurrentStudy,
  hideOverwriteHandles,
}: ISQLLabObjectSelectFormProps) => {
  const { hasGL } = useFeatures();
  const { notificationApi } = useAppContext();
  const { t } = useTranslation(['dnaObject']);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<Record<string, TableData>>({});
  const [selectedTableItems, setSelectedTableItems] = useState<React.Key[]>([]);
  const [sourceInfo, setSourceInfo] = useState<{ kind: string; value: string }>({ kind: '', value: '' });
  const [sourceData, setSourceData] = useState<Record<string, ReturnType<typeof itemsToOptions>>>({});
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const user = useSelector(selectAuthUser);
  const { getColumnSearchProps, locale } = useColumnSearch<TableDataItem>();
  const { setPagination, getPagination } = useTablePaginationState(initialPage);
  const { userCurrentEnv, userCurrentEnvLabel, allUserEnvs } = useAuthUserEnvs();
  const [libraryListQuery, libraryListQueryData] = useLazyLibraryListQuery();
  const [libraryObjectSqlLabListQuery, libraryObjectSqlLabListQueryData] = useLazyLibraryObjectSqlLabListQuery();
  const [envStudyListQuery, studiesListQueryData] = useLazyEnvStudyListQuery();
  const [envStudySQLDatasetListQuery, studySQLDatasetListQueryData] = useLazyEnvSQLDatasetListQuery();

  const sourceType = useMemo(() => {
    const sourceOptions: Array<{ label: string; value: string }> = hasGL
      ? [
          {
            label: ELibrarySourceType.Library,
            value: ELibrarySourceType.Library,
          },
        ]
      : [];

    if (onlyCurrentEnv) {
      sourceOptions.push({ label: `Study (${userCurrentEnvLabel})`, value: userCurrentEnv } as any);
      return sourceOptions;
    }

    sourceOptions.push(
      ...Object.entries(allUserEnvs || ({} as SupportedEnvs)).map(([env, envData]) => {
        return { label: `Study (${envData!.label})`, value: env } as any;
      }),
    );

    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserEnvs, userCurrentEnv, userCurrentEnvLabel]);

  const initValues = {
    sourceKind: !hideCurrentStudy ? userCurrentEnv : sourceType.at(-1)?.value,
    sourceId: !hideCurrentStudy ? globalStudy?.id : undefined,
    overwrite: false,
  };

  const changeSource = async (value: string) => {
    setSelectedTableItems([]);
    setSourceInfo((prevValue) => ({ ...prevValue, kind: value }));
    if (value === ELibrarySourceType.Library) {
      const data = await libraryListQuery({
        status: libraryStatuses ? libraryStatuses.join(',') : undefined,
        page_size: 999,
      }).unwrap();
      const libraryListOption = itemsToOptions(data?.items?.filter((item) => item.id !== globalLibrary?.id));
      setSourceData((prevValue) => ({ ...prevValue, [value]: libraryListOption }));
    } else if (value) {
      if (isCrossStudy(globalStudy?.id)) {
        setSourceData((prevValue) => ({ ...prevValue, [value]: [{ value: 0, label: 'Global' }] }));
      } else {
        const data = await envStudyListQuery({ env: value }).unwrap();
        const studiesListOption = itemsToOptions(
          hideCurrentStudy && value === userCurrentEnv ? data?.filter((item) => item.id !== globalStudy?.id) : data,
        );
        setSourceData((prevValue) => ({
          ...prevValue,
          [value]: [...(globalLibrary?.id !== undefined ? [{ value: 0, label: 'Global' }] : []), ...studiesListOption],
        }));
      }
    }
  };

  const onChangeSourceInput = (value: string) => {
    form.setFieldValue('sourceId', undefined);
    setSourceInfo((prevValue) => ({ ...prevValue, value: '' }));
    changeSource(value);
  };

  const onChangeSourceIdInput = async (id: number) => {
    try {
      setSourceInfo((prevValue) => ({ ...prevValue, value: id.toString() }));
      setSelectedTableItems([]);
      const source = form.getFieldValue('sourceKind');
      if (source === ELibrarySourceType.Library) {
        const data = await libraryObjectSqlLabListQuery({ library_id: id, detailed: '1' }).unwrap();
        setTableData((prevValue) => ({
          ...prevValue,
          [id]: prepareSqlLabTableData({
            data: data.items!,
            studyId: globalStudy?.id!,
            sourceType: ELibrarySourceType.Library,
          }),
        }));
      } else if (source) {
        const data = await envStudySQLDatasetListQuery({ study_id: id, env: source }).unwrap();
        setTableData((prevValue) => ({
          ...prevValue,
          [id]: prepareSqlLabTableData({
            data,
            studyId: globalStudy?.id!,
            protocolId: globalStudy?.protocolId!,
            sourceType: ELibrarySourceType.Study,
          }),
        }));
      }
    } catch (e) {
      console.error('Error while changing source id', e);
    }
  };

  useEffect(() => {
    if (initValues.sourceKind) {
      changeSource(initValues.sourceKind);
    }
    if (initValues.sourceId !== undefined) {
      onChangeSourceIdInput(initValues.sourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = async (values: ISQLLabObjectSelectFormValues) => {
    try {
      let options: IOnSubmitOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if (values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) {
        const studyName = sourceData[values['sourceKind']].find((item) => item.value === values['sourceId'])?.label!;
        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            tenant_info: user!.tenant_info,
            env: values['sourceKind'],
            source: studyName,
          },
        };
      }

      await onSubmit(
        (tableData[values['sourceId']] as any)
          .filter((item: TableDataItem) => selectedTableItems.includes(item.id))
          .map((item: TableDataItem) => item.rawData),
        options,
      );
      notificationApi.success({ message: t('importForm.successMessage', { kind: ELibrarySourceType.Study }) });
      onClose();
    } catch (e) {
      if ((e as QueryErrorType)?.data?.userMsg) {
        notificationApi.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const columns = useMemo(
    () =>
      (
        [
          {
            title: t('sqlLab.rootTable.name'),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
          },
          (sourceInfo.kind || initValues.sourceKind) === ELibrarySourceType.Library && {
            title: t('sqlLab.rootTable.libraryVersion'),
            dataIndex: 'libraryVersion',
            render: (_, record) => record.libraryVersion || '-',
          },
        ] as TableColumnsType<TableDataItem>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceData],
  );

  const filteredTableData: TableData = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return (tableData[sourceInfo.value] as any)?.filter(
        (model: TableDataItem) => !selectedTableItems.includes(model.id),
      );
    }
    return tableData[sourceInfo.value];
  }, [selectedFilter, tableData, selectedTableItems, sourceInfo]);

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  const rowSelection: TableRowSelection<TableDataItem> = {
    onChange: (selectedRowKeys) => {
      setSelectedTableItems(selectedRowKeys);
    },
  };

  const pagination = getPagination(tableData[sourceInfo.value]?.length);

  const fetchingNames = libraryListQueryData.isFetching || studiesListQueryData.isFetching;

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmitForm}
      okText={t('import')}
      initialValues={initValues}
      submitIsDisabled={
        selectedTableItems.length === 0 ||
        fetchingNames ||
        studySQLDatasetListQueryData.isFetching ||
        libraryObjectSqlLabListQueryData.isFetching
      }
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={t('importForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('importForm.sourceKindPlaceholder')}
              options={sourceType}
              onChange={onChangeSourceInput}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('importForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('importForm.sourceIdPlaceholder')}
              options={sourceData[sourceInfo.kind]}
              loading={fetchingNames}
              onChange={onChangeSourceIdInput}
            />
          </FormItem>
        </Col>
      </Row>
      {!hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="overwrite"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              label={t('importForm.duplicateLabel.label')}
              css={cssHorizontalFormItem}
            >
              <Radio.Group>
                <Radio value={false}>{t('importForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('importForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="table" wrapperCol={{ span: 24 }}>
            <Table
              size="small"
              columns={columns}
              locale={locale}
              loading={studySQLDatasetListQueryData.isFetching || libraryObjectSqlLabListQueryData.isFetching}
              rowKey="id"
              tableLayout="fixed"
              dataSource={filteredTableData}
              pagination={pagination}
              onChange={onTableChange}
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: 'checkbox',
                ...rowSelection,
                selectedRowKeys: selectedTableItems,
                selections: [
                  Table.SELECTION_INVERT,
                  {
                    key: ESelectedActions.SHOW_ALL,
                    text: 'Show all',
                    onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
                  },
                  {
                    key: ESelectedActions.HIDE_SELECTED,
                    text: 'Hide selected',
                    onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
                  },
                ],
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssHorizontalFormItem = css({
  '&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&& .ant-form-item-label': {
    paddingBottom: 0,
  },
});

export interface ISQLLabObjectSelectFormProps {
  onClose: () => void;
  onSubmit: (data: IDataset[] | ISQLLabObject[], options: IOnSubmitOptions) => void;
  onlyCurrentEnv?: boolean;
  hideCurrentStudy?: boolean;
  hideOverwriteHandles?: boolean;
  libraryStatuses?: LibraryStatus[];
}

interface IOnSubmitOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
}

interface ISQLLabObjectSelectFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}

type TableData = IPrepareSqlLabTableData<IDataset> | IPrepareSqlLabTableData<ISQLLabObject>;
type TableDataItem = IPrepareSqlLabTableData<IDataset>[0] | IPrepareSqlLabTableData<ISQLLabObject>[0];
