import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useAppPermissions, useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { crossStudyMenu, MenuType, studyMenu } from '@routes/appMenu';
import { MainMenu } from '@components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StudySelector } from './StudySelector';

export const StudyMenu = ({ isCrossStudy }: IStudyMenuProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { userPermissions, userPermissionsReady } = useStudyPermissions();
  const { appPermissions } = useAppPermissions();
  const features = useFeatures();

  const menuItems = useMemo(() => {
    let items: MenuType = [];

    if (isCrossStudy) {
      return crossStudyMenu(appPermissions, features);
    }

    if (globalStudy?.id && userPermissionsReady) {
      items = studyMenu(globalStudy.id, userPermissions, features);
    }

    return items;
  }, [isCrossStudy, globalStudy?.id, userPermissionsReady, appPermissions, features, userPermissions]);

  return (
    <MainMenu items={menuItems} isLoading={isCrossStudy ? false : !globalStudy?.id || !userPermissionsReady}>
      {!isCrossStudy && <StudySelector />}
    </MainMenu>
  );
};

interface IStudyMenuProps {
  isCrossStudy?: boolean;
}
