import { DraggableModal, Form, FormItem, FormLayout, Input, Radio, Select, Skeleton } from '@ui';
import { QueryErrorType } from '@shared/utils/Error';
import { Scope } from '@modules/dataset/DatasetTypes';
import { useStudyListAllQuery } from '@modules/study/duck/studyApi';
import { useCreateStackDatasetMutation } from '@modules/dataset/duck/datasetApi';
import { useAppContext } from '@app/AppContext';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isArray } from 'lodash';

enum FieldFormScopeTypeValue {
  AllStudies = 'all-studies',
  Studies = 'studies',
}

const DatasetModalsEditStackDatasetContent = ({
  data,
  t,
  onClose,
}: IDatasetModalsEditStackDatasetContentContentProps) => {
  const [form] = Form.useForm();
  const studyListQuery = useStudyListAllQuery();
  const { notificationApi } = useAppContext();
  const [createStackDataset, createStackDatasetQuery] = useCreateStackDatasetMutation();

  const onSubmit = (values: IDatasetModalsEditStackDatasetFieldFormValues) => {
    createStackDataset({
      name: values.name,
      scope:
        values.scope_type === FieldFormScopeTypeValue.AllStudies
          ? 'tenant'
          : (values.studies as any).map((item: any) => item.toString()).join(','),
    }).then(({ error }: any) => {
      if (!error) {
        notificationApi.success({
          message: data?.id
            ? t('stackDatasetModal.notification.successUpdateMessage', { name: data?.name })
            : t('stackDatasetModal.notification.successCreateMessage', { name: data?.name }),
        });
        onClose();
      }
    });
  };

  const initValues = {
    name: data?.name,
    scope_type: data?.scope === 'tenant' ? FieldFormScopeTypeValue.AllStudies : FieldFormScopeTypeValue.Studies,
    studies: isArray(data?.scope) ? data?.scope.map((item: any) => item.id) : data?.scope,
  };

  const errors = createStackDatasetQuery.error
    ? [
        `${(createStackDatasetQuery.error as QueryErrorType).data.userMsg!}: ${
          (createStackDatasetQuery.error as QueryErrorType).data.devMsg ?? 'No details'
        }`,
      ]
    : undefined;

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      disabled={createStackDatasetQuery.isLoading}
      submitIsDisabled={createStackDatasetQuery.isLoading}
      cancelIsDisabled={createStackDatasetQuery.isLoading}
      errors={errors}
    >
      <FormItem name="name" label={t('stackDatasetModal.name')}>
        <Input disabled />
      </FormItem>
      <FormItem name="scope_type" label={t('stackDatasetModal.scopeType')}>
        <Radio.Group>
          <Radio value={FieldFormScopeTypeValue.AllStudies}>{t('stackDatasetModal.allStudies')}</Radio>
          <Radio value={FieldFormScopeTypeValue.Studies}>{t('stackDatasetModal.studies')}</Radio>
        </Radio.Group>
      </FormItem>
      <FormItem dependencies={['scope_type']} asInfo wrapperCol={{ span: 24 }}>
        {(form) => {
          if (form.getFieldValue('scope_type') === FieldFormScopeTypeValue.Studies) {
            return (
              <FormItem
                name="studies"
                label={t('stackDatasetModal.studies')}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                {studyListQuery.isFetching ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <Select
                    options={studyListQuery.data}
                    placeholder={t('select')}
                    loading={studyListQuery.isFetching}
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                    fieldNames={{ label: 'name', value: 'id' }}
                    mode="multiple"
                  />
                )}
              </FormItem>
            );
          }
          return null;
        }}
      </FormItem>
    </FormLayout>
  );
};

export const DatasetModalsEditStackDataset = ({ open, data, onClose }: IDatasetModalsEditStackDatasetProps) => {
  const { t } = useTranslation(['dataset']);

  return (
    <DraggableModal
      width={800}
      open={open}
      onCancel={onClose}
      title={data?.id ? t('stackDatasetModal.editTitle') : t('stackDatasetModal.createTitle')}
      footer={null}
      destroyOnClose
    >
      {open && <DatasetModalsEditStackDatasetContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IDatasetModalsEditStackDatasetProps {
  open: boolean;
  data?: {
    id?: number;
    name: string;
    scope?: Scope;
  };
  onClose: () => void;
}

interface IDatasetModalsEditStackDatasetContentContentProps
  extends Pick<IDatasetModalsEditStackDatasetProps, 'data' | 'onClose'> {
  t: TFunction;
}

interface IDatasetModalsEditStackDatasetFieldFormValues {
  name: string;
  scope_type: FieldFormScopeTypeValue;
  studies: string[];
}
