import { Form, FormItem, FormLayout, Select, Radio, Table, notification, Popover } from '@ui';
import {
  ELibraryEntityNames,
  ELibrarySourceType,
  ESelectedActions,
  IBaseLibraryEntitySystemInfo,
} from '@modules/library/root/LibraryTypes';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import {
  useLazyEnvModelListQuery,
  useLazyEnvStudyListQuery,
  useLazyLibraryListQuery,
} from '@modules/library/root/duck/libraryApi';
import { useFeatures } from '@modules/user/duck/userHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { itemsToOptions } from '@shared/utils/Form';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { IBaseColumnProps } from '@shared/components/ObjectTable';
import { SupportedEnvs } from '@app/AppTypes';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { ILibraryModel } from '@modules/library/model/LibraryModelTypes';
import { Model, ModelType } from '@modules/model/ModelTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { IAnalysisObjectModelListItem } from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { Checkbox, Col, Row, TableColumnsType, TablePaginationConfig } from 'antd';
import { CSSObject } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useLazyLibraryModelListQuery } from '../duck/libraryModelApi';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export interface GetLibraryImportModelFromLibrarySourceProps extends IBaseColumnProps {
  type: ModelType;
  data_store: string;
  version?: number | string;
  rawData: ILibraryModel | IAnalysisObjectModelListItem | Model;
}

export const getLibraryImportModelFromLibrarySource = (
  data: ILibraryModel[] | Model[] | IAnalysisObjectModelListItem[],
  kind: ELibrarySourceType,
): Array<GetLibraryImportModelFromLibrarySourceProps> => {
  if (!Array.isArray(data)) return [];

  if (kind === ELibrarySourceType.Library) {
    return (data as ILibraryModel[]).map((item) => ({
      id: item.id,
      name: item.name,
      type: item.model_type,
      data_store: item.data_store,
      version: Array.isArray((item as IAnalysisObjectModelListItem)?.library_id)
        ? (item as IAnalysisObjectModelListItem).library_id.length
          ? item.version
          : '-'
        : item.version,
      rawData: item,
      version_id: item.version_id,
    }));
  }

  return (data as Model[]).map((item) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    data_store: item.data_store.name,
    version: item.version,
    rawData: item,
  }));
};

const isRecordDisabled = (record: GetLibraryImportModelFromLibrarySourceProps) =>
  'configuration' in record.rawData
    ? isEmpty(record.rawData.configuration.schema) || isEmpty(record.rawData.configuration.spark_schema)
    : isEmpty(record.rawData.schema) || isEmpty(record.rawData.spark_schema);

export const LibraryImportModel = ({
  onClose,
  columns,
  sourceOnlyLibrary,
  locale,
  kind,
  onImport,
  libraryStatuses,
  hideOverwriteHandles,
  model,
  isModelSent,
  hideCurrentStudy,
  onlyCurrentEnv,
  showExport,
}: ILibraryImportModelProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const user = useSelector(selectAuthUser);
  const [form] = Form.useForm();
  const { t } = useTranslation(['libraryRoot']);
  const { setPagination, getPagination } = useTablePaginationState(initialPage);
  const { hasGL } = useFeatures();
  const [columnsFiltered, setColumnsFiltered] = useState(columns);
  const [sourceData, setSourceData] = useState<TSourceDataProps>([]);
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);
  const [libraryListQuery, libraryListQueryData] = useLazyLibraryListQuery();
  const [envStudyListQuery, studiesListQueryData] = useLazyEnvStudyListQuery();
  const [libraryModelsListQuery, libraryModelsListQueryData] = useLazyLibraryModelListQuery();
  const [envStudyModelsListQuery, studyModelsListQueryData] = useLazyEnvModelListQuery();

  const [tableData, setTableData] = useState<ILibraryImportModel[]>([]);
  const [selectedTableItems, setSelectedTableItems] = useState<React.Key[]>([]);

  const fetchingNames = libraryListQueryData.isFetching || studiesListQueryData.isFetching;

  const pagination = getPagination(tableData.length);

  const userCurrentEnv = user?.env_name ?? '';
  const allUserEnvs = user?.environments;
  const currentEnvLabel = allUserEnvs && allUserEnvs[userCurrentEnv]?.label;

  const sourceType = useMemo(() => {
    const sourceOptions = [];

    if (hasGL) {
      sourceOptions.push({
        label: ELibrarySourceType.Library,
        value: ELibrarySourceType.Library,
      });
    }

    if (onlyCurrentEnv) {
      sourceOptions.push({ label: `Study (${currentEnvLabel})`, value: userCurrentEnv } as any);
      return sourceOptions;
    }

    if (!sourceOnlyLibrary) {
      sourceOptions.push(
        ...Object.entries(allUserEnvs || ({} as SupportedEnvs)).map(([env, envData]) => {
          return { label: `Study (${envData!.label})`, value: env } as any;
        }),
      );
    }
    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserEnvs, sourceOnlyLibrary, hasGL]);

  const initValues = {
    sourceKind: (Array.isArray(sourceType) && sourceType.length && sourceType[0].value) || undefined,
    sourceId: (!hasGL && !hideCurrentStudy && globalStudy?.id) || undefined,
    overwrite: false,
  };

  useEffect(() => {
    if (initValues.sourceKind) {
      changeSource(initValues.sourceKind);
    }
    if (initValues.sourceId) {
      onChangeSourceIdInput(initValues.sourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isModelSent) {
      setTableData(getLibraryImportModelFromLibrarySource([model], ELibrarySourceType.Study));
      setSelectedTableItems([model.id]);
    }
  }, [model, isModelSent]);

  const changeSource = async (value: string) => {
    setTableData([]);
    setSelectedTableItems([]);
    setSourceData([]);
    if (value === ELibrarySourceType.Library) {
      setColumnsFiltered(columns);
      const data = await libraryListQuery({
        status: libraryStatuses ? libraryStatuses.join(',') : undefined,
        page_size: 999,
      }).unwrap();
      const libraryListOption = itemsToOptions(data?.items?.filter((item) => item.id !== globalLibrary?.id));
      setSourceData(libraryListOption);
    } else if (value) {
      // TODO: maybe after updating Antd to 5.13 version we can use property 'hidden' directly in the column and remove state variable
      setColumnsFiltered(columns.filter((el) => el.key && !['version'].includes(el.key as string)));
      const data = await envStudyListQuery({ env: value }).unwrap();
      const studiesListOption = itemsToOptions(
        hideCurrentStudy && value === user?.env_name ? data?.filter((item) => item.id !== globalStudy?.id) : data,
      );
      setSourceData(studiesListOption);
    }
  };

  const onChangeSourceInput = (value: string) => {
    form.setFieldValue('sourceId', '');
    changeSource(value);
  };

  const onChangeSourceIdInput = async (id: number) => {
    try {
      if (!isModelSent) {
        setSelectedTableItems([]);
        const source = form.getFieldValue('sourceKind');
        if (source === ELibrarySourceType.Library) {
          const data = await libraryModelsListQuery({ library_id: id, page_size: 9999, detailed: '1' }).unwrap();
          setTableData(getLibraryImportModelFromLibrarySource(data.items, ELibrarySourceType.Library));
        } else if (source) {
          const data = await envStudyModelsListQuery({ study_id: id, env: source }).unwrap();
          setTableData(getLibraryImportModelFromLibrarySource(data, ELibrarySourceType.Study));
        }
      }
    } catch (e) {
      console.error('Error while changing source id', e);
    }
  };

  const onSubmit = async (values: ILibraryCopyFieldFormValues) => {
    try {
      let options: IOnImportOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if ((values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) || isModelSent) {
        const studyName = sourceData.find((item) => item.value === values['sourceId'])?.label!;
        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            tenant_info: user!.tenant_info,
            ...(isModelSent
              ? { env: user!.env_name, source: globalStudy!.name ?? '' }
              : { env: values['sourceKind'], source: studyName }),
          },
          libraryId: values['sourceId'],
        };
      }

      await onImport(
        tableData.filter((item) => selectedTableItems.includes(item.id)),
        options,
      );
      notification.success({ message: t('copyForm.successMessage', { kind }) });
      onClose();
    } catch (e) {
      console.error('Error while importing', e);
      if ((e as QueryErrorType)?.data?.userMsg) {
        notification.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  const rowSelection: TableRowSelection<GetLibraryImportModelFromLibrarySourceProps> = {
    onChange: (selectedRowKeys) => {
      setSelectedTableItems(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: isRecordDisabled(record),
      id: record.id as string,
    }),
    renderCell: (value, record, index, originNode) =>
      isRecordDisabled(record) ? (
        <Popover title={t('libraryModel.copyForm.disabledRecord', { name: record.name })} color="yellow">
          <Checkbox id={record.id as string} disabled />
        </Popover>
      ) : (
        originNode
      ),
  };

  const filteredModels = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return tableData?.filter((model) => !selectedTableItems.includes(model.id));
    }
    return tableData;
  }, [selectedFilter, tableData, selectedTableItems]);

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={selectedTableItems.length === 0}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={showExport ? t('copyForm.sourceKindExport') : t('copyForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('copyForm.sourceKindPlaceholder')}
              options={sourceType}
              onChange={onChangeSourceInput}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('copyForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('copyForm.sourceIdPlaceholder')}
              options={sourceData}
              loading={fetchingNames}
              disabled={fetchingNames}
              onChange={onChangeSourceIdInput}
            />
          </FormItem>
        </Col>
      </Row>
      {!hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <FormItem name="overwrite" label={t('copyForm.duplicateLabel.label')} css={cssHorizontalFormItem}>
              <Radio.Group>
                <Radio value={false}>{t('copyForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('copyForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
      )}
      {!isModelSent && (
        <Row gutter={24}>
          <Col span={24}>
            <FormItem name="table" wrapperCol={{ span: 24 }} style={{ marginBottom: 50 }}>
              <Table
                size="small"
                columns={columnsFiltered}
                locale={locale}
                loading={libraryModelsListQueryData.isFetching || studyModelsListQueryData.isFetching}
                rowKey="id"
                dataSource={filteredModels}
                pagination={pagination}
                onChange={onTableChange}
                rowSelection={{
                  preserveSelectedRowKeys: true,
                  type: 'checkbox',
                  ...rowSelection,
                  selectedRowKeys: selectedTableItems,
                  selections: [
                    Table.SELECTION_INVERT,
                    {
                      key: ESelectedActions.SHOW_ALL,
                      text: 'Show all',
                      onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
                    },
                    {
                      key: ESelectedActions.HIDE_SELECTED,
                      text: 'Hide selected',
                      onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
                    },
                  ],
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </FormLayout>
  );
};

const cssHorizontalFormItem = (): CSSObject => ({
  '&&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&&& .ant-form-item-label': {
    paddingBottom: 0,
    paddingRight: 16,
    flex: 'none',
  },
});

export interface ILibraryImportModelProps {
  onClose: () => void;
  columns: TableColumnsType<ILibraryImportModel>;
  sourceOnlyLibrary?: boolean;
  locale: Record<string, any>;
  kind: ELibraryEntityNames;
  onImport: (data: ILibraryImportModel[], options: IOnImportOptions) => void;
  libraryStatuses?: LibraryStatus[];
  hideOverwriteHandles?: boolean;
  model?: any;
  hideCurrentStudy?: boolean;
  isModelSent?: boolean;
  onlyCurrentEnv?: boolean;
  showExport?: boolean;
}

type TSourceDataProps = {
  label: string;
  value: number;
}[];

interface IOnImportOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
  libraryId?: number;
}

export type ILibraryImportModel = GetLibraryImportModelFromLibrarySourceProps;

export type WrapperFn = (id: number, env?: string) => Promise<ILibraryImportModel[]>;

interface ILibraryCopyFieldFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}
