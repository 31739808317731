import { DirectoryTree, Skeleton, Space, SpaceCompact, Tooltip, Typography } from '@components/ui';
import { CaretUpDownIcon, InfoIcon } from '@components/icons';
import { SvgIconProps } from '@components/icons/BaseIcon';
import { useClickOutside } from '@shared/duck/sharedHooks';
import { ComponentType, KeyboardEventHandler, MouseEventHandler, ReactNode, useRef, useState } from 'react';
import { CSSObject, Theme } from '@emotion/react';
import { TreeProps } from 'antd';

export const MenuSelector = ({
  title,
  category,
  Icon,
  noDataText,
  isLoading = false,
  treeData,
  onSelect,
  ...props
}: MenuSelectorProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useClickOutside(selectorRef, () => {
    setIsExpanded(false);
  });

  const handleToggleExpanded = (target: EventTarget) => {
    if (target === selectorRef.current) {
      setIsExpanded((prev) => !prev);
    }
  };

  const handleClick: MouseEventHandler = (event) => {
    handleToggleExpanded(event.target);
  };

  const handleKeyUp: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleToggleExpanded(event.target);
    }
  };

  const handleSelectNode: TreeProps['onSelect'] = (...args) => {
    onSelect?.(...args);
    setIsExpanded(false);
  };

  if (isLoading) {
    return <Skeleton.Button active css={cssSkeleton} size="large" />;
  }

  return (
    <div ref={selectorRef} css={cssContainer} onClick={handleClick} onKeyUp={handleKeyUp} role="button" tabIndex={0}>
      <Space css={cssInfo} justify="space-between" align="center">
        <Space css={cssInfoLeft} align="center">
          <Icon css={cssSelectorIcon} />
          <SpaceCompact direction="vertical">
            <Typography.Text css={cssTitle} type="secondary">
              {category}
            </Typography.Text>
            <Typography.Text>{title}</Typography.Text>
          </SpaceCompact>
        </Space>
        <CaretUpDownIcon css={cssCaretIcon} />
      </Space>

      <div css={cssTreeContainer(isExpanded)}>
        {treeData?.length ? (
          <DirectoryTree defaultExpandAll treeData={treeData} {...props} onSelect={handleSelectNode} />
        ) : (
          <Typography.Text>{noDataText}</Typography.Text>
        )}
      </div>
    </div>
  );
};

interface MenuSelectorProps extends TreeProps {
  category: string | ReactNode;
  title: string;
  noDataText: string;
  isLoading?: boolean;
  Icon: ComponentType<SvgIconProps>;
}

const cssContainer = (theme: Theme): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  padding: '5px',
  margin: '8px 0 16px',
  minHeight: '52px',
  border: `1px solid ${theme['color-grey-400']}`,
  borderRadius: '4px',
  cursor: 'pointer',
});

const cssInfo = (): CSSObject => ({
  pointerEvents: 'none',
});

const cssInfoLeft = (): CSSObject => ({
  gap: '10px',
});

const cssTitle = (theme: Theme): CSSObject => ({
  fontSize: theme.fontSizeSM,
});

const cssSelectorIcon = (): CSSObject => ({
  height: '40px',
  width: 'auto',
  flexShrink: 0,
});

const cssCaretIcon = (theme: Theme): CSSObject => ({
  height: '18px',
  width: '18px',
  flexShrink: 0,
  color: theme['color-grey-500'],
});

const cssTreeContainer =
  (isExpanded: boolean) =>
  (theme: Theme): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 11,
    left: 0,
    top: '60px',
    width: '100%',
    maxHeight: '565px',
    background: 'white',
    overflow: 'hidden',
    padding: '12px',
    fontSize: theme.fontSizeSM,
    color: theme.colorTextSecondary,
    border: `1px solid ${theme['color-grey-300']}`,
    borderRadius: '8px',
    boxShadow: '0px 4px 8px 0px #00000029',
    transition: '0.1s all ease',

    ...(!isExpanded && {
      opacity: 0,
      pointerEvents: 'none',
      transform: 'translateY(-20px)',
    }),
  });

const cssSkeleton = (theme: Theme): CSSObject => ({
  height: '52px',
  margin: '8px 0 16px',
  borderRadius: theme.borderRadiusSM,
  '&&': {
    display: 'flex',
  },
  '&& .ant-skeleton-button': {
    width: '100%',
    height: '100%',
    margin: 0,
  },
});
