import { TabProps } from '@modules/job/JobTypes';
import { Alert, Button, Checkbox, FormItem, Select, Space, Typography } from '@ui';
import { AntdIconBox } from '@components/icons';
import { emptyError } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { DataTable } from '@components';
import { isSystemColumn, typeOptions } from '@modules/job/modals/components/upload/duck/uploadUtils';
import { MandatoryColumnsWithAutogeneration } from '@config/constants';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RenderCellProps } from 'react-data-grid';

export const DiscoverTabManual = ({
  t,
  tab,
  canImportJobEditStructure,
  updateTabPartially,
  handleTabConfirm,
}: DiscoverTabProps) => {
  const { tableName, mapping, isMappingExists, isHideConfirmBtn, missingRequiredColumns } = tab;
  const isDisableMapping = !canImportJobEditStructure && isMappingExists;
  const missingAutogeneratedColumns = missingRequiredColumns.filter((column) =>
    MandatoryColumnsWithAutogeneration.includes(column),
  );

  const handlesColumnChange = (record: any, field: string, value?: string) => {
    const updatedMappingTable = mapping.map((el) =>
      (el.sourceColumn || el.sourceMappingColumn) === (record.sourceColumn || record.sourceMappingColumn)
        ? {
            ...el,
            [field]: value || !record[field],
          }
        : el,
    );
    updateTabPartially(tableName, { mapping: updatedMappingTable, confirmed: false, ...emptyError });
  };

  const columns = useMemo(
    () => [
      {
        width: 60,
        key: 'skip',
        name: t('uploadRT.mapping.skip'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.skip}
              disabled={isSystemColumn(row.sourceColumn!) || isDisableMapping}
              onChange={() => handlesColumnChange(row, 'skip')}
            />
          </div>
        ),
      },
      {
        minWidth: 250,
        key: 'sourceColumn',
        name: t('uploadRT.mapping.sourceColumn'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => {
          if (!row.sourceMappingColumn) {
            return (
              <Space direction="horizontal">
                {row.sourceColumn}
                <AntdIconBox icon={QuestionCircleOutlined} tip={t('uploadRT.mappingTooltips.columnAbsentInMapping')} />
              </Space>
            );
          }

          if (!row.sourceColumn) {
            return (
              <Space direction="horizontal">
                <Typography.Text type="danger" children={row.sourceMappingColumn} />
                <AntdIconBox
                  icon={QuestionCircleOutlined}
                  tip={
                    canImportJobEditStructure
                      ? t('uploadRT.mappingTooltips.columnAbsentInFileAdmin')
                      : t('uploadRT.mappingTooltips.columnAbsentInFile')
                  }
                  color="danger"
                />
              </Space>
            );
          }

          return row.sourceColumn;
        },
      },
      {
        minWidth: 100,
        key: 'type',
        name: t('uploadRT.mapping.type'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <FormItem
            name={[tableName, 'structure', row.sourceColumn!]}
            wrapperCol={{ span: 24 }}
            css={cssTableInput}
            label={null}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              options={typeOptions}
              onChange={(value) => handlesColumnChange(row, 'type', value)}
              disabled={isSystemColumn(row.sourceColumn!) || isDisableMapping}
              onClick={(e) => e.stopPropagation()}
            />
          </FormItem>
        ),
      },
      {
        width: 96,
        key: 'nullable',
        name: t('uploadRT.mapping.nullable'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.nullable}
              onChange={() => handlesColumnChange(row, 'nullable')}
              disabled={isSystemColumn(row.sourceColumn!) || isDisableMapping}
            />
          </div>
        ),
      },
      {
        width: 96,
        key: 'primaryKey',
        name: t('uploadRT.mapping.primaryKey'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.primaryKey}
              onChange={() => handlesColumnChange(row, 'primaryKey')}
              disabled={isDisableMapping}
            />
          </div>
        ),
      },
    ],
    [tableName, isDisableMapping, handlesColumnChange, canImportJobEditStructure],
  );

  const rowKeyGetter = (item: TabProps['mapping'][0]) =>
    `${tableName}_${item.sourceColumn || item.sourceMappingColumn}`;

  return (
    <>
      <div css={cssTabHeaderContainer(isMappingExists || missingAutogeneratedColumns.length > 0)}>
        <div>
          {isMappingExists && (
            <Alert
              css={cssAlert}
              type="warning"
              message={canImportJobEditStructure ? t('uploadRT.mappingExistsMsgAdmin') : t('uploadRT.mappingExistsMsg')}
            />
          )}
          {missingAutogeneratedColumns.length > 0 && (
            <Alert
              css={cssAlert}
              type="warning"
              message={t('uploadRT.missingAutogeneratedColumns', { list: missingAutogeneratedColumns.join(', ') })}
            />
          )}
        </div>
        {!isHideConfirmBtn && (
          <Button
            type="primary"
            onClick={() => {
              handleTabConfirm(tableName);
            }}
          >
            {t('uploadRT.confirmTabBtn')}
          </Button>
        )}
      </div>
      <div css={cssMappingTable}>
        {mapping && (
          <DataTable
            rowKeyGetter={rowKeyGetter}
            rowHeight={56}
            headerRowHeight={40}
            columns={columns}
            rows={mapping ?? []}
          />
        )}
      </div>
    </>
  );
};

const cssCheckbox = (): CSSObject => ({ textAlign: 'center' });

const cssMappingTable = (): CSSObject => ({
  height: '300px',
  maxHeight: '300px',
  width: '100%',
  paddingBottom: '8px',

  '.rdg-cell': {
    outline: 'none',
    alignContent: 'space-evenly',
  },
});

const cssTableInput = (): CSSObject => ({
  width: '100%',
});

const cssTabHeaderContainer = (warnings: boolean): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: warnings ? 'space-between' : 'right',
  alignItems: 'center',
  marginBottom: 12,
});

const cssAlert = (): CSSObject => ({
  height: 32,
});

interface DiscoverTabProps {
  t: TFunction;
  tab: TabProps;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  handleTabConfirm: (val: string) => void;
  canImportJobEditStructure?: boolean;
}
