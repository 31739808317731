import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const FilterSvg: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M3.7998 4C3.7998 3.44772 4.24752 3 4.7998 3H20.7998C21.3521 3 21.7998 3.44772 21.7998 4V6.58579C21.7998 6.851 21.6944 7.10536 21.5069 7.29289L15.0927 13.7071C14.9052 13.8946 14.7998 14.149 14.7998 14.4142V17L10.7998 21V14.4142C10.7998 14.149 10.6944 13.8946 10.5069 13.7071L4.0927 7.29289C3.90516 7.10536 3.7998 6.851 3.7998 6.58579V4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
