import { Button, Space, Typography } from '@components/ui';
import { Ace } from 'ace-builds/ace';
import AceEditor from 'react-ace';
import { CSSObject, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { createRef, useEffect, useMemo } from 'react';
import { TFunction } from 'i18next';
import { ExpressionVarsList } from './ExpressionVarsList';
import { FUNCTIONS, OPERATORS } from './ExpressionSystemData';
import CustomHighlightMode from './Highlights';

export const ExpressionBuilder = ({
  id,
  className,
  variables,
  t,
  value,
  readOnly,
  onChange,
}: ExpressionBuilderProps) => {
  const refEditor = createRef<AceEditor>();

  useEffect(() => {
    refEditor.current?.editor.focus();
  }, [refEditor]);

  useEffect(() => {
    const customMode = new CustomHighlightMode(variables) as Ace.SyntaxMode;
    refEditor.current?.editor.getSession().setMode(customMode);
  }, [variables, refEditor]);

  const sourceVars = useMemo(
    () =>
      variables.map((variable) => ({
        name: variable,
        rawData: {
          name: variable,
        },
      })),
    [variables],
  );

  const insertTextAtCursor = (text: string) => refEditor.current?.editor.insert(text);

  const addVariable = (data: Field) => insertTextAtCursor(data.name);

  const addFunction = ({ value }: { value: string }) => {
    insertTextAtCursor(value);
    // move cursor one symbol back to be inside parenthesis
    const point = refEditor.current?.editor.getCursorPosition();
    if (point) {
      refEditor.current?.editor.moveCursorTo(point.row, point.column - 1);
    }
  };

  const onChangeValue = () => onChange(refEditor.current?.editor.getValue() ?? '');

  return (
    <div css={cssBody} className={className}>
      <AceEditor
        readOnly={readOnly}
        ref={refEditor}
        css={cssEditorBody}
        height="100px"
        width="100%"
        mode="text"
        fontSize={14}
        wrapEnabled={true}
        focus={!readOnly}
        name={id ?? 'code_editor'}
        defaultValue={value ?? ''}
        onChange={onChangeValue}
      />
      {!readOnly && (
        <Scrollbars autoHide={false} autoHeight autoHeightMin={32}>
          <Space css={cssTools}>
            {OPERATORS.map((data) => (
              <Button
                size="middle"
                type="default"
                icon={data.value}
                onClick={() => {
                  insertTextAtCursor(data.value);
                }}
              />
            ))}
          </Space>
        </Scrollbars>
      )}

      <Space full css={cssContainer}>
        <Space full direction="vertical">
          <Typography.Text>{t('transform.variables')}</Typography.Text>
          <ExpressionVarsList
            disabled={readOnly}
            dataSource={sourceVars}
            onClick={addVariable}
            searchPlaceholder={t('transform.search.variables')}
            t={t}
          />
        </Space>

        <Space full direction="vertical">
          <Typography.Text>{t('transform.functions')}</Typography.Text>
          <ExpressionVarsList
            disabled={readOnly}
            dataSource={FUNCTIONS}
            onClick={addFunction}
            searchPlaceholder={t('transform.search.functions')}
            t={t}
          />
        </Space>
      </Space>
    </div>
  );
};

const cssContainer = (): CSSObject => ({
  '&&& .ant-space-item': {
    flex: 1,
  },
});

const cssBody = (theme: Theme): CSSObject => ({
  width: '100%',
});

const cssTools = (theme: Theme): CSSObject => ({
  margin: `${theme.marginLG}px 0`,
  '&&': {
    '&&& .ant-btn-icon': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

const cssEditorBody = (theme: Theme): CSSObject => ({
  borderBottom: `1px solid ${theme.colorBorder}`,
  backgroundColor: 'transparent',
});

interface ExpressionBuilderProps {
  id?: string;
  className?: string;
  onChange: (value: string) => void;
  t: TFunction;
  variables: string[];
  value?: string;
  readOnly?: boolean;
}

interface Field {
  name: string;
}
