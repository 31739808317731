import { DraggableModal } from '@ui';
import { PageSkeleton, QueryError } from '@components';
import { useCodeLabJobLogsQuery } from '@modules/codeLab/duck/codeLabApi';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';

const CodeLabModalsLogContent = ({ data, t }: CodeLabModalsLogContentProps) => {
  const codeLabJobLogsQuery = useCodeLabJobLogsQuery(data?.codeLabJobId!);
  return (
    <div>
      {codeLabJobLogsQuery.isLoading && <PageSkeleton />}
      {!codeLabJobLogsQuery.isLoading && codeLabJobLogsQuery.error && (
        <QueryError error={codeLabJobLogsQuery.error} title={t('modals.logs.logErrorTitle')} />
      )}
      {codeLabJobLogsQuery.data && !codeLabJobLogsQuery.error && (
        <>
          <div>
            <strong>Started:</strong>
            <span>
              {codeLabJobLogsQuery.data?.started_at
                ? new Date(codeLabJobLogsQuery.data?.started_at).toLocaleString()
                : ''}
            </span>
          </div>
          <div>
            <strong>Finished:</strong>
            <span>
              {codeLabJobLogsQuery.data?.finished_at
                ? new Date(codeLabJobLogsQuery.data?.finished_at).toLocaleString()
                : ''}
            </span>
          </div>
          <Scrollbars css={cssLogsLayout} autoHide={false} autoHeight autoHeightMin={500}>
            <pre css={cssLogs}>{codeLabJobLogsQuery.data.log}</pre>
          </Scrollbars>
        </>
      )}
    </div>
  );
};

export const CodeLabModalsLog = ({ open, data, onClose }: CodeLabModalsLogProps) => {
  const { t } = useTranslation(['codeLab']);

  return (
    <DraggableModal
      width="80%"
      open={open}
      onCancel={onClose}
      title={t('modals.logs.title')}
      destroyOnClose
      okButtonProps={{ hidden: true }}
      cancelText={t('close')}
    >
      {open && <CodeLabModalsLogContent data={data} t={t} />}
    </DraggableModal>
  );
};

export interface CodeLabModalsLogProps {
  open: boolean;
  data: Partial<{ codeLabJobId: number }>;
  onClose: () => void;
}

export interface CodeLabModalsLogContentProps {
  data?: CodeLabModalsLogProps['data'];
  t: TFunction;
}

const cssLogsLayout = (): CSSObject => ({
  height: '500px',
  marginTop: '20px',
});

const cssLogs = (): CSSObject => ({
  fontSize: '12px',
  overflow: 'visible',
});
