import { DraggableModal, notification, Typography } from '@ui';
import { ILibraryImportPscProps, LibraryImportPsc } from '@modules/library/psc/components/LibraryImportPsc';
import { usePscColumns } from '@modules/library/psc/duck/libraryPscHooks';
import { ELibraryEntityNames } from '@modules/library/root/LibraryTypes';
import { useLibraryCopyPscMutation, useValidatePscMutation } from '@modules/library/psc/duck/libraryPscApi';
import { prepareLibraryPsc } from '@modules/library/psc/duck/libraryPscUtils';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import { useState } from 'react';

const LibraryPscModalsCopyPscContent = ({ t, onClose }: LibraryPscModalsCopyPscContentProps) => {
  const { pscColumns, locale } = usePscColumns();
  const [copyPsc] = useLibraryCopyPscMutation();
  const [usedPsc] = useValidatePscMutation();
  const confirmModal = useConfirmModal();

  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);
  const [isUsedError, setIsUsedError] = useState(false);

  const onImport: ILibraryImportPscProps['onImport'] = async (values, { kind, overwrite, systemInfo }) => {
    if (overwrite) {
      try {
        await usedPsc({ psc_filter_names: values.map((item) => item.name) }).unwrap();

        await copyPsc({ data: prepareLibraryPsc(values, kind, systemInfo), overwrite: overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const isError =
          data.rawData?.message || typeof data.rawData?.error === 'string' || isArray(data.rawData?.error);
        setIsUsedError(isError);

        confirmModal.openConfirmation({
          content: isError ? (
            <Typography.Text type="danger">{data.rawData?.message || data.rawData?.error}</Typography.Text>
          ) : (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{(Object.keys(data.rawData?.error) || []).join(', ')}</strong>
            </div>
          ),
          data: prepareLibraryPsc(values, kind, systemInfo),
          cascadeObjects: !isError ? data.rawData?.error : {},
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copyPsc({ data: prepareLibraryPsc(values, kind, systemInfo), overwrite: overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);

      await copyPsc({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <LibraryImportPsc
        onClose={onClose}
        columns={pscColumns}
        locale={locale}
        kind={ELibraryEntityNames.Psc}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
        okButtonProps={{ disabled: isUsedError }}
      >
        {!isUsedError && (
          <LibraryCascadeObjects
            data={confirmModal.confirmState?.cascadeObjects}
            isCascade={isCascade}
            setIsCascade={setIsCascade}
            setSelectedCascadeObjects={setSelectedCascadeObjects}
          />
        )}
      </ConfirmModal>
    </>
  );
};

export const LibraryPscModalsCopyPsc = ({ open, data, onClose }: ILibraryPscModalsCopyPscProps) => {
  const { t } = useTranslation(['libraryPsc']);

  return (
    <DraggableModal
      css={cssModal}
      width={800}
      open={open}
      onCancel={onClose}
      title={t('copyForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <LibraryPscModalsCopyPscContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 800,
  },
});

export interface ILibraryPscModalsCopyPscProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface LibraryPscModalsCopyPscContentProps extends Pick<ILibraryPscModalsCopyPscProps, 'data' | 'onClose'> {
  t: TFunction;
}
