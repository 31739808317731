import { I18nLocales } from '@i18n';
import en from './codeLab_en.json';

export const codeLabLocales: Record<I18nLocales, I18nCodeLabTranslations> = {
  en,
};

interface I18nCodeLabTranslations {
  pageRootName: string;
  loadingError: string;
  rootTable: {
    fileName: string;
    schedulerRule: string;
    schedulerUpdated: string;
    lastFinished: string;
    status: string;
    actions: string;
    showLogs: string;
    viewConfig: string;
  };
  modals: {
    scheduler: {
      title: string;
    };
    logs: {
      title: string;
      logErrorTitle: string;
    };
  };
}
