import { dataStageColors } from '@config/theme/colors';
import { ArgsProps, default as antNotification } from 'antd/es/notification';

const backgroundStyles = {
  success: { backgroundColor: dataStageColors['color-green-background'] },
  error: { backgroundColor: dataStageColors['color-error-red-dark-background'] },
  info: { backgroundColor: dataStageColors['color-brand-blue-200'] },
  warning: { backgroundColor: dataStageColors['color-alert-yellow-background'] },
};

const notify = (config: ArgsProps) => {
  const { type } = config;

  antNotification.open({
    placement: 'bottomRight',
    ...config,
    style: {
      ...(type && backgroundStyles[type]),
      borderRadius: 8,
      ...config.style,
    },
  });
};

const notification = {
  ...antNotification,
  success: (config: ArgsProps) => notify({ ...config, type: 'success' }),
  info: (config: ArgsProps) => notify({ ...config, type: 'info' }),
  warning: (config: ArgsProps) => notify({ ...config, type: 'warning' }),
  error: (config: ArgsProps) => notify({ ...config, type: 'error' }),
};

export { notification };
