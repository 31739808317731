import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const PlusCircle: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
