import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { MainMenu } from '@components';
import { LibrarySelector } from '@modules/library/root/components/LibrarySelector';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { libraryMenu } from '@routes/library';
import { useSelector } from 'react-redux';

export const LibraryMenu = () => {
  const globalLibrary = useSelector(selectGlobalLibrary);

  const { appPermissions } = useAppPermissions();

  const libraryMenuItems = globalLibrary?.id ? libraryMenu(globalLibrary?.id, appPermissions) : [];

  return (
    <MainMenu items={libraryMenuItems}>
      <LibrarySelector />
    </MainMenu>
  );
};
