import { useSourceListQuery } from '@modules/source/duck/sourceApi';
import { MANUAL_UPLOAD_SOURCE_ID } from '@modules/source/duck/sourceConstants';
import { isCrossStudy } from '@shared/utils/common';
import { useMemo } from 'react';
import { StoreListResponse, useStoreListQuery } from './storeApi';
import { SYSTEM_STORE_NAMES } from './storeConstants';

export const useManualUploadStores = (
  defaultDataStoreId?: string | number,
  options?: { onlySystemStores?: boolean; onlyUserStores?: boolean; canRefTableUpload?: boolean },
  studyId?: number,
) => {
  const dataStoreListQuery = useStoreListQuery({}, { skip: studyId === undefined });
  const dataSourceListQuery = useSourceListQuery({}, { skip: studyId === undefined });

  const manualUploadStores = useMemo(() => {
    return (
      dataStoreListQuery.data?.reduce((acc, store) => {
        if (store.data_source_id === defaultDataStoreId) {
          return [...acc, store];
        }
        if (options?.onlyUserStores && isCrossStudy(studyId) && store.name === SYSTEM_STORE_NAMES.GLOBAL_INTERNALS) {
          return [...acc, store];
        }
        if (
          store.active &&
          dataSourceListQuery?.data?.some(
            (source) =>
              store.data_source_id === source.id &&
              source.connection_id === MANUAL_UPLOAD_SOURCE_ID &&
              (options?.onlySystemStores ? store.system : options?.onlyUserStores ? !store.system : true) &&
              (store.name === SYSTEM_STORE_NAMES.REFERENCE_TABLE && store.system ? options?.canRefTableUpload : true),
          ) &&
          !([SYSTEM_STORE_NAMES.INTERNALS, SYSTEM_STORE_NAMES.GLOBAL_INTERNALS].includes(store.name) && store.system)
        ) {
          return [...acc, store];
        }
        return acc;
      }, [] as StoreListResponse['items']) || []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceListQuery?.data, dataStoreListQuery.data, defaultDataStoreId]);

  return {
    stores: dataStoreListQuery.data || [],
    sources: dataSourceListQuery.data || [],
    manualUploadStores,
    loading: dataStoreListQuery.isLoading || dataSourceListQuery.isLoading,
  };
};
