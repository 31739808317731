import { RefObject, useEffect } from 'react';

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void, useCapture?: boolean) => {
  const handleClick: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, useCapture);

    return () => {
      document.removeEventListener('click', handleClick, useCapture);
    };
  });
};
