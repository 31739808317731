import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { ILibraryImportPsc } from '@modules/library/psc/components/LibraryImportPsc';
import { ILibraryPsc, ILibraryPscQuery, IPsc } from '@modules/library/psc/PscTypes';

export const prepareLibraryPsc = (
  data: ILibraryImportPsc[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibraryPscQuery['data'] => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    if (kind === ELibrarySourceType.Library) {
      const rawData = item.rawData as ILibraryPsc;
      return {
        id: rawData.id,
        name: rawData.name,
        version_id: rawData.version_id,
        configuration: rawData.configuration,
        type: rawData.type || '',
        source_id: rawData.source_id || null,
        description: rawData.description,
        source: rawData.source || '',
        env: rawData.env || '',
        tenant_info: rawData.tenant_info || '',
      };
    }

    const { id, ...rawData } = item.rawData as IPsc;
    return {
      name: rawData.name,
      configuration: rawData,
      type: rawData.type || '',
      source_id: null, // TODO looks like we don't need this field but it's required by backend side. Should contain the study id
      description: rawData?.description || '',
      source: systemInfo?.source || '',
      env: systemInfo?.env || '',
      tenant_info: systemInfo?.tenant_info || '',
    };
  });
};
