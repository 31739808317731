import { Button, Checkbox, Form, FormItem, notification, Select, Tooltip } from '@ui';
import { TabProps } from '@modules/job/JobTypes';
import { emptyError } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { ReferenceTableInfo } from '@modules/viewer/duck/viewerApi';
import { SKIP_COLUMN } from '@modules/job/duck/constants';
import { DataTable, Loader } from '@components';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RenderCellProps } from 'react-data-grid';

export const DiscoverTab = ({
  tab,
  t,
  referenceTables,
  refTableInfo,
  updateTabPartially,
  referenceTablesLoading,
  referenceTablesInfoLoading,
  handleTabConfirm,
  form,
  studyId,
}: DiscoverTabProps) => {
  const refTablesOption = referenceTables?.map((el) => ({ label: el.split('.')[1], value: el })) ?? [];
  const tableData = tab?.mapping || [];
  const tabReferenceTable = tab?.referenceTable ?? '';
  const isDisableConfirmMapping = tableData.length === 0 || tableData.some((el) => !el.sourceColumn);

  const targetColumnsOptions = useMemo(
    () =>
      [{ label: '<skip>', value: SKIP_COLUMN }].concat(
        Object.keys(tab.structure)?.map((el) => ({
          label: el,
          value: el,
        })),
      ),
    [tab.structure],
  );

  const handlesTargetColumnChange = (value: string, record: any) => {
    if (value) {
      const updatedMappingTable = tableData.map((el) =>
        el.targetColumn === record.targetColumn
          ? {
              ...el,
              sourceColumn: value,
            }
          : el,
      );
      updateTabPartially(tab.tableName, {
        mapping: updatedMappingTable,
        confirmed: false,
        ...emptyError,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        minWidth: 250,
        key: 'targetColumn',
        name: t('uploadRT.mapping.targetColumn'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <>
            {row.targetColumn}
            {row.description && (
              <Tooltip css={cssTooltipIcon} title={row.description}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        minWidth: 200,
        key: 'sourceColumn',
        name: t('uploadRT.mapping.sourceColumn'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => {
          return (
            <FormItem
              css={cssTableInput}
              name={[tab.tableName, tabReferenceTable, 'structure', row.targetColumn!]}
              label={null}
              rules={[{ required: true, message: '' }]}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={targetColumnsOptions}
                onChange={(value) => handlesTargetColumnChange(value, row)}
                disabled={!tabReferenceTable}
                onClick={(e) => e.stopPropagation()}
              />
            </FormItem>
          );
        },
      },
      { minWidth: 90, key: 'type', name: t('uploadRT.mapping.type') },
      { minWidth: 60, key: 'length', name: t('uploadRT.mapping.length'), align: 'center' },
      {
        minWidth: 60,
        key: 'nullable',
        name: t('uploadRT.mapping.nullable'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox checked={row.nullable} disabled />
          </div>
        ),
      },
      {
        minWidth: 60,
        key: 'primaryKey',
        name: t('uploadRT.mapping.primaryKey'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox checked={row.primaryKey} disabled />
          </div>
        ),
      },
    ],
    [targetColumnsOptions, tabReferenceTable, tab.tableName, handlesTargetColumnChange],
  );

  const onChangeRefTable = async (table: string) => {
    try {
      const referenceTableInfo = await refTableInfo({ table, studyId }).unwrap();
      const fieldName = `${tab.tableName}.${table}_sourceColumn`;
      const tableInfoData = referenceTableInfo?.map((el: ReferenceTableInfo) => {
        const valueFromForm = form.getFieldValue(`${fieldName}_${el.name}`);

        return {
          targetColumn: el.name,
          sourceColumn: valueFromForm || '',
          description: el.description,
          type: el.type,
          length: el.length,
          nullable: el.is_nullable,
          primaryKey: el.is_primary_key,
        };
      });
      updateTabPartially(tab.tableName, {
        referenceTable: table,
        mapping: tableInfoData,
        confirmed: false,
        ...emptyError,
      });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('uploadRT.errors.errorInfo') });
      updateTabPartially(tab.tableName, {
        referenceTable: table,
        mapping: [],
        confirmed: false,
        ...emptyError,
      });
    }
  };

  const rowKeyGetter = (item: TabProps['mapping'][0]) => `${tab.tableName}_${item.targetColumn}`;

  return (
    <>
      <div css={cssTabHeaderContainer}>
        <FormItem
          style={{ flex: 1 }}
          name={`${tab.tableName}.referenceTable`}
          label={t('uploadRT.referenceTable')}
          rules={[{ required: true }]}
          labelCol={{ span: 6 }}
        >
          <Select
            value={tabReferenceTable}
            placeholder={t('uploadRT.referenceTablePlaceholder')}
            options={refTablesOption}
            onChange={onChangeRefTable}
            loading={referenceTablesLoading}
          />
        </FormItem>

        <Button
          type="primary"
          disabled={isDisableConfirmMapping}
          onClick={() => {
            handleTabConfirm(tab.tableName);
          }}
        >
          {t('uploadRT.confirmTabBtn')}
        </Button>
      </div>

      <div css={cssMappingTable}>
        {referenceTablesInfoLoading && <Loader mode="absolute" />}
        {!referenceTablesInfoLoading && tab.mapping && (
          <DataTable
            rowKeyGetter={rowKeyGetter}
            rowHeight={56}
            headerRowHeight={40}
            columns={columns}
            rows={tab.mapping ?? []}
          />
        )}
      </div>
    </>
  );
};

const cssCheckbox = (): CSSObject => ({ textAlign: 'center' });

const cssMappingTable = (): CSSObject => ({
  height: '300px',
  maxHeight: '300px',
  width: '100%',
  paddingBottom: '8px',
});

const cssTooltipIcon = (): CSSObject => ({
  color: 'grey',
  marginLeft: 4,
});

const cssTableInput = (): CSSObject => ({
  width: '100%',
  maxWidth: '300px',
});

const cssTabHeaderContainer = (): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
});

interface DiscoverTabProps {
  tab: TabProps;
  t: TFunction;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  referenceTables: string[];
  refTableInfo: any;
  referenceTablesLoading: boolean;
  referenceTablesInfoLoading: boolean;
  handleTabConfirm: (val: string) => void;
  form: ReturnType<typeof Form.useForm>[0];
  studyId: number;
}
