import { TLibrarySqlQueryModalsPushModalPayload, TLibrarySqlQueryModalsState } from '@modules/library/sqlQuery/modals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LibrarySqlQueryStore = {
  modals: { selectedModal: null },
};

export const librarySqlQuerySlice = createSlice({
  name: 'librarySqlQuery',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<TLibrarySqlQueryModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const librarySqlQueryActions = librarySqlQuerySlice.actions;

export const librarySqlQueryReducer = librarySqlQuerySlice.reducer;

interface LibrarySqlQueryStore {
  modals: TLibrarySqlQueryModalsState;
}
