import { IBaseLibraryEntity, IBaseLibraryParams } from '@modules/library/root/LibraryTypes';
import { ICascadeObjectsTableData } from '@shared/components/CascadeObjects';

export interface IPsc {
  id: number;
  name: string;
  type: EPscType;
  description?: string;
  access: {
    accessLevel: string;
    accessType: string;
    smartSharing: any[];
    studies: number[];
    studyGroups: any[];
    userAccessLevel: string;
  };
  created_at: string;
  created_by: {
    first_name: string;
    id: number;
    last_name: string;
  };
  data: string;
  is_shared: true;
  roles_mapping: Record<number, number>;
  study_mapping: Record<number, string>;
  users_mapping: Record<number, string>;
}

export enum EPscType {
  SC = 'sc_subset',
  DPSC = 'dpsc_subset',
}

export enum EPscTypeNames {
  sc_subset = 'PSC',
  dpsc_subset = 'Dynamic PSC',
}

export interface ILibraryPsc extends IBaseLibraryEntity {
  version_id: number;
  description: string;
  source_id?: string | null;
  type: EPscType;
  library_id?: number[];
}

export interface ILibraryPscResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: ILibraryPsc[];
}

export interface ILibraryPscListQueryParams extends IBaseLibraryParams {
  page?: number;
  page_size?: number;
  detailed?: '0' | '1';
}

export type TLibraryPscDeleteProps = Pick<ILibraryPsc, 'id' | 'name'>;

export interface ILibraryPscQuery {
  data: Array<
    Omit<ILibraryPsc, 'created_at' | 'updated_at' | 'created_by' | 'updated_by' | 'version' | 'id' | 'version_id'>
  >;
  overwrite: boolean;
  cascade_update?: ICascadeObjectsTableData[];
}

export interface ILibraryPscValidateQuery {
  psc_filter_names: string[];
}
