import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { FormLayout, DraggableModal, Form, FormItem, Input, Typography, notification, Alert, Radio } from '@ui';
import {
  useAnalysisPackageByIdQuery,
  useNewPackageVersionQuery,
  useSaveAnalysisPackageMutation,
} from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { ILibraryModel } from '@modules/library/model/LibraryModelTypes';
import { ILibraryCDR } from '@modules/library/cdr/LibraryCdrTypes';
import { useAnalysisObjectModelListQuery } from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import { Tabs } from '@components/ui/tabs';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { useCdrColumns } from '@modules/library/cdr/duck/libraryCdrHooks';
import { getLibraryImportCDRFromLibrarySource } from '@modules/library/cdr/components/LibraryImportCDR';
import { getLibraryImportModelFromLibrarySource } from '@modules/library/model/components/LibraryImportModel';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { ELibraryEntityKind, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useAnalysisObjectCDRListQuery } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRApi';
import { useAnalysisObjectPscListQuery } from '@modules/library/analysisObjects/psc/duck/analysisObjectPscApi';
import { usePscColumns } from '@modules/library/psc/duck/libraryPscHooks';
import { ILibraryPsc } from '@modules/library/psc/PscTypes';
import { getLibraryImportPscFromLibrarySource } from '@modules/library/psc/components/LibraryImportPsc';
import { InformationModal } from '@components';
import { renderInfoModalContent } from '@modules/dataset/components';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import {
  useAnalysisObjectCodeLabListQuery,
  useAnalysisObjectSQLDatasetListQuery,
} from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { useSqlLabObjectColumns } from '@modules/dnaObject/SQLLab/duck/sqlLabObjectHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { isCrossStudy } from '@shared/utils/common';
import { useCodeLabObjectColumns } from '@modules/dnaObject/CodeLab/duck/codeLabObjectHooks';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ComponentType, useEffect, useState } from 'react';
import { TableColumnsType } from 'antd';
import { useSelector } from 'react-redux';

const wrapMixPermissions = (Component: ComponentType<AnalysisPackageModalsSaveAnalysisPackageContentProps>) => {
  const WrapperComponent = (props: AnalysisPackageModalsSaveAnalysisPackageContentProps) => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      userPermissions: { canGlDmAoRead, canGlCdrAoRead, canGlSqlAoRead, canGlPscAoRead },
    } = useStudyPermissions();
    const {
      appPermissions: { canCrossDmAoRead, canCrossSqlAoRead, canCrossClAoRead },
    } = useAppPermissions();
    const crossStudy = isCrossStudy(globalStudy?.id);

    return (
      <Component
        {...props}
        mixСanDmAoRead={crossStudy ? canCrossDmAoRead : canGlDmAoRead}
        mixСanCdrAoRead={crossStudy ? false : canGlCdrAoRead}
        mixCanSqlAoRead={crossStudy ? canCrossSqlAoRead : canGlSqlAoRead}
        mixCanPscAoRead={crossStudy ? false : canGlPscAoRead}
        mixCanClAoRead={crossStudy ? canCrossClAoRead : false}
      />
    );
  };

  return WrapperComponent;
};

const AnalysisPackageModalsSaveAnalysisPackageContent = wrapMixPermissions(
  ({
    data,
    onClose,
    t,
    isView,
    mixCanClAoRead,
    mixCanPscAoRead,
    mixCanSqlAoRead,
    mixСanCdrAoRead,
    mixСanDmAoRead,
  }: AnalysisPackageModalsSaveAnalysisPackageContentProps) => {
    const [form] = Form.useForm();
    const newPackageVersionQuery = useNewPackageVersionQuery(undefined, { skip: isView });
    const [saveAnalysisPackage, saveAnalysisPackageQuery] = useSaveAnalysisPackageMutation();

    const dataModelAnalysisObjects = useAnalysisObjectModelListQuery({}, { skip: !mixСanDmAoRead });
    const cdrAnalysisObjects = useAnalysisObjectCDRListQuery({ detailed: '1' }, { skip: !mixСanCdrAoRead });
    const sqlDatasetsObjects = useAnalysisObjectSQLDatasetListQuery({ detailed: '1' }, { skip: !mixCanSqlAoRead });
    const codeLabObjects = useAnalysisObjectCodeLabListQuery({ detailed: '1' }, { skip: !mixCanClAoRead });
    const pscObjects = useAnalysisObjectPscListQuery({ detailed: '1' }, { skip: !mixCanPscAoRead });

    const analysisPackageById = useAnalysisPackageByIdQuery(data?.id!, { skip: !data?.id });
    const { modelColumns, locale: modelLocale } = useModelColumns();
    const { cdrColumns, locale: cdrLocale } = useCdrColumns();
    const { pscColumns, locale: pscLocale } = usePscColumns();
    const {
      sqlLabColumns,
      locale: sqlDatasetLocale,
      studiesDetailsModal,
      setStudiesDetailsModal,
    } = useSqlLabObjectColumns();
    const { codeLabColumns, locale: codeLabLocale } = useCodeLabObjectColumns();
    const [selectedTableItems, setSelectedTableItems] = useState<Record<string, any>>({});

    useEffect(() => {
      if (analysisPackageById.data) {
        setSelectedTableItems(analysisPackageById.data?.objects!);

        form.setFieldsValue({
          version: analysisPackageById.data.version,
          description: analysisPackageById.data.description,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analysisPackageById.data]);

    const initValues =
      isView && data
        ? {
            version: data.version,
            description: data.description,
          }
        : {
            version: newPackageVersionQuery.data?.minor_version,
            description: '',
          };

    const tableDataModels =
      ((isView && data ? analysisPackageById.data?.objects.data_model : dataModelAnalysisObjects.data?.items) as
        | ILibraryModel[]) || [];
    const tableDataCdr =
      ((isView && data
        ? analysisPackageById.data?.objects.cdr_report
        : cdrAnalysisObjects.data?.items) as ILibraryCDR[]) || [];
    const tableDataSQLDatasets =
      ((isView && data ? analysisPackageById.data?.objects.sql_lab : sqlDatasetsObjects.data?.items) as any[]) || [];
    const tableDataCodeLab =
      ((isView && data ? analysisPackageById.data?.objects.python_notebook : codeLabObjects.data?.items) as any[]) ||
      [];
    const tableDataPsc =
      ((isView && data ? analysisPackageById.data?.objects.psc : pscObjects.data?.items) as ILibraryPsc[]) || [];

    const isSaveDisabled =
      Object.values(selectedTableItems).every((v) => v.length === 0) || newPackageVersionQuery.isFetching;

    const errorMessage = [saveAnalysisPackageQuery, analysisPackageById]
      .map(
        (result) =>
          result.isError &&
          result.error &&
          ('data' in result.error ? result.error.data.userMsg : result.error?.message),
      )
      .filter(Boolean)
      .join(';');

    useEffect(() => {
      if (newPackageVersionQuery.isSuccess) {
        form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPackageVersionQuery.isSuccess]);

    const onSubmit = async (values: IAnalysisPackageSaveFieldFormValues) => {
      try {
        await saveAnalysisPackage({
          ...values,
          objects: selectedTableItems,
        }).unwrap();
        notification.success({
          message: t('saveForm.successMessageCreate', { name: values.version }),
        });
        onClose();
      } catch (e) {
        notification.error({
          message: t('saveForm.errorMessageCreate', { name: values.version }),
        });
      }
    };

    const tabs: TabProps<IBaseColumnProps>[] = [
      mixСanDmAoRead && {
        kind: ELibraryEntityKind.Model,
        label: t('Data Models'),
        columns: modelColumns as TableColumnsType<IBaseColumnProps>,
        locale: modelLocale,
        tableDataFetching: dataModelAnalysisObjects.isFetching,
        tableData: getLibraryImportModelFromLibrarySource(tableDataModels, ELibrarySourceType.Library),
      },
      mixСanCdrAoRead && {
        kind: ELibraryEntityKind.CDR,
        label: t('CDR'),
        columns: cdrColumns as TableColumnsType<IBaseColumnProps>,
        locale: cdrLocale,
        tableDataFetching: cdrAnalysisObjects.isFetching,
        tableData: getLibraryImportCDRFromLibrarySource(tableDataCdr, ELibrarySourceType.Library),
      },
      mixCanSqlAoRead && {
        kind: ELibraryEntityKind.SQL_Lab,
        label: t('SQL Lab'),
        columns: sqlLabColumns as TableColumnsType<IBaseColumnProps>,
        locale: sqlDatasetLocale,
        tableDataFetching: sqlDatasetsObjects.isFetching,
        tableData: tableDataSQLDatasets,
      },
      mixCanClAoRead && {
        kind: ELibraryEntityKind.Notebook,
        label: t('Code Lab'),
        columns: codeLabColumns as TableColumnsType<IBaseColumnProps>,
        locale: codeLabLocale,
        tableDataFetching: codeLabObjects.isFetching,
        tableData: tableDataCodeLab,
      },
      mixCanPscAoRead && {
        kind: ELibraryEntityKind.Psc,
        label: t('PSC Filters'),
        columns: pscColumns as TableColumnsType<IBaseColumnProps>,
        locale: pscLocale,
        tableDataFetching: pscObjects.isFetching,
        tableData: getLibraryImportPscFromLibrarySource(tableDataPsc, ELibrarySourceType.Library),
      },
    ].filter((item) => typeof item !== 'boolean') as TabProps<IBaseColumnProps>[];

    return (
      <FormLayout
        hideFooter={isView}
        form={form}
        onCancel={onClose}
        initialValues={initValues}
        onSubmit={onSubmit}
        cancelText={isView ? t('close') : ''}
        okText={t('save')}
        disabled={isView || newPackageVersionQuery.isLoading}
        submitIsDisabled={isSaveDisabled || isView}
        hideOkBtn={isView}
      >
        <FormItem name="version" label={t('saveForm.version')} rules={[{ required: true }]}>
          {isView ? (
            <Input />
          ) : (
            <Radio.Group>
              <Radio value={newPackageVersionQuery.data?.major_version || 0}>
                {t('saveForm.majorVersion', { value: newPackageVersionQuery.data?.major_version || 0 })}
              </Radio>
              <Radio value={newPackageVersionQuery.data?.minor_version || 0}>
                {t('saveForm.minorVersion', { value: newPackageVersionQuery.data?.minor_version || 0 })}
              </Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
          <Input />
        </FormItem>
        <FormItem wrapperCol={{ span: 24 }}>
          {!tabs.length ? (
            <Alert message={t('saveForm.noAccessToAllAp')} type="warning" />
          ) : (
            <Tabs
              style={{ marginTop: 16 }}
              defaultActiveKey={ELibraryEntityKind.Model}
              size="small"
              items={tabs.map((tab) => ({
                key: tab.kind,
                label: tab.label,
                children: (
                  <ObjectTable
                    kind={tab.kind}
                    columns={tab.columns}
                    locale={tab.locale}
                    tableDataFetching={tab.tableDataFetching || analysisPackageById.isFetching}
                    tableData={tab.tableData || []}
                    selectedTableItems={selectedTableItems}
                    setSelectedTableItems={setSelectedTableItems}
                    hideCheckboxes={isView}
                  />
                ),
              }))}
            />
          )}
        </FormItem>
        {studiesDetailsModal && (
          <InformationModal
            message={renderInfoModalContent(studiesDetailsModal)}
            onClose={() => setStudiesDetailsModal(null)}
            width="500px"
            autoHeightMin={50}
          />
        )}
        {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
      </FormLayout>
    );
  },
);

export const AnalysisPackageModalsSaveAnalysisPackage = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsSaveAnalysisPackageProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const isView = !!(data as IAnalysisPackage)?.id;

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={isView ? t('saveForm.titleView') : t('saveForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisPackageModalsSaveAnalysisPackageContent data={data} onClose={onClose} t={t} isView={isView} />}
    </DraggableModal>
  );
};

export interface AnalysisPackageModalsSaveAnalysisPackageProps {
  open: boolean;
  data: Partial<Pick<IAnalysisPackage, 'id' | 'version' | 'description'>>;
  onClose: () => void;
}

interface AnalysisPackageModalsSaveAnalysisPackageContentProps
  extends Pick<AnalysisPackageModalsSaveAnalysisPackageProps, 'data' | 'onClose'> {
  t: TFunction;
  isView: boolean;
  mixСanDmAoRead?: boolean;
  mixСanCdrAoRead?: boolean;
  mixCanSqlAoRead?: boolean;
  mixCanPscAoRead?: boolean;
  mixCanClAoRead?: boolean;
}

interface IAnalysisPackageSaveFieldFormValues {
  id?: number;
  version: string;
  description: string;
  objects: IAnalysisPackage['objects'];
}

interface TabProps<T> {
  kind: ELibraryEntityKind;
  label: string;
  columns: TableColumnsType<T>;
  locale: Record<string, any>;
  tableDataFetching: boolean;
  tableData: T[];
}
