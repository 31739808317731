import { dataStageColors } from '@config/theme/colors';
import { NoDataFound } from '@components';
import { ArrowLeft, ArrowRight, FilterSvg, SortAsc, SortDesc } from '@components/icons';
import { default as AntTable } from 'antd/es/table';
import { TableProps } from 'antd';
import { CSSObject, Theme } from '@emotion/react';
import { SortOrder } from 'antd/es/table/interface';
import { FunctionComponent, ReactNode } from 'react';

const CustomTable: FunctionComponent<TableProps<any>> = ({ children, ...props }) => {
  const itemRender = (current: number, type: string, originalElement: ReactNode) => {
    if (type === 'prev') {
      return <ArrowLeft color="darkGrey" />;
    }
    if (type === 'next') {
      return <ArrowRight color="darkGrey" />;
    }
    return originalElement;
  };

  const customizedColumns = props.columns?.map((col) => ({
    sortIcon: ({ sortOrder }: { sortOrder: SortOrder }) => {
      const color = sortOrder ? 'primary' : 'darkGrey';
      return (
        <span css={{ marginLeft: 8, fontSize: 24, display: 'flex' }}>
          {sortOrder === 'descend' ? <SortDesc color={color} /> : <SortAsc color={color} />}
        </span>
      );
    },

    filterIcon: (filtered: boolean) => <FilterSvg color={filtered ? 'primary' : 'darkGrey'} />,
    onCell: (record: any) => ({
      style: col.key === 'narrow' ? { paddingTop: '9.5px', paddingBottom: '9.5px' } : {},
    }),
    ...col,
  }));

  return (
    <AntTable
      {...props}
      css={cssTable}
      locale={{
        emptyText: <NoDataFound />,
      }}
      columns={customizedColumns}
      children={children}
      pagination={
        props.pagination
          ? {
              ...props.pagination,
              simple: true,
              size: 'default',
              itemRender: itemRender,
            }
          : false
      }
    />
  );
};

const SELECTION_COLUMN = AntTable.SELECTION_COLUMN;
const EXPAND_COLUMN = AntTable.EXPAND_COLUMN;
const SELECTION_ALL = AntTable.SELECTION_ALL;
const SELECTION_INVERT = AntTable.SELECTION_INVERT;
const SELECTION_NONE = AntTable.SELECTION_NONE;
const Column = AntTable.Column;
const ColumnGroup = AntTable.ColumnGroup;

const Table = Object.assign(CustomTable, {
  displayName: 'CustomTable',
  SELECTION_COLUMN,
  EXPAND_COLUMN,
  SELECTION_ALL,
  SELECTION_INVERT,
  SELECTION_NONE,
  Column,
  ColumnGroup,
});

const cssTable = (theme: Theme): CSSObject => ({
  marginBottom: 16,
  '&&': {
    '&&& th.ant-table-cell:before': {
      content: 'none',
    },
  },

  '.ant-table-container': {
    border: `1px solid ${theme['color-grey-300']}`,
    borderRadius: 8,
  },
  '.ant-table-row:last-child .ant-table-cell': {
    borderBottom: 'none',
  },
  '&& .ant-table-selection-column': {
    width: 96,
    textAlign: 'left',
  },
  '.ant-checkbox-wrapper': {
    paddingLeft: 8,
    paddingRight: 4,
    '& .ant-checkbox-inner': {
      border: `1.5px solid ${dataStageColors['color-grey-500']}`,
    },
  },
  '.ant-table-column-title': {
    flex: 'none',
  },
  '.ant-table-filter-column': {
    justifyContent: 'normal',

    '.ant-table-filter-trigger': {
      fontSize: 24,
    },
  },
  '.ant-table-column-sorters': {
    justifyContent: 'normal',
  },
  '.ant-pagination-prev': {
    fontSize: 20,
  },
  '.ant-pagination-next': {
    fontSize: 20,
  },
  '&& .ant-checkbox-indeterminate .ant-checkbox-inner': {
    backgroundColor: `${theme['color-grey-900']} !important`,
  },
  '.ant-checkbox-indeterminate .ant-checkbox-inner:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '20%',
    width: '60%',
    height: '2px',
    backgroundColor: theme['color-white'],
    transform: 'translateY(-50%)',
  },
  '.ant-pagination-slash': {
    visibility: 'hidden',
  },
  '.ant-pagination-slash:before': {
    visibility: 'visible',
    content: '"of"',
    color: 'inherit',
    marginInlineEnd: 0,
  },
});

export { Table };
