import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button } from '@ui';
import { initialPage } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { ICodeLabObject } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { CodeLabObjectList } from '@modules/dnaObject/CodeLab/components';
import { useAppContext } from '@app/AppContext';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import { useAnalysisObjectCodeLabListQuery, useDeleteAnalysisObjectCodeLabMutation } from '../duck/analysisObjectApi';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

const wrapMixPermissions = (Component: ComponentType<IAnalysisObjectCodeLabRootPageProps>) => {
  const WrapperComponent = () => {
    const {
      appPermissions: { canCrossClAoImport, canCrossClAoDelete },
    } = useAppPermissions();

    return <Component mixCanClAoImport={canCrossClAoImport} mixCanClAoDelete={canCrossClAoDelete} />;
  };

  return WrapperComponent;
};

export const AnalysisObjectCodeLabRootPage = wrapMixPermissions(
  ({ mixCanClAoDelete, mixCanClAoImport }: IAnalysisObjectCodeLabRootPageProps) => {
    const { notificationApi } = useAppContext();
    const dispatch = useDispatch();
    const { t } = useTranslation(['analysisObjects']);
    const supportedEnvsQuery = useSupportedEnvsQuery();
    const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
      useTablePaginationState<ICodeLabObject>(initialPage);

    const codeLabObjectsQuery = useAnalysisObjectCodeLabListQuery({
      detailed: '0',
      page: paginationState.current - 1,
      page_size: paginationState.pageSize,
      ...preparedFilters,
      ...preparedSorter,
    });

    const [deleteCodeLab, deleteCodeLabQuery] = useDeleteAnalysisObjectCodeLabMutation();

    const pagination = getPagination(codeLabObjectsQuery.data?.totalItems);

    const importCodeLabAOModal = () =>
      dispatch(analysisObjectActions.pushModal({ type: EAnalysisObjectModalsType.importCodeLab }));

    const onDeleteCodeLab = async (value: { id: number; version_id: number; name: string }) => {
      const { version_id, name } = value;
      try {
        await deleteCodeLab(version_id).unwrap();
        notificationApi.success({ message: t('codeLabObject.modals.deleteSuccess', { name }) });
      } catch (e) {
        notificationApi.error({ message: (e as QueryErrorType).data.userMsg });
      }
    };

    return (
      <PageTemplateSimple
        content={{
          isLoading: codeLabObjectsQuery.isLoading && !codeLabObjectsQuery.data,
          errorText: t('codeLabObject.loadingListError'),
          error: codeLabObjectsQuery.error,
        }}
        title={{
          children: t('codeLabObject.rootPageName'),
          pageTitle: t('codeLabObject.pageTitle'),
          extra: mixCanClAoImport && <Button children={t('import')} onClick={importCodeLabAOModal} />,
        }}
      >
        {codeLabObjectsQuery.data && (
          <CodeLabObjectList
            data={codeLabObjectsQuery.data}
            onChange={onTableChange}
            pagination={pagination}
            loading={codeLabObjectsQuery.isFetching}
            onDelete={mixCanClAoDelete ? onDeleteCodeLab : undefined}
            loadingDelete={deleteCodeLabQuery.isLoading}
            supportedEnvs={supportedEnvsQuery.data || {}}
            t={t}
          />
        )}
      </PageTemplateSimple>
    );
  },
);

interface IAnalysisObjectCodeLabRootPageProps {
  mixCanClAoImport?: boolean;
  mixCanClAoDelete?: boolean;
}
