import { LibraryRootPage } from '@modules/library/root/containers/LibraryRootPage';
import { LibraryAppRouterProvider } from '@modules/library/root/LibraryAppRouterProvider';
import { LibraryApp } from '@modules/library/root/LibraryApp';
import { LibraryModelRootPage } from '@modules/library/model/containers/LibraryModelRootPage';
import { LibraryCdrRootPage } from '@modules/library/cdr/containers/LibraryCdrRootPage';
import { LibrarySqlQueryRootPage } from '@modules/library/sqlQuery/containers/LibrarySqlQueryRootPage';
import { LibraryAuditLogListPage } from '@modules/library/audit/containers';
import { LibraryPscRootPage } from '@modules/library/psc/containers/LibraryPscRootPage';
import { PageNotFound } from '@components';
import { AppRoleGate } from '@modules/user/UserRoleGate';
import { FeatureGate } from '@modules/user/FeatureGate';
import { LibraryObjectCodeLabRootPage, LibraryObjectSqlLabRootPage } from '@modules/library/libraryObject/containers';
import { LibraryViewPage } from '@modules/library/root/containers/LibraryViewPage';
import { RouteObject } from 'react-router-dom';
import { libraryRoutes } from '.';

export const RoutesLibraryApp: RouteObject[] = [
  {
    path: libraryRoutes.root.path,
    id: libraryRoutes.root.id,
    element: (
      <FeatureGate hasGL>
        <LibraryRootPage />
      </FeatureGate>
    ),
    caseSensitive: true,
  },
  {
    path: libraryRoutes.root.path,
    id: 'library-provider',
    element: <LibraryAppRouterProvider />,
    children: [
      {
        element: (
          <FeatureGate hasGL>
            <AppRoleGate canGlLibraryRead>
              <LibraryApp />
            </AppRoleGate>
          </FeatureGate>
        ),
        children: [
          {
            path: libraryRoutes.view.path,
            id: libraryRoutes.view.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibraryViewPage />,
          },
          {
            path: libraryRoutes.models.root.path,
            id: libraryRoutes.models.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlDmoRead>
                <LibraryModelRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: libraryRoutes.cdr.root.path,
            id: libraryRoutes.cdr.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlCdroRead>
                <LibraryCdrRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: libraryRoutes.sqlQuery.root.path,
            id: libraryRoutes.sqlQuery.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: <LibrarySqlQueryRootPage />,
          },
          {
            path: libraryRoutes.sqlLab.root.path,
            id: libraryRoutes.sqlLab.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlSloRead>
                <LibraryObjectSqlLabRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: libraryRoutes.notebook.root.path,
            id: libraryRoutes.notebook.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlCloRead>
                <LibraryObjectCodeLabRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: libraryRoutes.psc.root.path,
            id: libraryRoutes.psc.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlPscRead>
                <LibraryPscRootPage />
              </AppRoleGate>
            ),
          },
          {
            path: libraryRoutes.audit.root.path,
            id: libraryRoutes.audit.root.id,
            handle: { parentId: libraryRoutes.view.id },
            element: (
              <AppRoleGate canGlAuditLogsRead>
                <LibraryAuditLogListPage />
              </AppRoleGate>
            ),
          },
          {
            path: ':libraryId/*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];
